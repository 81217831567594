import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Col, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import AuthContext from "../../auth/AuthContext";
import { getMemberDataById } from "../../api-services/AuthService";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import {
  getAllStores,
  deleteStoreById,
} from "../../api-services/Inventory/StoreApi";
import Pagination from "../../docs/Pagination";
let PageSize = 5;

export default function StoresManagement() {
  const { userRole } = useContext(AuthContext);

  const [logedinData, setLoggedinData] = useState([]);

  // pagination related
  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  // stores Array Variable
  const [allStores, setAllStores] = useState([]);
  const [allStoresTwo, setAllStoresTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // delete popup //
  const [deleteStoreId, setDeleteStoreId] = useState("");

  const removeStoreByID = async () => {
    await deleteStoreById(deleteStoreId)
      .then((res) => {
        // console.log("response", res);
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllStoresData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  // Get Api Call
  useEffect(() => {
    // if(user){
    getAllStoresData();
    // }
  }, []);

  const getLoggedInUserData = async (user_Id) => {
    let loggedinData;
    await getMemberDataById(user_Id)
      .then((res) => {
        if (res.data.status === 1) {
          loggedinData = res.data.member;
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.response?.data?.message?.message, {
          position: "top-right",
        });
      });

    return loggedinData?.member_name;
  };

  const getAllStoresData = async () => {
    setLoading(true);
    await getAllStores()
      .then((res) => {
        res.data.stores.forEach(async (el) => {
          if (el.store_handler) {
            let memberName = await getLoggedInUserData(el.store_handler);
            let obj = {
              store_admin_id: el.store_handler,
              store_admin_name: memberName,
            };
            setLoggedinData((logedinData) => [...logedinData, obj]);
          }
        });
        // displaying first 5 values //
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        let slicedData = res.data.stores.slice(firstPageIndex, lastPageIndex);
        setAllStores(slicedData);
        setShowPagination(true); // showing pagination
        // setAllStores(res.data.stores);
        setAllStoresTwo(res.data.stores);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("All Stores", allStores);
  };

  // showing sliced data for every page change click event //
  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = allStoresTwo.slice(firstPageIndex, lastPageIndex);
    setAllStores(slicedData);
  };

  // For Navigation
  const navigate = useNavigate();

  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = allStoresTwo.slice(firstPageIndex, lastPageIndex);
      setAllStores(slicedData);
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = allStoresTwo.filter(
      (item) => item.store_name.toLowerCase().indexOf(query) > -1
    );
    setAllStores(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setAllStores(allStoresTwo);
    }
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Store Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Store Management
            </h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Store Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>

              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {userRole === 7 && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    size="md"
                    onClick={() => {
                      navigate("/storeform");
                    }}
                  >
                    <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                    <span className="d-none d-sm-inline">Store</span>
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* stores List Started */}
        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && allStores.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Stores Data Currently</h5>
            </div>
          )}
          {!loading && !isError && allStores.length > 0 && (
            <Col xs="12 mt-5 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        {/* <th>Image</th> */}
                        <th>Store Name</th>
                        <th>Store Handler</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allStores &&
                        allStores.map((item) => {
                          return (
                            <tr key={item.store_id} className="text-center">
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.store_name}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {logedinData.find(
                                    (el) =>
                                      el.store_admin_id === item.store_handler
                                  )?.store_admin_name || ""}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  <Link to={`/store/${item.store_id}`}>
                                    <Button className="btn btn-success text-light">
                                      View Store Details
                                    </Button>
                                  </Link>
                                  {userRole === 7 && (
                                    <>
                                      <Link to={`/storeform/${item.store_id}`}>
                                        <Button variant="primary">
                                          Edit Store
                                        </Button>
                                      </Link>
                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteStoreId(item.store_id);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && allStoresTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={allStoresTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeStoreByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
