import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import Sidebar from "../../layouts/Sidebar";

const WebsiteDashboard = () => {
  // const { user } = useContext(AuthContext);
  // const navigate = useNavigate();
  // useEffect(() => {

  // }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Website Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Website Dashboard
            </h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0"></div>
        </div>

        <Row className="g-3">
          {[
            {
              name: "Home Slider",
              icon: "ri-image-2-fill",
              link: "homeslider",
            },
            {
              name: "Offer Slider",
              icon: "ri-image-2-fill",
              link: "offerslider",
            },
            {
              name: "Social Media",
              link: "socialmedia",
              icon: "ri-facebook-box-fill",
            },
            {
              name: "Footer",
              icon: "ri-separator",
              link: "footer",
            },
            {
              name: "Contacts",
              icon: "ri-contacts-book-2-fill",
              link: "contacts",
            },
            {
              name: "Services",
              icon: "ri-airplay-fill",
              link: "services",
            },
            {
              name: "Enquiries",
              icon: "ri-questionnaire-fill",
              link: "enquiries",
            },
            {
              name: "Subscribers",
              icon: "ri-mail-check-fill",
              link: "subscribers",
            },
            {
              name: "News Letters",
              icon: "ri-article-fill",
              link: "newsletters",
            },
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <Link to={`/website-dashboard/${item.link}`}>
                <Card className="card-one dashboardCard">
                  <Card.Body className="p-3">
                    <div className="d-flex d-sm-block d-xl-flex align-items-center">
                      <div className="helpdesk-icon text-white bg-primary">
                        <i className={item.icon}></i>
                      </div>
                      <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                        <h4 className="card-value d-flex align-items-baseline mb-0">
                          {item.name} Management
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default WebsiteDashboard;
