import { useEffect, useContext } from "react";
import AuthContext from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import { setSideBarId } from "../api-services/AuthService";

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("landing page called");
    if (!user) {
      // console.log("user", user);
      navigate("/login");
    }
  }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">
              Welcome to Vending Grocery Admin Dashboard
            </h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0"></div>
        </div>

        <Row className="g-3">
          <Col sm="4">
            <Link
              to="/inventory-dashboard"
              // onClick={()=>{
              //     setSideBarId("inventory")
              // }}
            >
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <i className="ri-shopping-bag-3-fill"></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Inventory management
                        {/* <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small> */}
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm="4">
            <Link
              to="/product-dashboard"
              // onClick={()=>{
              //     setSideBarId("products")
              // }}
            >
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <i className="ri-suitcase-2-fill"></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Product management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm="4">
            <Link
              to="/website-dashboard"
              // onClick={()=>{
              //     setSideBarId("website")
              // }}
            >
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <i className="ri-computer-fill"></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Website management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default LandingPage;
