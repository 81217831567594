import React, { useEffect, useState } from "react";
import { Button, Card, Col, Table, Row } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Sidebar from "../../layouts/Sidebar";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import { useForm } from "react-hook-form";
import { getAllShops } from "../../api-services/Inventory/ShopAPi";
import { getStoreById } from "../../api-services/Inventory/StoreApi";
import { getVaultProductsByStoreId } from "../../api-services/Inventory/Vault";
import { getAllVendingMachines } from "../../api-services/VendingMachineApi";
import { addVmProduct } from "../../api-services/VendingMachineProductApi.js";
import { addShopProduct } from "../../api-services/Inventory/ShopProductAPi";
import { updateVault } from "../../api-services/Inventory/Vault";
import { getVmsByStoreId } from "../../api-services/VendingMachineApi";

import Pagination from "../../docs/Pagination";
let PageSize = 5;

export default function VaultManagement() {
  const { id } = useParams();
  // pagination related
  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  const [allProducts, setAllProducts] = useState([]);
  const [allProductsTwo, setAllProductsTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  useEffect(() => {
    getStoreProductsData(id); // commented api was not there
    getStoreData(id);
    getStoreAllVmsData(id);
  }, []);

  // get store data //
  const [storeData, setStoreData] = useState();
  const getStoreData = async (store_Id) => {
    await getStoreById(store_Id)
      .then((res) => {
        if (res.data.status == 1) {
          let store = res.data.store;
          // console.log("store data", store);
          setStoreData(store);
        } else {
          console.log(res.data.message);
          setStoreData({});
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getStoreProductsData = async (id) => {
    setLoading(true);
    await getVaultProductsByStoreId(id)
      .then((res) => {
        // console.log("store products", res);
        if (res.data.status === 1) {
          if (res.data.count > 0 || res.data?.products) {
            // displaying first 5 values //
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            let slicedData = res.data.products.slice(
              firstPageIndex,
              lastPageIndex
            );
            setAllProducts(slicedData);
            setShowPagination(true); // showing pagination
            setAllProductsTwo(res.data.products);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log(err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  // showing sliced data for every page change click event //
  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = allProductsTwo.slice(firstPageIndex, lastPageIndex);
    setAllProducts(slicedData);
  };
  // Get Api Call

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = allProductsTwo.slice(firstPageIndex, lastPageIndex);
      setAllProducts(slicedData); // assigning sliced data
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = allProductsTwo.filter(
      (item) => item.product_title.toLowerCase().indexOf(query) > -1
    );
    setAllProducts(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setAllProducts(allProductsTwo);
    }
  };

  const navigation = useNavigate();

  // setting product values
  const [productImage, setProductImage] = useState();
  const [productName, setProductName] = useState();
  const [productId, setProductId] = useState("");
  const [shopProductId, setShopProductId] = useState("");
  const [productTotalQuantity, setProductTotalQuantity] = useState("");

  const form = useForm();

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
    setShopId("");
    setVmId("");
    setValue("vm_product_quantity", "");
    setValue("shop_product_quantity", "");
    //
    setVaultId("");
    setProductId("");
    setVaultStoreId("");
    setSelectedproductState("");
    setValue("store_product_discount", "");
    setValue("store_product_total_quantity", "");
    //
    setMaximumQuantity("");
    setShowErrMsg(false);
  };

  const addShopProductData = async (data) => {
    // console.log("data", data);
    // data["shop_id"] = shopId;
    // data["product_id"] = productId;
    let finalData = {
      product_id: productId,
      shop_id: shopId,
      shop_product_quantity: data["shop_product_quantity"],
    };
    // console.log("Shop data", finalData);
    await addShopProduct(finalData)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          getStoreProductsData(id);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const submitVmProductData = async (data) => {
    // data["vm_id"] = vmId;
    // data["product_id"] = productId;
    let finalData = {
      product_id: productId,
      vm_id: vmId,
      vm_product_quantity: data["vm_product_quantity"],
    };
    // console.log("VM data", finalData);
    await addVmProduct(finalData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          getStoreProductsData(id);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const [shopId, setShopId] = useState("");
  const setSelectedShopOption = (event) => {
    const option = storeData?.store_shops.find(
      (option) => option.shop_id === event.target.value
    );
    setShopId(option.shop_id);
  };

  // ========================= get vms data ===================== //
  const [allVms, setAllVms] = useState([]);
  const getStoreAllVmsData = async (strore_id) => {
    await getVmsByStoreId(strore_id)
      .then((res) => {
        if (res.data.status === 1) {
          setAllVms(res.data.vms);
        } else {
          setAllVms([]);
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  const [vmId, setVmId] = useState("");
  const setSelectedVmsOption = (event) => {
    const option = allVms.find((option) => option.vm_id === event.target.value);
    setVmId(option.vm_id);
  };

  //============================== share Vault Data ==================================== //
  const [selectedproductState, setSelectedproductState] = useState(0);

  const [vaultStoreId, setVaultStoreId] = useState("");
  const [vaultId, setVaultId] = useState("");

  const [maximumQuantity, setMaximumQuantity] = useState("");

  const shareVaultData = (item) => {
    setProductImage(item.product_images[0]);
    setProductName(item.product_title);
    //
    setVaultId(item.store_product.vault_id);
    setProductId(item.product_id);
    setVaultStoreId(item.store_product.store_id);
    setSelectedproductState(item.store_product.store_product_state);
    setValue(
      "store_product_discount",
      item.store_product.store_product_discount
    );
    setValue(
      "store_product_total_quantity",
      item.store_product.store_product_total_quantity
    );
  };

  // ================================= UPDATE VAULT PRODUCT Discount RELATED ======================== //
  const updateVaultProductData = async (data) => {
    // console.log("data", data);
    let finalData = {
      vault_id: vaultId,
      product_id: productId,
      store_id: vaultStoreId,
      store_product_discount: data["store_product_discount"],
      // store_product_state: selectedproductState,
      // store_product_total_quantity: data["store_product_total_quantity"]
    };
    // console.log("final data", finalData);
    await updateVault(finalData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          getStoreProductsData(id);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ================================= UPDATE VAULT PRODUCT State RELATED ======================== //
  const updateVaultProductStateData = async (data) => {
    // console.log("data", data);
    let finalData = {
      vault_id: vaultId,
      product_id: productId,
      store_id: vaultStoreId,
      store_product_state: selectedproductState,
    };
    // console.log("final data", finalData);
    await updateVault(finalData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          getStoreProductsData(id);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const [showErrMsg, setShowErrMsg] = useState(false);
  function handleChange(event) {
    if (event.target.value > maximumQuantity) {
      setShowErrMsg(true);
    } else {
      setShowErrMsg(false);
    }
    // console.log("value", event.target.value);
  }

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/inventory-dashboard/store">Store Management</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <span className="text-primary" style={{ cursor: "pointer" }}>
                  {storeData?.store_name}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Vault Management
              </li>
            </ol>
            <h5 className="main-title mb-0 mt-4">
              Welcome to Vault Management of{" "}
              <span className="text-primary fs-4">{storeData?.store_name}</span>
            </h5>
          </div>
          <div className="d-flex gap-2 mt-3">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              onClick={() => {
                navigation(-1);
              }}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-sm-inline">Back to Store Detail Page</span>
            </Button>
          </div>
        </div>

        {/* Search */}
        <div className="d-sm-flex align-items-center justify-content-between mt-3">
          <div
            className="form-search me-auto"
            style={{ borderColor: "#0CB785" }}
          >
            <input
              type="text"
              name="text"
              className="form-control"
              placeholder="Search"
              value={q}
              onChange={(e) => searchData(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <i
              className="ri-search-line"
              style={{ cursor: "pointer" }}
              onClick={() => searchData(q)}
            ></i>
          </div>
          <div></div>
        </div>

        {/* Products List Started */}
        <div className="mt-3 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && allProducts.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}>
                {" "}
                This Store Has No Products Currently !
              </h5>
            </div>
          )}
          {!loading && !isError && allProducts.length > 0 && (
            <Col xs="12 mt-3 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Quantity</th>
                        <th>Discount%</th>
                        <th>State</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allProducts &&
                        allProducts.map((item) => {
                          return (
                            <tr key={item.product_id} className="text-center">
                              <td>
                                {item.product_images.length > 0 ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={item.product_images[0]}
                                        alt={item.product_title}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item">
                                    <div className="interest-icon bg-light ">
                                      {/* <i className="ri-github-fill"></i> */}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.product_title}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item?.store_product
                                    ?.store_product_total_quantity || 0}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item?.store_product
                                    ?.store_product_discount || 0}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item?.store_product?.store_product_state ===
                                  0
                                    ? "Non-Featured"
                                    : "Featured"}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  <button
                                    className="btn btn-primary btn-md text-light d-flex align-items-center gap-2"
                                    onClick={() => {
                                      resetValues();
                                      setProductImage(item.product_images[0]);
                                      setProductName(item.product_title);
                                      setProductId(item.product_id);
                                      setMaximumQuantity(
                                        item.store_product
                                          .store_product_total_quantity
                                      );
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addShopProduct"
                                  >
                                    <i className="ri-add-circle-fill fs-18 lh-1"></i>{" "}
                                    Add to SHOP
                                  </button>
                                  <button
                                    className="btn btn-primary btn-md text-light d-flex align-items-center gap-2"
                                    onClick={() => {
                                      resetValues();
                                      setProductImage(item.product_images[0]);
                                      setProductName(item.product_title);
                                      setProductId(item.product_id);
                                      setMaximumQuantity(
                                        item.store_product
                                          .store_product_total_quantity
                                      );
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addVmProduct"
                                  >
                                    <i className="ri-add-circle-fill fs-18 lh-1"></i>{" "}
                                    Add to VM
                                  </button>
                                  <button
                                    className="btn btn-success btn-md text-light d-flex align-items-center gap-2"
                                    onClick={() => {
                                      shareVaultData(item);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateVaultProductDiscount"
                                  >
                                    <i className="ri-add-circle-fill fs-18 lh-1"></i>{" "}
                                    Add Discount
                                  </button>
                                  <button
                                    className="btn btn-primary btn-md text-light"
                                    onClick={() => {
                                      shareVaultData(item);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateVaultProductState"
                                  >
                                    {item.store_product.store_product_state ===
                                    0
                                      ? "Make Featured"
                                      : "Make Non-Featured"}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && allProductsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={allProductsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
      </div>

      {/* add to shop popup */}
      <div
        className="modal fade"
        id="addShopProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addShopProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addShopProductLabel">
                <span className="fs-4">Add Product to shop</span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        {storeData?.store_shops?.length > 0 ? (
                          <div className="mb-3 mt-3">
                            <label className="fs-sm fw-medium">
                              Choose shop
                            </label>
                            <div>
                              <select
                                {...register("shop_id")}
                                className="form-control"
                                onChange={setSelectedShopOption}
                                value={shopId}
                              >
                                <option value="">Select Shop ... </option>
                                {storeData?.store_shops?.map((option) => (
                                  <option
                                    key={option.shop_id}
                                    value={option.shop_id}
                                  >
                                    {option.shop_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <>
                            <h5 className="card-value mb-1 text-danger">
                              <i className="ri-information-fill fs-20 lh-1 text-danger"></i>
                              Please ADD Shop to Store. To ADD Product to Shop.
                            </h5>
                          </>
                        )}
                        <div className="mb-2 mt-5">
                          <h5 className="fs-sm fw-medium mb-1">
                            Available Quantity in Vault *
                          </h5>
                          <h3 className="card-value mb-1">
                            <i className="ri-shopping-bag-3-line"></i>
                            {maximumQuantity}
                          </h3>
                        </div>
                        <div className="mb-2 mt-3">
                          <label
                            htmlFor="productQuantity"
                            className="fs-sm fw-medium"
                          >
                            Choose Product Quantity
                          </label>
                          <input
                            type="number"
                            id="productQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("shop_product_quantity", {
                              valueAsNumber: true,
                              maxLength: maximumQuantity,
                              message: `value should be less than ${maximumQuantity}`,
                            })}
                            onChange={handleChange}
                          />
                          {showErrMsg && (
                            <p className="text-danger">{`value should be less than or equal to ${maximumQuantity}`}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                disabled={showErrMsg || storeData?.store_shops?.length === 0}
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(addShopProductData)}
              >
                <span className="fs-6">Add to Shop</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add to Vm popup */}
      <div
        className="modal fade"
        id="addVmProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addVmProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addVmProductLabel">
                <span className="fs-4">Add Product to Vending Machine</span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        {allVms?.length > 0 ? (
                          <div className="mb-3 mt-3">
                            <label className="fs-sm fw-medium">
                              Choose Vending Machine
                            </label>
                            <div>
                              <select
                                {...register("vm_id")}
                                className="form-control"
                                onChange={setSelectedVmsOption}
                                value={vmId}
                              >
                                <option value="">
                                  Select Vending Machine ...{" "}
                                </option>
                                {allVms.map((option) => (
                                  <option
                                    key={option.vm_id}
                                    value={option.vm_id}
                                  >
                                    {option.vm_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <>
                            <h5 className="card-value mb-1 text-danger">
                              <i className="ri-information-fill fs-20 lh-1 text-danger"></i>
                              Please ADD Vending Machine to Store. To ADD
                              Product to Vending Machine.
                            </h5>
                          </>
                        )}
                        <div className="mb-2 mt-5">
                          <h5 className="fs-sm fw-medium mb-1">
                            Available Quantity in Vault *
                          </h5>
                          <h3 className="card-value mb-1">
                            <i className="ri-shopping-bag-3-line"></i>
                            {maximumQuantity}
                          </h3>
                        </div>
                        <div className="mb-2 mt-3">
                          <label
                            htmlFor="productQuantity"
                            className="fs-sm fw-medium"
                          >
                            Choose Product Quantity
                          </label>
                          <input
                            type="number"
                            id="productQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("vm_product_quantity", {
                              valueAsNumber: true,
                            })}
                            onChange={handleChange}
                          />
                          {showErrMsg && (
                            <p className="text-danger">{`value should be less than or equal to ${maximumQuantity}`}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                disabled={showErrMsg || allVms?.length === 0}
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(submitVmProductData)}
              >
                <span className="fs-6">Add to Vending Machine</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* update Vault Discount popup */}
      <div
        className="modal fade"
        id="updateVaultProductDiscount"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="updateVaultProductDiscountLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateVaultProductDiscountLabel">
                <span className="fs-4">Update Vault Product Discount</span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        <div className="mb-2 mt-5">
                          <label
                            htmlFor="storeproductQuantity"
                            className="text-dark card-value mb-1 fs-5"
                          >
                            Choose Product Discount%
                          </label>
                          <input
                            type="number"
                            id="storeproductQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("store_product_discount", {
                              valueAsNumber: true,
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(updateVaultProductData)}
              >
                <span className="fs-6">Update Vault Product Discount</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* update Vault Product State popup */}
      <div
        className="modal fade"
        id="updateVaultProductState"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="updateVaultProductStateLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateVaultProductStateLabel">
                <span className="fs-4">Update Vault Product State</span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        <div className="mb-3 mt-5">
                          <label
                            htmlFor="producttype"
                            className="text-dark card-value mb-1 fs-5"
                          >
                            Update Product State
                          </label>
                          <div>
                            <select
                              {...register("store_product_state")}
                              className="form-control"
                              id="producttype"
                              value={selectedproductState}
                              onChange={(e) =>
                                setSelectedproductState(Number(e.target.value))
                              }
                            >
                              <option value="">Select...</option>
                              <option value="1">Featured</option>
                              <option value="0">Non-Featurd</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(updateVaultProductStateData)}
              >
                <span className="fs-6">Update Vault Product State</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
