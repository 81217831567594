import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import AuthContext from "../auth/AuthContext";
import { getMemberById } from "../api-services/AuthService";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
export default function Header({ onSkin }) {
  const { setUser, logout, setUserRole, logedinData, getLoggedInMemberData } =
    useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("header is called");
    if (localStorage.getItem("access_token")) {
      let decodedJwt = jwt_decode(localStorage.getItem("access_token"));
      if (decodedJwt.exp * 1000 < Date.now()) {
        // localStorage.clear();
        // setUser(null);
        // setUserRole(0);
        // navigate("/login");
      } else {
        setUserRole(decodedJwt?.member_role);
        getLoggedInMemberData();
      }
      // ========= If Page Refreshes this function will invokes ====== //
      // ========= Have to check Expiry time of token ========= //
      // ========= Call refresh token If expires ========= //
    }
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem("skin-mode", skin);

      // onSkin(skin);
    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem("skin-mode");

      // onSkin("");
    }
  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="me-auto"></div>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem("skin-mode")
                  ? "nav-link"
                  : "nav-link active"
              }
            >
              Light
            </Link>
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem("skin-mode")
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Dark
            </Link>
          </nav>
          <hr />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link
              onClick={sidebarSkin}
              className={
                !localStorage.getItem("sidebar-skin")
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Default
            </Link>
            <Link
              onClick={sidebarSkin}
              className={
                localStorage.getItem("sidebar-skin") === "prime"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Prime
            </Link>
            <Link
              onClick={sidebarSkin}
              className={
                localStorage.getItem("sidebar-skin") === "dark"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Dark
            </Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={logedinData?.member_image} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={logedinData?.member_image} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">
              {logedinData?.member_name}
            </h5>
            <p className="fs-sm text-secondary">{logedinData?.member_email}</p>
            {logedinData?.member_role === 7 && (
              <p className="fs-md text-dark">
                Role :<span className="text-primary"> Super Admin</span>
              </p>
            )}
            {logedinData?.member_role === 6 && (
              <p className="fs-md text-dark">
                Role :<span className="text-primary"> Site Admin</span>
              </p>
            )}
            {logedinData?.member_role === 5 && (
              <p className="fs-md text-dark">
                Role :<span className="text-primary"> Store Admin</span>
              </p>
            )}

            <nav className="nav">
              <Link to="/ProfileDetailPage">
                <i className="ri-profile-line"></i> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link onClick={logout}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
