import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Sidebar from "../layouts/Sidebar";
import {
  getShopById,
  addShop,
  updateShop,
  updateShopImage,
} from "../api-services/Inventory/ShopAPi.js";
import { getAllStores } from "../api-services/Inventory/StoreApi.js";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import { convertToWebP } from "../api-services/FileConvertionService";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function ShopForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [spinner, setSpinner] = useState(false);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getShopDataById(id);
    }
    if (isAddMode) {
      if (location?.state?.id) {
        setValue("shop_store", location.state.id);
        setstoreOption(location.state.id);
      }
    }
    if (location?.state?.name) {
      setStoreName(location?.state?.name);
    }
    getAllStoresData();
  }, []);

  // stores Array Variable
  const [allStores, setAllStores] = useState([]);
  const getAllStoresData = async () => {
    await getAllStores()
      .then((res: any) => {
        if (res.data.status === 1) {
          setAllStores(res.data.stores);
        }
      })
      .catch((err: any) => {
        // console.log("error", err);
        toast.error("Failed To Fetch Stores Data.\n Pls try again", {
          position: "top-right",
        });
      });
  };
  const [storeOption, setstoreOption] = useState("");
  const setSelectedOption = (event: any) => {
    const option = allStores.find(
      (option) => option.store_id === event.target.value
    );
    setstoreOption(option.store_id);
  };

  // ======================== ZIP CODES related =============================== //

  const [finalZipcodesArray, setFinalZipcodesArray] = useState([]);
  const [defaultZipcodesArray, setDefaultZipcodesArray] = useState([]);

  const [selectedZipCodeIds, setselectedZipCodeIds] = useState([]);
  useEffect(() => {
    // if (!isAddMode) {
    let selectedZipCodes = defaultZipcodesArray?.map((obj: any) => {
      return obj.value;
    });
    // console.log("selectedZipCodeIds", selectedZipCodeIds);
    setselectedZipCodeIds(selectedZipCodes);
    // }
  }, [defaultZipcodesArray]);

  //============= default ZipCode data ============//
  var zipCodeArray = [];
  var ZipCodeObj = [
    { value: "500001", label: "Hyderabad 500001" },
    { value: "530027", label: "Vishakapatnam 530027" },
    { value: "507001", label: "Khammam 507001" },
    { value: "532001", label: "Srikakulam 532001" },
    { value: "560001", label: "Bangalore 532312" },
    { value: "515004 ", label: "AnanthPur 515004 " },
  ];
  useEffect(() => {
    // if (isAddMode) {
    let zipCodeArrayTwo = [];
    zipCodeArrayTwo = ZipCodeObj;
    setFinalZipcodesArray(zipCodeArrayTwo);
    // }
  }, []);
  // ======================== ZIP CODES related Ends =============================== //

  // image state variables
  const [shopImage, setShopImage] = useState("");
  const [shopImageTwo, setShopImageTwo] = useState("");
  const [shopImageThree, setShopImageThree] = useState("");
  const [shopImageFour, setShopImageFour] = useState("");
  const [shopImageFive, setShopImageFive] = useState("");
  // images array state
  const [updatefinalImagesS3Urls, setupdatefinalImagesS3Urls] = useState([]);

  // ========================= GET SHOP DATA By Id ================================= //
  const getShopDataById = async (id) => {
    await getShopById(id)
      .then((res) => {
        let data = res.data;
        let shopData = data.shop;
        //
        setValue("shop_name", shopData.shop_name);
        setValue("shop_description", shopData.shop_description);
        setValue("shop_address", shopData.shop_address);
        setValue("shop_store", shopData.shop_store);
        // setValue("shop_by", shopData.shop_by);
        setValue("shop_agent", shopData.shop_agent);
        setValue("shop_shipping_zip_codes", shopData.shop_shipping_zip_codes);
        setstoreOption(shopData.shop_store);
        // ============== Images ===================== //
        setValue("shop_images", shopData.shop_images);
        if (shopData.shop_images) {
          setShopImage(shopData.shop_images[0]);
          setShopImageTwo(shopData.shop_images[1]);
          setShopImageThree(shopData.shop_images[2]);
          setShopImageFour(shopData.shop_images[3]);
          setShopImageFive(shopData.shop_images[4]);
          setupdatefinalImagesS3Urls(shopData.shop_images); // assigning images array to state array
        }
        // ============== ImagesEnded  ===================== //
        // ============== ZipCodes Related ==================== //
        let zipcodes = shopData.shop_shipping_zip_codes;
        zipCodeArray = ZipCodeObj;
        setFinalZipcodesArray(zipCodeArray);
        zipcodes.forEach((el: any) => {
          var filteredObject = zipCodeArray.filter((obj) => {
            return obj.value == el;
          });
          oldSelectedZipcodesArray.push(filteredObject[0]);
        });
        setDefaultZipcodesArray(oldSelectedZipcodesArray);
        // ============== ZipCodes Related Ended ==================== //
      })
      .catch((err: any) => {
        // console.log("error", err);
        toast.error("Failed To Fetch Data.\n\n Pls try again", {
          position: "top-right",
        });
      });
  };

  let oldSelectedZipcodesArray = [];

  const form = useForm<formValues>({
    defaultValues: {
      shop_name: "",
      shop_store: "",
      // shop_by: "",
      // shop_updated_by: "",
      shop_description: "",
      shop_address: "",
      shop_shipping_zip_codes: [],
      shop_agent: "",
      shop_images: [],
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Interface
  type formValues = {
    shop_name: String;
    shop_description: String;
    shop_address: String;
    shop_shipping_zip_codes: string[];
    shop_store: String;
    // shop_by: String;
    // shop_updated_by: String,
    shop_agent: String;
    shop_images: string[];
  };

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
    setSpinner(false);
    setDefaultZipcodesArray([]);
  };

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  const [fileTwo, setFileTwo] = useState<File>();
  const [fileThree, setFileThree] = useState<File>();
  const [fileFour, setFileFour] = useState<File>();
  const [fileFive, setFileFive] = useState<File>();

  var finalImagesS3Urls: any = [];

  const onSubmit = async (data: any) => {
    if (isAddMode) {
      setSpinner(true);
      data["shop_shipping_zip_codes"] = selectedZipCodeIds;
      // console.log("data", data);
      await addShop(data)
        .then(async (res: any) => {
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_shopId = response_data.shop_id;
            //========== if files selected =========//
            if (file || fileTwo || fileThree || fileFour || fileFive) {
              let folderPath =
                "Common/Stores/" +
                data.shop_store +
                "/Shops/" +
                response_data_shopId;
              if (file) {
                let filename = response_data_shopId + "_shop_image_one.webp";
                let response = await UploadImagetoS3(
                  file,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[0] = response["Location"];
              }
              if (fileTwo) {
                let filename = response_data_shopId + "_shop_image_two.webp";
                let response = await UploadImagetoS3(
                  fileTwo,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[1] = response["Location"];
              }
              if (fileThree) {
                let filename = response_data_shopId + "_shop_image_three.webp";
                let response = await UploadImagetoS3(
                  fileThree,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[2] = response["Location"];
              }
              if (fileFour) {
                let filename = response_data_shopId + "_shop_image_four.webp";
                let response = await UploadImagetoS3(
                  fileFour,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[3] = response["Location"];
              }
              if (fileFive) {
                let filename = response_data_shopId + "_shop_image_five.webp";
                let response = await UploadImagetoS3(
                  fileFive,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[4] = response["Location"];
              }
              // ========== updating with image s3 urls =================== //
              await updateShopImage(response_data_shopId, finalImagesS3Urls)
                .then((updateRes: any) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.message, {
                      position: "top-right",
                    });
                    navigation(-1);
                    // return;
                  } else {
                    resetValues();
                    toast.error(updateRes.data.message, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((error: any) => {
                  toast.error(error.message, {
                    position: "top-right",
                  });
                  // console.log("error", error);
                });
              // ========== update images completed ============== //
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          }
        })
        .catch((err: any) => {
          toast.error(err.message, {
            position: "top-right",
          });
          // console.log("error", err);
        });
      // =============================  UPDATE ===================================  //
    } else {
      setSpinner(true);
      data["shop_shipping_zip_codes"] = selectedZipCodeIds;
      data.shop_id = id;
      let folderPath = "Common/Stores/" + data.shop_store + "/Shops/" + id;
      // console.log("folder path", folderPath);
      // let folderPath = "Shops/" + id;
      if (file) {
        let filename = id + "_shop_image_one.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        updatefinalImagesS3Urls[0] = response["Location"];
      }
      if (fileTwo) {
        let filename = id + "_shop_image_two.webp";
        let response = await UploadImagetoS3(fileTwo, folderPath, filename);
        updatefinalImagesS3Urls[1] = response["Location"];
      }
      if (fileThree) {
        let filename = id + "_shop_image_three.webp";
        let response = await UploadImagetoS3(fileThree, folderPath, filename);
        updatefinalImagesS3Urls[2] = response["Location"];
      }
      if (fileFour) {
        let filename = id + "_shop_image_four.webp";
        let response = await UploadImagetoS3(fileFour, folderPath, filename);
        updatefinalImagesS3Urls[3] = response["Location"];
      }
      if (fileFive) {
        let filename = id + "_shop_image_five.webp";
        let response = await UploadImagetoS3(fileFive, folderPath, filename);
        updatefinalImagesS3Urls[4] = response["Location"];
      }
      data["shop_images"] = updatefinalImagesS3Urls;
      // console.log("update data", data);
      await updateShop(data)
        .then((res: any) => {
          if (res.data.status === 1) {
            resetValues();
            setShopImage("");
            setShopImageTwo("");
            setShopImageThree("");
            setShopImageFour("");
            setShopImageFive("");
            toast.success(res.data.message, {
              position: "top-right",
            });
            navigation(-1);
          }
        })
        .catch((err: any) => {
          // resetValues();
          toast.error(err.message, {
            position: "top-right",
          });
          // console.log("error", err);
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/inventory-dashboard/store`}>Store Management</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <span className="text-primary" style={{ cursor: "pointer" }}>
                  {storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Shop" : "Edit Shop"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Shop" : "Edit Shop"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <Col sm="6">
                  <div className="mt-3">
                    <label
                      htmlFor="inputName"
                      className="col-form-label text-dark"
                    >
                      Shop Name
                    </label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      placeholder="Shop Name"
                      {...register("shop_name")}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mt-3">
                    <label
                      htmlFor="shopstore"
                      className="col-form-label text-dark"
                    >
                      Select Store
                    </label>
                    <div>
                      <select
                        {...register("shop_store")}
                        className="form-control"
                        onChange={setSelectedOption}
                        value={storeOption}
                      >
                        <option value="">Select Store ... </option>
                        {allStores.map((option) => (
                          <option key={option.store_id} value={option.store_id}>
                            {option.store_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                {/* <Col sm="6">
                  <div className="">
                    <label
                      htmlFor="shopby"
                      className="col-form-label text-dark"
                    >
                      Shop By
                    </label>
                    <input
                      type="text"
                      id="shopby"
                      className="form-control"
                      placeholder="Shop By"
                      {...register("shop_by")}
                    />
                  </div>
                </Col> */}
                <Col sm="6">
                  {/* <div className="">
                                        <label
                                            htmlFor="shopUpdatedby"
                                            className="col-form-label text-dark"
                                        >
                                            Shop Updated By
                                        </label>
                                        <input
                                            type="text"
                                            id="shopUpdatedby"
                                            className="form-control"
                                            placeholder="shop Updated By"
                                            {...register("shop_updated_by")}
                                        />
                                    </div> */}
                  <div className="mb-3">
                    <label
                      htmlFor="shopAgent"
                      className="col-form-label text-dark"
                    >
                      Shop Agent
                    </label>
                    <input
                      type="text"
                      id="shopAgent"
                      className="form-control"
                      placeholder="shop Agent"
                      {...register("shop_agent")}
                    />
                  </div>
                </Col>
              </Row>
              <div className="mb-3">
                <label htmlFor="Textarea" className="col-form-label text-dark">
                  Shop Description
                </label>
                <textarea
                  className="form-control"
                  id="Textarea"
                  placeholder="Enter Description here..."
                  rows={3}
                  {...register("shop_description")}
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="Textarea" className="col-form-label text-dark">
                  Shop Address
                </label>
                <textarea
                  className="form-control"
                  id="Textarea"
                  placeholder="Enter Shop Address here..."
                  rows={3}
                  {...register("shop_address")}
                ></textarea>
              </div>
              {/* Shop Zip Codes */}
              <div className="mb-3 ">
                <p className="col-form-label text-dark">
                  Shop Shipping Zip Codes
                </p>
                <SelectDropDown
                  finalArray={finalZipcodesArray}
                  defaultArray={defaultZipcodesArray}
                  setDefaultArray={setDefaultZipcodesArray}
                />
              </div>
              {/* Images */}
              <div className="row mb-3">
                <div className="col mt-3">
                  <label>Shop Images</label>
                  <p className="mb-1 text-warning">
                    Preffered Image Size 580px x 550px{" "}
                  </p>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="shopImage" className="mt-3 mb-1">
                        Shop Image One
                      </label>
                      <input
                        id="shopImage"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFile(webpFile);
                        }}
                      />
                      {!isAddMode && shopImage && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={shopImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="shopImageTwo" className="mt-3 mb-1">
                        Shop Image Two
                      </label>
                      <input
                        id="shopImageTwo"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileTwo(webpFile);
                        }}
                      />
                      {!isAddMode && shopImageTwo && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={shopImageTwo}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="shopImageThree" className="mt-3 mb-1">
                        Shop Image Three
                      </label>
                      <input
                        id="shopImageThree"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileThree(webpFile);
                        }}
                      />
                      {!isAddMode && shopImageThree && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={shopImageThree}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="shopImageFour" className="mt-3 mb-1">
                        Shop Image four
                      </label>
                      <input
                        id="shopImageFour"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFour(webpFile);
                        }}
                      />
                      {!isAddMode && shopImageFour && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={shopImageFour}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="shopImageFive" className="mt-3 mb-1">
                        Shop Image five
                      </label>
                      <input
                        id="shopImageFive"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFive(webpFile);
                        }}
                      />
                      {!isAddMode && shopImageFive && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={shopImageFive}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {spinner && (
                <div className="d-flex justify-content-center mt-2">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

function SelectDropDown({ finalArray, defaultArray, setDefaultArray }) {
  return (
    <Select
      name="select"
      options={finalArray}
      labelField="label"
      valueField="value"
      multi
      onChange={(value) => setDefaultArray(value)}
      values={defaultArray}
      clearable
      color={"#22c595"}
      style={{ padding: 10 }}
    />
  );
}
