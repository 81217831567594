import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Table, Badge } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import {
  getAllNewsLetters,
  updateNewsLetterState,
  sendNewsLetters,
} from "../../api-services/NewsLettersApi";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import Forbidden from "../../pages/Forbidden";

export default function NewsLettersManagement() {
  const { userRole } = useContext(AuthContext);

  const [newsLettersData, setNewsLettersData] = useState([]);

  // Get Api Call
  useEffect(() => {
    if (userRole === 7 || userRole === 6) {
      getAllNewsLettersData();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllNewsLettersData = async () => {
    setLoading(true);
    await getAllNewsLetters()
      .then((res) => {
        setNewsLettersData(res.data.news_letters);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("News Letters Data", newsLettersData);
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // For Navigation
  const navigate = useNavigate();

  // move to active/inactive related
  const [moveNewsLetterById, setMoveNewsLetterId] = useState("");

  const updateNewsLetterStateByID = async (state) => {
    let status = Number(state);
    await updateNewsLetterState(moveNewsLetterById, status)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllNewsLettersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // Form Configuration

  const [newsLetterObj, setNewsLetterObj] = useState({});

  const form = useForm({
    defaultValues: {
      news_letter_id: "",
      subscriber_state: 2,
    },
  });

  const { register, handleSubmit, reset } = form;

  // =================================== SUBMIT ====================================== //
  const onSubmit = async (data) => {
    data.news_letter_id = newsLetterObj.news_letter_id;
    let state = Number(data["subscriber_state"]);
    data["subscriber_state"] = state;
    await sendNewsLetters(data)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
        } else {
          toast.success(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const resetValues = () => {
    reset();
  };

  if (userRole === 5) {
    return (
      <>
        <div className="main main-app p-3 p-lg-4">
          <Forbidden linkUrl={"/website-dashboard"} />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                News Letters Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to News Letters Management
            </h4>
          </div>
          <div>
            {(userRole === 7 || userRole === 6) && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                size="md"
                onClick={() => {
                  navigate("/newsletterform");
                }}
              >
                <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                <span className="d-none d-sm-inline">News Letter</span>
              </Button>
            )}
          </div>
        </div>

        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && newsLettersData.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No News Letter Data Currently</h5>
            </div>
          )}
          {!loading && !isError && newsLettersData.length > 0 && (
            <Col xs="12 mt-5">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        {/* <th>Si.no</th> */}
                        <th>News Letter Image One</th>
                        <th>News Letter Image Two</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newsLettersData &&
                        newsLettersData.map((item, index) => {
                          return (
                            <tr
                              key={item.news_letter_id}
                              className="text-center"
                            >
                              <td>
                                {item.news_letter_image1 ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={item.news_letter_image1}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item text-center">
                                    <div className="interest-icon bg-light "></div>
                                  </div>
                                )}
                              </td>
                              <td>
                                {item.news_letter_image2 ? (
                                  <div
                                    className="news-item"
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div className="news-img">
                                      <img
                                        src={item.news_letter_image2}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item text-center">
                                    <div className="interest-icon bg-light "></div>
                                  </div>
                                )}
                              </td>

                              <td>
                                <span className="ff-numerals">
                                  {item.news_letter_status === 0 ? (
                                    <Badge bg="secondary p-2" pill>
                                      In-Active
                                    </Badge>
                                  ) : (
                                    <Badge bg="success p-2" pill>
                                      Active
                                    </Badge>
                                  )}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Link
                                        to={`/newsletterform/${item.news_letter_id}`}
                                      >
                                        <Button variant="primary">
                                          Edit News Letter
                                        </Button>
                                      </Link>
                                      <Button
                                        variant="success"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => {
                                          setNewsLetterObj(item);
                                        }}
                                        className="text-light"
                                      >
                                        <i className="ri-send-plane-fill"></i>{" "}
                                        Send News Letters
                                      </Button>
                                      {item.news_letter_status === 0 ? (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#featuredPopup"
                                          onClick={() => {
                                            setMoveNewsLetterId(
                                              item.news_letter_id
                                            );
                                          }}
                                        >
                                          Make Active
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#NormalPopup"
                                          onClick={() => {
                                            setMoveNewsLetterId(
                                              item.news_letter_id
                                            );
                                          }}
                                        >
                                          Make In-Active
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
      </div>

      {/* Make Active  */}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Make it Active?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateNewsLetterStateByID(1)}
              >
                Make Active
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Active  */}

      {/* Make in-active  */}
      <div
        className="modal fade"
        id="NormalPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="NormalPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="NormalPopupLabel">
                Are You want to Make it In-Active?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateNewsLetterStateByID(0)}
              >
                Make In-Active
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make in-active */}

      {/* send news Letter popup*/}
      <div
        className="modal fade"
        id="exampleModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Send News Letters to Subscribers
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form noValidate>
                <div className="mb-3 mt-3">
                  <label className="col-form-label text-dark">
                    Choose Subscriber Status
                  </label>
                  <div>
                    <select
                      {...register("subscriber_state")}
                      className="form-control"
                    >
                      <option value="2">Select...</option>
                      <option value="1">Active Subscribers</option>
                      <option value="0">In-Active Subscribers</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
              >
                Send News Letters
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
