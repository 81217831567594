import axios from "axios";
import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getMemberById } from "../api-services/AuthService";
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(0);

  const [user, setUser] = useState(() => {
    if (localStorage.getItem("access_token")) {
      let Access_Token = JSON.parse(localStorage.getItem("access_token"));
      // console.log("Access_Token", Access_Token);

      let decodedJwt = jwt_decode(Access_Token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear();
        setUserRole(0);
        return null;
      } else {
        return decodedJwt?.member_id;
      }
    }
    return null;
  });

  const navigate = useNavigate();


  let ApiUrl = process.env.REACT_APP_DEV_API_KEY;
  // console.log("APi url", ApiUrl);

  // =================================== Log-in =========================================== //
  const login = async (payload) => {
    // console.log("paylod in auth_C", payload);
    await axios
      .post(`${ApiUrl}/auth`, payload)
      .then((apiResponse) => {
        if (apiResponse.data.status === 1) {
          toast.success("Logged In Successfully", {
            position: "top-right",
          });
          // console.log("login response", apiResponse);
          localStorage.setItem(
            "access_token",
            JSON.stringify(apiResponse.data.auth_token),
          );
          localStorage.setItem(
            "refresh_token",
            JSON.stringify(apiResponse.data.refresh_token),
          );
          //
          let decoded_Data = jwt_decode(apiResponse.data.auth_token);
          // console.log("decoded_data", decoded_Data);
          //
          setUser(decoded_Data?.member_id);
          setUserRole(decoded_Data?.member_role);
          navigate("/dashboard");
        } else if (apiResponse.data.status === 0) {
          toast.error(apiResponse.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // =================================== Log-Out =========================================== //

  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    removeTokens();
    setUser(null);
    setUserRole(0);
    toast.success("Logged Out", {
      position: "top-right",
    });
    navigate("/login");
  };

  // remove tokens in local storage
  function removeTokens() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  const [logedinData, setLoggedinData] = useState();
  const getLoggedInMemberData = async (user_Id) => {
    await getMemberById(user_Id)
      .then((res) => {
        if (res?.data.status === 1) {
          setLoggedinData(res?.data.member);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response?.data?.message?.message, {
          position: "top-right",
        });
        // toast.error(err.response?.data?.message?.message, {
        //   position: "top-right",
        // });
        // console.log("error", err.response?.data?.message?.message);
        // if(err.response?.data?.message?.message === "jwt expired" ){
        //   removeTokens();
        //   setUser(null);
        //   setUserRole(0);
        //   navigate("/login");
        // }
      });
  };

  return (
    <AuthContext.Provider value={{ user, setUser , login, logout, userRole, setUserRole, getLoggedInMemberData, logedinData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
