import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllFaqs() {
  return await axios.get(apiUrl + "/faq/allfaqs");
}

export async function getFaqById(id) {
  return await axios.get(apiUrl + "/faq/" + id);
}

export async function addFaq(data) {
  return await JwtInterceoptor.post(apiUrl + "/faq/addfaq", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateFaq(data) {
  return await JwtInterceoptor.put(apiUrl + "/faq", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateFaqImage(id, image) {
  const data = { faq_id: id, faq_image: image };
  return await JwtInterceoptor.put(apiUrl + "/faq", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteFaqById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/faq", {
    data: {
      faq_id: id,
    },
  });
}
