import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getActiveSliders() {
  return await axios.get(apiUrl + "/slider_one/filter/1");
}

export async function getInactiveSliders() {
  return await axios.get(apiUrl + "/slider_one/filter/0");
}

export async function addSlider(data) {
  return await JwtInterceoptor.put(apiUrl + "/slider_one", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getAllSliders() {
  return await axios.get(apiUrl + "/slider_one");
}

export async function updateSlider(data) {
  return await JwtInterceoptor.patch(apiUrl + "/slider_one", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteSliderById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/slider_one", {
    data: {
      slider_one_id: id,
    },
  });
}

export async function updateSliderState(id, state) {
  const data = { slider_one_id: id, slider_one_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/slider_one", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateSliderImage(id, image) {
  const data = { slider_one_id: id, slider_one_image: image };
  return await JwtInterceoptor.patch(apiUrl + "/slider_one", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getSliderById(id) {
  return await axios.get(apiUrl + "/slider_one/" + id);
}
