
// ============================= IMAGE to WEBP Convertion Service ================================== //

export async function convertToWebP(inputFile: any) {
  return new Promise((resolve, reject) => {
    // Get the selected file
    let file = inputFile;

    // Create a FileReader to read the file
    var reader = new FileReader();
    reader.readAsDataURL(file); // actual reader
    // reader.readAsText(file);

    // Set the onload event handler
    reader.onload = function (e) {
      // Create an image element
      var img = document.createElement("img");

      // Set the source of the image to the loaded file
      img.src = e.target?.result as any;

      // Wait for the image to load
      img.onload = async function () {
        // Create a canvas element
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");

        // Set the canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        context!.drawImage(img, 0, 0);

        // Convert the canvas to WebP format
        var webpDataUrl = canvas.toDataURL("image/webp");

        // Use the resulting WebP data URL as needed
        // console.log("webpDataUrl", webpDataUrl);

        const convertToFile = (imgData: any) => {
          var arr = imgData.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let convertedFile = new File([u8arr], "image.webp", { type: mime });
          return convertedFile;
        };

        // converting base64 to file
        let finalFile = await convertToFile(webpDataUrl);
        // console.log("finalFile",finalFile);
        resolve(finalFile);
      };
    };
  });
}