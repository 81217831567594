import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/Vending Grocery _Crop _Trans.png";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import AuthContext from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  const { login, user } = useContext(AuthContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      // console.log("user in login" , user);
      navigate("/dashboard");
    } else {
      // console.log("user in login" , user);
    }
  }, []);

  const form = useForm({
    defaultValues: {
      member_email: "",
      member_password: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState } = form;

  // Form error handling
  const { errors } = formState;

  // ========== Submit Login related ==================//

  const loginSubmit = async (data) => {
    // console.log("login data", data);
    await login(data);
  };

  // ========== show password related ==================//
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <img src={logo} alt="" className="img-fluid mb-4" />
          <Card.Title className="text-center">Sign In</Card.Title>
          <Card.Text className="text-center">
            Welcome back! Please signin to continue.
          </Card.Text>
        </Card.Header>
        <Card.Body>
          <form noValidate>
            <div className="mb-4">
              <label className="col-form-label">Email address</label>
              <input
                type="email"
                id="email"
                className="form-control"
                {...register("member_email", {
                  required: "Please Enter Your Email!",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please Enter A Valid Email!",
                  },
                })}
              />
              <p className="text-danger">{errors?.member_email?.message}</p>
            </div>

            <div className="mb-4">
              <label className="d-flex justify-content-between col-form-label">
                Password <Link to="">Forgot password?</Link>
              </label>
              <div style={{ position: "relative", display: "flex" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  {...register("member_password", {
                    required: "Please Enter Your Password",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  })}
                />
                <i
                  className={
                    !showPassword
                      ? "ri-eye-off-fill fs-20"
                      : "ri-eye-fill text-primary fs-20"
                  }
                  style={{
                    position: "absolute",
                    top: "15%",
                    right: "5%",
                  }}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                ></i>
              </div>
              <p className="text-danger">{errors?.member_password?.message}</p>
            </div>
            <Button
              type="button"
              variant="primary"
              className="btn-sign"
              onClick={handleSubmit(loginSubmit)}
            >
              Sign In
            </Button>

            {/* <div className="divider">
              <span>or sign in with</span>
            </div> */}

            {/* <Row className="gx-2">
              <Col>
                <Button variant="" className="btn-facebook">
                  <i className="ri-facebook-fill"></i> Facebook
                </Button>
              </Col>
              <Col>
                <Button variant="" className="btn-google">
                  <i className="ri-google-fill"></i> Google
                </Button>
              </Col>
            </Row> */}
          </form>
        </Card.Body>
        {/* <Card.Footer>
          Don't have an account?{" "}
          <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer> */}
      </Card>
    </div>
  );
}
