import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllEnquiries() {
  return await JwtInterceoptor.get(apiUrl + "/contact_us/");
}

export async function getFilteredEnquiries(type) {
  return await JwtInterceoptor.get(apiUrl + "/contact_us/" + type);
}

//
export async function updateEnquiry(data) {
  return await JwtInterceoptor.patch(apiUrl + "/contact_us/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateEnquiryState(id, state) {
  const data = { enquiry_id: id, enquiry_status: state };
  return await JwtInterceoptor.patch(apiUrl + "/contact_us/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteEnquiryById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/contact_us/", {
    data: {
      enquiry_id: id,
    },
  });
}
