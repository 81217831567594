import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Badge, Button, Card, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import AuthContext from "../../auth/AuthContext";
import {
  getMemberById,
  updateUserPassword,
} from "../../api-services/AuthService";
import Sidebar from "../../layouts/Sidebar";
import { useForm } from "react-hook-form";

import documentIcon from "../../assets/img/documentIcon.jpg";

// ================================= UPDATE PASSWORD FORM (child Component) ============================ //
const UpdateForm = ({ closePopup }) => {
  const form = useForm({
    defaultValues: {
      member_old_password: "",
      member_new_password: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Form error handling
  const { errors } = formState;

  const updatePassword = async (data) => {
    // console.log("data", data);
    await updateUserPassword(data)
      .then((res) => {
        if (res.data.status === 1) {
          resetValues();
          toast.success(res.data.message, {
            position: "top-right",
          });
          closePopup();
        } else if (res.data.status === 0) {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const resetValues = () => {
    reset();
  };

  return (
    <Card>
      <Card.Body>
        <form onSubmit={handleSubmit(updatePassword)} noValidate>
          <div className="mb-3 mt-1">
            <label
              htmlFor="oldPassword"
              className="text-dark card-value mb-1 fs-5"
            >
              Old Password
            </label>
            <input
              type="text"
              id="oldPassword"
              className="form-control"
              placeholder="Enter Old Password"
              {...register("member_old_password", {
                required: "Please Enter Old Password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long!",
                },
              })}
            />
            <p className="text-danger">
              {errors?.member_old_password?.message}
            </p>
          </div>
          <div className="mb-2 mt-4">
            <label
              htmlFor="newPassword"
              className="text-dark card-value mb-1 fs-5"
            >
              New Password
            </label>
            <input
              type="text"
              id="newPassword"
              className="form-control"
              placeholder="Enter New Password"
              {...register("member_new_password", {
                required: "Please Enter New Password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long!",
                },
              })}
            />
            <p className="text-secondary text-sm mt-1">
              Password must be at least 6 characters long!
            </p>
            <p className="text-danger">
              {errors?.member_new_password?.message}
            </p>
          </div>

          <div className="text-end mt-5">
            <Button
              type="submit"
              style={{ paddingLeft: 50, paddingRight: 50 }}
              variant="primary"
            >
              submit
            </Button>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};

// ================================= UPDATE PASSWORD FORM (child Component) Ended ============================ //

// =================================== PROFILE DETAIL PAGE RELATED Starts ==================================== //

export default function ProfileDetailPage() {
  const { user, getLoggedInMemberData } = useContext(AuthContext);

  useEffect(() => {
    getLoggedInMemberData(); // calling get member data for assigning of data in header
    getLoggedInUserData(user);
  }, []);

  const [dataFetched, setDataFetched] = useState(false);

  const [logedinData, setLoggedinData] = useState();
  const getLoggedInUserData = async (user_Id) => {
    setDataFetched(false);
    await getMemberById(user_Id)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("log res", res);
          setLoggedinData(res.data.member);
          setDataFetched(true);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.response?.data?.message?.message, {
          position: "top-right",
        });
      });
  };

  const navigate = useNavigate();
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      {!dataFetched ? (
        <div className="main main-app p-5 p-lg-4">
          <div className="parentContainer">
            <div className="childContainer">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-2">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link>
                    {logedinData?.member_role === 7 && "Super Admin"}
                    {logedinData?.member_role === 6 && "Site Admin"}
                    {logedinData?.member_role === 5 && "Store Admin"}
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile Detail Page
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              {logedinData?.member_role === 7 && (
                <Button
                  variant="success"
                  className="d-flex align-items-center gap-2"
                  onClick={() => {
                    navigate("/Profileform", {
                      state: {
                        role: logedinData?.member_role,
                        type: "AddingMember",
                      },
                    });
                  }}
                >
                  <i className="ri-add-circle-fill fs-18 lh-1"></i> Add
                  <span className="d-none d-sm-inline"> New Member </span>
                </Button>
              )}
            </div>
          </div>

          <Row className="g-5">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile mb-3">
                    <div className="media-body">
                      <h5 className="media-name">{logedinData?.member_name}</h5>
                      <p className="d-flex gap-2 mt-1 mb-3">
                        <i className="ri-map-pin-line"></i>
                        {logedinData?.member_address}
                      </p>
                      <p className="mb-0">{logedinData?.member_bio}</p>
                      <p className="mt-2">
                        Created by :
                        <span className="text-primary">
                          {" "}
                          {logedinData?.member_created_by}
                        </span>
                      </p>
                    </div>
                    <div className="media-img mb-3 mb-sm-0">
                      <img
                        src={logedinData?.member_image}
                        className="img-fluid"
                        alt={logedinData?.member_name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 mt-3">
                <Button
                  variant="primary"
                  className="fw-medium"
                  onClick={() => {
                    navigate(`/Profileform/${logedinData?.member_id}`, {
                      state: {
                        role: logedinData?.member_role,
                      },
                    });
                  }}
                >
                  Update Profile
                </Button>
                <Button
                  variant=""
                  className="btn-white fw-medium"
                  // data-bs-toggle="modal" data-bs-target="#updatePasswordPopup"
                  onClick={handleShow}
                >
                  Change Password
                </Button>
              </div>

              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="section-title mb-4">User Information</h5>
                  <Row>
                    <Col>
                      <ul className="list-contact-info">
                        <li>
                          <i
                            className="ri-building-fill"
                            style={{ fontSize: "24" }}
                          ></i>
                          <span className="fs-6">
                            {logedinData?.member_address}
                          </span>
                        </li>
                        {/* <li>
                                        <i className="ri-home-8-fill" style={{fontSize:"24"}}></i>
                                        <span>Westfield, Oakland, CA</span>
                                    </li> */}
                        <li>
                          <i
                            className="ri-phone-fill"
                            style={{ fontSize: "24" }}
                          ></i>
                          <span className="fs-6">
                            {logedinData?.member_phone}
                          </span>
                        </li>
                        <li>
                          <i
                            className="ri-mail-fill"
                            style={{ fontSize: "24" }}
                          ></i>
                          <span className="fs-6">
                            {logedinData?.member_email}
                          </span>
                        </li>
                      </ul>
                    </Col>
                    <Col>
                      <ul className="list-contact-info">
                        <li>
                          <i
                            className="ri-account-circle-fill"
                            style={{ fontSize: "24" }}
                          ></i>
                          {logedinData?.member_role === 7 && (
                            <span className="fs-6">Super Admin</span>
                          )}
                          {logedinData?.member_role === 6 && (
                            <span className="fs-6">Site Admin</span>
                          )}
                          {logedinData?.member_role === 5 && (
                            <span className="fs-6">Store Admin</span>
                          )}
                        </li>
                        <li>
                          {logedinData?.member_state === 0 && (
                            <>
                              <i
                                className="ri-chat-delete-fill"
                                style={{ fontSize: "24" }}
                              ></i>
                              <span className="fs-6">InActive</span>
                            </>
                          )}
                          {logedinData?.member_state === 1 && (
                            <>
                              <i
                                className="ri-chat-check-fill"
                                style={{ fontSize: "24" }}
                              ></i>
                              <span className="fs-6">Active</span>
                            </>
                          )}
                          {logedinData?.member_state === 2 && (
                            <>
                              <i
                                className="ri-chat-download-fill"
                                style={{ fontSize: "24" }}
                              ></i>
                              <span className="fs-6">Archive</span>
                            </>
                          )}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>

              {logedinData?.member_documents?.length > 0 && (
                <div className="card mt-3">
                  <div className="card-body">
                    <h5 className="section-title mb-4">User Documents</h5>

                    <Row className="g-1 g-sm-2 g-xl-3 mb-2">
                      {logedinData?.member_documents.map((file, index) => (
                        <Col sm="3" key={index}>
                          <Card className="card-file">
                            <div className={"card-file-icon " + "info"}>
                              <div className="avatar avatar-xl avatar-rounded ">
                                <img src={documentIcon} alt="" />
                              </div>
                            </div>
                            <Card.Body>
                              <h6>{file.title}</h6>
                            </Card.Body>
                            <Card.Footer>
                              <div className="d-grid gap-2 mt-2">
                                <a
                                  href={file.url}
                                  download={file.url.split("/").pop()}
                                  target="_blank"
                                >
                                  <Button
                                    className="d-flex align-items-center justify-content-center gap-2 w-100"
                                    type="button"
                                    size="md"
                                  >
                                    <i className="ri-download-2-line fs-18 lh-1"></i>
                                    <span>Download</span>
                                  </Button>
                                </a>
                              </div>
                            </Card.Footer>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}

      {/* update Password MODAL */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateForm closePopup={handleClose} />
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close Modal
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
}
