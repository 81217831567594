import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllMedia() {
  return await axios.get(apiUrl + "/social_media/");
}

export async function addMedia(data) {
  return await JwtInterceoptor.put(apiUrl + "/social_media/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getMediaById(id) {
  return await axios.get(apiUrl + "/social_media/" + id);
}

export async function updateMedia(data) {
  return await JwtInterceoptor.patch(apiUrl + "/social_media/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteMediaById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/social_media/", {
    data: {
      media_id: id,
    },
  });
}

export async function updateMediaImage(id, image) {
  const data = { media_id: id, media_image: image };
  return await JwtInterceoptor.patch(apiUrl + "/social_media/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
