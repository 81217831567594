import axios from "axios";
import JwtInterceoptor from "../../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getVaultProductsByStoreId(id) {
  return await JwtInterceoptor.get(apiUrl + "/vault/products/" + id);
}

export async function updateVault(data) {
  return await JwtInterceoptor.patch(apiUrl + "/vault/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
