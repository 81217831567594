import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllSubscribers() {
  return await JwtInterceoptor.get(apiUrl + "/subscriber/");
}

export async function updateSubscriber(data) {
  return await JwtInterceoptor.patch(apiUrl + "/subscriber/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateSubscriberState(id, state) {
  const data = { subscriber_id: id, subscriber_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/subscriber/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getFilteredSubscribers(type) {
  return await JwtInterceoptor.get(apiUrl + "/subscriber/" + type);
}

export async function deleteSubscriber(id) {
  return await JwtInterceoptor.delete(apiUrl + "/subscriber/", {
    data: {
      subscriber_id: id,
    },
  });
}
