import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { convertToWebP } from "../api-services/FileConvertionService";
import Sidebar from "../layouts/Sidebar.js";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import HtmlEditor from "../dashboard/websiteManagement/HtmlEditor.js";
import {
  addFaq,
  updateFaqImage,
  updateFaq,
  getFaqById,
} from "../api-services/FaqApi";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function FaqForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [faqimage, setfaqImage] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getFaqDataById(id);
    }
  }, []);

  const [viewForm, setViewForm] = useState(false);

  const getFaqDataById = async (id) => {
    await getFaqById(id)
      .then((res: any) => {
        if (res.data.status == 1) {
          let data = res.data;
          let faq = data.faq;
          setViewForm(true);
          setValue("faq_question", faq.faq_question);
          setValue("faq_answer", faq.faq_answer);
          setValue("faq_sub_answer", faq.faq_sub_answer);
          setValue("faq_image", faq.faq_image);
          setfaqImage(faq.faq_image);
        } else {
          // console.log("error", res.data.message);
          setViewForm(false);
          toast.error(res.data.message, {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      faq_question: "",
      faq_answer: "",
      faq_sub_answer: "",
      faq_image: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    faq_question: string;
    faq_answer: string;
    faq_sub_answer: string;
    faq_image: string;
  };

  // Form error handling
  const { errors } = formState;

  // Img Upload to s3
  const [file, setFile] = useState<File>();

  // reset form
  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    if (isAddMode) {
      setSpinner(true);
      // console.log("data", data);
      await addFaq(data)
        .then(async (res: any) => {
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_faqId = response_data.faq_id;
            if (file) {
              let folderPath = s3FolderType + "/Faqs/" + response_data_faqId;
              let filename = response_data_faqId + "_faq_image.webp";
              let response = await UploadImagetoS3(file, folderPath, filename);
              let s3Url = response["Location"];
              // console.log("s3Url", s3Url);

              await updateFaqImage(response_data_faqId, s3Url)
                .then((updateRes: any) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.mesage, {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.mesage, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err: any) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= UPDATE ============== //
    } else {
      setSpinner(true);
      data.faq_id = id;
      if (file) {
        let folderPath = s3FolderType + "/Faqs/" + id;
        let filename = id + "_faq_image.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        data["faq_image"] = response["Location"];
      }
      await updateFaq(data)
        .then((updateRes: any) => {
          if (updateRes.data.status === 1) {
            resetValues();
            setfaqImage("");
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setfaqImage("");
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/website-dashboard/faqs">Faq Management</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Faq" : "Edit Faq"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Faq" : "Edit Faq"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}

        <div>
          {(viewForm || isAddMode) && (
            <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row className="mb-3">
                    <label
                      htmlFor="faqQuestion"
                      className="col-sm-2 col-form-label"
                    >
                      Faq Question
                    </label>
                    <Col sm="10">
                      <input
                        type="text"
                        id="faqQuestion"
                        className="form-control"
                        placeholder="Faq Question"
                        {...register("faq_question")}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="taqanswer"
                      className="col-sm-2 col-form-label"
                    >
                      Faq Answer
                    </label>
                    <Col sm="10">
                      <textarea
                        className="form-control"
                        placeholder="Faq Answer"
                        rows={3}
                        id="faqanswer"
                        {...register("faq_answer")}
                      ></textarea>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="faqsubanswer"
                      className="col-sm-2 col-form-label"
                    >
                      Faq Sub Answer
                    </label>
                    <Col sm="10">
                      <input
                        type="text"
                        id="faqsubanswer"
                        className="form-control"
                        placeholder="Faq Sub Answer"
                        {...register("faq_sub_answer")}
                      />
                    </Col>
                  </Row>

                  <div className="row mb-3">
                    <div className="col-lg-2 col-sm-12">
                      <p className="mt-3">Choose Faq Image</p>
                    </div>
                    <div className="col-lg-10 col-sm-12">
                      <label
                        htmlFor="faqImage"
                        className="mb-1 text-warning"
                      ></label>
                      <input
                        id="faqImage"
                        type="file"
                        className="form-control"
                        // onChange={handleClick}
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFile(webpFile);
                        }}
                      />
                      {!isAddMode && faqimage && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={faqimage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {spinner && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  )}

                  {/* Button */}
                  <div className="text-end mt-5">
                    <Button
                      type="submit"
                      style={{ paddingLeft: 50, paddingRight: 50 }}
                      variant="primary"
                    >
                      submit
                    </Button>
                  </div>
                </form>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}
