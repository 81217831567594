import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { convertToWebP } from "../api-services/FileConvertionService";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import {
  addNewsLetter,
  getNewsLetterById,
  updateNewsLetter,
} from "../api-services/NewsLettersApi.js";
import HtmlEditor from "../dashboard/websiteManagement/HtmlEditor.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function NewsLetterForm() {
  const { id } = useParams();
  const isAddMode = !id;

  const [spinner, setSpinner] = useState(false);

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  const [fileTwo, setFileTwo] = useState<File>();

  const [editorValueOne, setEditorValueOne] = useState("");
  const [editorValueTwo, setEditorValueTwo] = useState("");
  const [editorValueThree, setEditorValueThree] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    if (!isAddMode) {
      getNewsLetterDataById(id);
    }
  }, []);

  const [newsimageOne, setNewsImageOne] = useState("");
  const [newsimageTwo, setNewsImageTwo] = useState("");

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isError, setIsError] = useState(false);

  const getNewsLetterDataById = async (id) => {
    setIsDataFetched(false);
    await getNewsLetterById(id)
      .then((res: any) => {
        let data = res.data;
        let newsLetter = data.news_letters;
        setIsDataFetched(true);
        // setValue("news_letter_subject", newsLetter.news_letter_subject);
        // setValue("news_letter_content", newsLetter.news_letter_content);
        // setValue("news_letter_footer", newsLetter.news_letter_footer);
        setEditorValueOne(newsLetter.news_letter_subject);
        setEditorValueTwo(newsLetter.news_letter_content);
        setEditorValueThree(newsLetter.news_letter_footer);
        setValue("news_letter_image1", newsLetter.news_letter_image1);
        setValue("news_letter_image2", newsLetter.news_letter_image2);
        setNewsLetterState(newsLetter.news_letter_status); /////////////////////////
        setNewsImageOne(newsLetter.news_letter_image1);
        setNewsImageTwo(newsLetter.news_letter_image2);
      })
      .catch((err: any) => {
        setIsDataFetched(false);
        setIsError(true);
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const navigation = useNavigate();

  const form = useForm<formValues>({
    defaultValues: {
      news_letter_subject: "",
      news_letter_content: "",
      news_letter_image1: "",
      news_letter_image2: "",
      news_letter_footer: "",
      news_letter_status: 0,
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    news_letter_subject: String;
    news_letter_content: String;
    news_letter_footer: String;
    news_letter_image1: String;
    news_letter_image2: String;
    news_letter_status: number;
  };

  // Form error handling
  const { errors } = formState;

  // state value management //
  let newsLetterStatusTypes = [
    { id: 0, newsLetterstateValue: "In-Active" },
    { id: 1, newsLetterstateValue: "Active" },
  ];
  const handleChangeStateType = (event, index) => {
    console.log(
      newsLetterStatusTypes[index].newsLetterstateValue,
      "newsLetterstateValue"
    );
    let value = newsLetterStatusTypes[index].newsLetterstateValue;
    setnewsLetterStateValue(value);
    setNewsLetterState(index);
  };
  const [NewsLetterState, setNewsLetterState] = useState(3);
  const [newsLetterstateValue, setnewsLetterStateValue] = useState("");

  // img upload to s3
  const handleClick = async (event) => {
    let webpFile: any = await convertToWebP(event.target.files[0]);
    setFile(webpFile);
  };
  const handleClickTwo = async (event) => {
    let webpFile: any = await convertToWebP(event.target.files[0]);
    setFileTwo(webpFile);
  };

  // ========= Form Submit event ================ //
  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    if (isAddMode) {
      setSpinner(true);
      data["news_letter_subject"] = editorValueOne;
      data["news_letter_content"] = editorValueTwo;
      data["news_letter_footer"] = editorValueThree;
      await addNewsLetter(data)
        .then(async (res) => {
          // if status == 1
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_news_letter_id = response_data.news_letter_id;
            // if files selected
            if (file || fileTwo) {
              let updateObject = {
                news_letter_id: response_data_news_letter_id,
                news_letter_image1: "",
                news_letter_image2: "",
              };
              let folderPath =
                s3FolderType + "/NewsLetters/" + response_data_news_letter_id;
              if (file) {
                let filename =
                  response_data_news_letter_id + "_newsLetter_imageOne.webp";
                let response = await UploadImagetoS3(
                  file,
                  folderPath,
                  filename
                );
                updateObject["news_letter_image1"] = response["Location"];
              }
              if (fileTwo) {
                let filename =
                  response_data_news_letter_id + "_newsLetter_imageTwo.webp";
                let response = await UploadImagetoS3(
                  fileTwo,
                  folderPath,
                  filename
                );
                updateObject["news_letter_image2"] = response["Location"];
              }
              await updateNewsLetter(updateObject)
                .then((updateRes) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success("News Letter Created Successfully", {
                      position: "top-right",
                    });
                    return;
                  } else {
                    resetValues();
                    toast.error(updateRes.data.mesage, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
            } else {
              resetValues();
              toast.success("News Letter Created Successfully", {
                position: "top-right",
              });
              return;
            }
          } else {
            // if add status == 0
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            // console.log("error meg", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // === update Operation related ===== //
    } else {
      setSpinner(true);
      data.news_letter_id = id;
      data["news_letter_status"] = Number(NewsLetterState);
      data["news_letter_subject"] = editorValueOne;
      data["news_letter_content"] = editorValueTwo;
      data["news_letter_footer"] = editorValueThree;
      let folderPath = s3FolderType + "/NewsLetters/" + id;
      if (file) {
        let filename = id + "_newsLetter_imageOne.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        data["news_letter_image1"] = response["Location"];
      }
      if (fileTwo) {
        let filename = id + "_newsLetter_imageTwo.webp";
        let response = await UploadImagetoS3(fileTwo, folderPath, filename);
        data["news_letter_image2"] = response["Location"];
      }
      // console.log("update data", data);
      await updateNewsLetter(data)
        .then((updateResponse) => {
          if (updateResponse.data.status === 1) {
            resetValues();
            setNewsImageOne("");
            setNewsImageTwo("");
            toast.success(updateResponse.data.message, {
              position: "top-right",
            });
          } else {
            resetValues();
            setNewsImageOne("");
            setNewsImageTwo("");
            toast.error(updateResponse.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  // reset form
  const resetValues = () => {
    reset();
    setNewsLetterState(3);
    setEditorValueOne("");
    setEditorValueTwo("");
    setEditorValueThree("");
    setSpinner(false);
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/website-dashboard/newsletters">
                  News Letter Management
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New News-Letter" : "Edit News-Letter"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New News-Letter" : "Edit News-Letter"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {isAddMode || !isError ? (
          <Card className="shadow p-3 mb-5 bg-white rounded">
            <Card.Body>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Row className="mb-5">
                  <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                    News Letter Subject
                  </label>
                  <Col sm="10">
                    <HtmlEditor
                      editorValue={editorValueOne}
                      setEditorValue={setEditorValueOne}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                    News Letter Content
                  </label>
                  <Col sm="10">
                    <HtmlEditor
                      editorValue={editorValueTwo}
                      setEditorValue={setEditorValueTwo}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                    News Letter Footer
                  </label>
                  <Col sm="10">
                    <HtmlEditor
                      editorValue={editorValueThree}
                      setEditorValue={setEditorValueThree}
                    />
                  </Col>
                </Row>
                <div className="row mb-5 mt-5">
                  <div className="col-lg-2 col-sm-12">
                    <label htmlFor="imageOne" className="mt-1">
                      News Letter Image 1
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="file"
                      id="imageOne"
                      className="form-control"
                      onChange={handleClick}
                    />
                    {!isAddMode && newsimageOne && (
                      <div className="mt-3 mb-3">
                        <label className="mb-2">Current Image</label>
                        <div className="news-item">
                          <div className="news-img fullwidth-img">
                            <img
                              src={newsimageOne}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-lg-2 col-sm-12">
                    <label htmlFor="imageTwo" className="mt-1">
                      News Letter Image 2
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      id="imageTwo"
                      type="file"
                      className="form-control"
                      onChange={handleClickTwo}
                    />
                    {!isAddMode && newsimageTwo && (
                      <div className="mt-3 mb-3">
                        <label className="mb-2">Current Image</label>
                        <div className="news-item">
                          <div className="news-img fullwidth-img">
                            <img
                              src={newsimageTwo}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-3 ">
                  <div className="col mt-2">
                    <label>News Letter Status</label>
                  </div>
                  <div className="col-10 mt-2">
                    <div className="row">
                      {newsLetterStatusTypes.map((type, i) => (
                        <div className="col-lg-6" key={i}>
                          <input
                            className="mt-1"
                            id={type.newsLetterstateValue}
                            key={type.id}
                            type="radio"
                            value={newsLetterstateValue}
                            checked={NewsLetterState === i}
                            onChange={(event) =>
                              handleChangeStateType(event, i)
                            }
                          />
                          <label
                            className="form-label ms-2"
                            htmlFor={type.newsLetterstateValue}
                          >
                            {type.newsLetterstateValue}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {spinner && (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" />
                  </div>
                )}
                {/* Button */}
                <div className="text-end mt-5">
                  <Button
                    type="submit"
                    style={{ paddingLeft: 50, paddingRight: 50 }}
                    variant="primary"
                  >
                    submit
                  </Button>
                </div>
              </form>
            </Card.Body>
          </Card>
        ) : (
          <div className="mt-5 shadow">
            <div className="text-center">
              <h5 style={{ padding: 30 }}> Failed to fetch data</h5>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
