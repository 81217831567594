import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import { convertToWebP } from "../api-services/FileConvertionService";
import {
  addVendingMachine,
  updateVendingMachine,
  getVendingMachineById,
  updateVendingMachineImage,
} from "../api-services/VendingMachineApi.js";
import { getAllStores } from "../api-services/Inventory/StoreApi.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function VendingMachineForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const [spinner, setSpinner] = useState(false);
  const navigation = useNavigate();

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  // image state variables
  const [vmImage, setvmImage] = useState("");
  const [vmImageTwo, setvmImageTwo] = useState("");
  const [vmImageThree, setvmImageThree] = useState("");
  const [vmImageFour, setvmImageFour] = useState("");
  const [vmImageFive, setvmImageFive] = useState("");
  // images array state
  const [updatefinalImagesS3Urls, setupdatefinalImagesS3Urls] = useState([]);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (location?.state?.name) {
      // console.log("vm name", location?.state?.name);
      setStoreName(location?.state?.name);
    }
    getAllStoresData();
  }, []);

  // get and set form fields
  useEffect(() => {
    if (!isAddMode) {
      getVmDataById(id);
    }
    if (isAddMode) {
      if (location?.state?.id) {
        setValue("vm_store", location.state.id);
        setstoreOption(location.state.id);
      }
    }
  }, []);

  const getAllStoresData = async () => {
    await getAllStores()
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("stores res", res);
          setAllStores(res.data.stores);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const [allStores, setAllStores] = useState([]);
  // store drop down
  const [storeOption, setstoreOption] = useState("");
  const setSelectedOption = (event) => {
    const option = allStores.find(
      (option) => option.store_id === event.target.value
    );
    setstoreOption(option.store_id);
  };

  //Zipcodes options displaying
  const [finalZipcodesArray, setFinalZipcodesArray] = useState([]);
  const [defaultZipcodesArray, setDefaultZipcodesArray] = useState([]);

  const [selectedZipCodeIds, setselectedZipCodeIds] = useState([]);
  useEffect(() => {
    let selectedZipCodes = defaultZipcodesArray?.map((obj) => {
      return obj.value;
    });
    // console.log("selectedZipCodeIds", selectedZipCodeIds);
    setselectedZipCodeIds(selectedZipCodes);
  }, [defaultZipcodesArray]);

  //============= default ZipCode data ============//
  var zipCodeArray = [];
  var ZipCodeObj = [
    { value: "500001", label: "Hyderabad 500001" },
    { value: "530027", label: "Vishakapatnam 530027" },
    { value: "507001", label: "Khammam 507001" },
    { value: "532001", label: "Srikakulam 532001" },
    { value: "560001", label: "Bangalore 532312" },
    { value: "515004 ", label: "AnanthPur 515004 " },
  ];

  useEffect(() => {
    let zipCodeArrayTwo = [];
    zipCodeArrayTwo = ZipCodeObj;
    setFinalZipcodesArray(zipCodeArrayTwo);
  }, []);

  //======================= get Vm by id api ===========================//
  const getVmDataById = async (id) => {
    await getVendingMachineById(id)
      .then((res) => {
        if (res.data.status === 1) {
          let data = res.data;
          let Vm = data.vm;
          //
          setValue("vm_name", Vm.vm_name);
          setValue("vm_description", Vm.vm_description);
          setValue("vm_address", Vm.vm_address);
          setValue("vm_store", Vm.vm_store);
          setstoreOption(Vm.vm_store);
          setValue("vm_shipping_zip_codes", Vm.vm_shipping_zip_codes); // array
          // ============== Images ===================== //
          setValue("vm_images", Vm.vm_images);
          // console.log("vm_images", Vm.vm_images);
          if (Vm.vm_images) {
            setvmImage(Vm.vm_images[0]);
            setvmImageTwo(Vm.vm_images[1]);
            setvmImageThree(Vm.vm_images[2]);
            setvmImageFour(Vm.vm_images[3]);
            setvmImageFive(Vm.vm_images[4]);
            setupdatefinalImagesS3Urls(Vm.vm_images); // assigning images array to state array
          }
          // ============== ImagesEnded  ===================== //

          // ============== ZipCodes Related ==================== //
          let zipcodes = Vm.vm_shipping_zip_codes;
          zipCodeArray = ZipCodeObj;
          setFinalZipcodesArray(zipCodeArray);
          zipcodes.forEach((el) => {
            var filteredObject = zipCodeArray.filter((obj) => {
              return obj.value == el;
            });
            oldSelectedZipcodesArray.push(filteredObject[0]);
          });
          setDefaultZipcodesArray(oldSelectedZipcodesArray);
          // ============== ZipCodes Related Ended ==================== //
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  let oldSelectedZipcodesArray = [];

  const form = useForm<formValues>({
    defaultValues: {
      vm_name: "",
      vm_description: "",
      vm_address: "",
      vm_store: "",
      vm_shipping_zip_codes: [],
      vm_images: [],
      // vm_created_by: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Interface
  type formValues = {
    vm_name: String;
    vm_description: String;
    vm_address: String;
    vm_shipping_zip_codes: string[];
    vm_images: string[];
    vm_store: String;
    // vm_created_by: String;
  };

  // Form error handling
  const { errors } = formState;

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  const [fileTwo, setFileTwo] = useState<File>();
  const [fileThree, setFileThree] = useState<File>();
  const [fileFour, setFileFour] = useState<File>();
  const [fileFive, setFileFive] = useState<File>();

  const resetValues = () => {
    reset();
    setSpinner(false);
    setDefaultZipcodesArray([]);
  };

  var finalImagesS3Urls: any = [];

  const onSubmit = async (data: any) => {
    if (isAddMode) {
      setSpinner(true);
      data["vm_shipping_zip_codes"] = selectedZipCodeIds;
      // console.log("data", data);
      await addVendingMachine(data)
        .then(async (res) => {
          // console.log("add response", res);
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_vmId = response_data.vm_id;
            //========== if files selected =========//
            if (file || fileTwo || fileThree || fileFour || fileFive) {
              let folderPath =
                "Common/Stores/" +
                data.vm_store +
                "/VendingMachines/" +
                response_data_vmId;
              if (file) {
                let filename = response_data_vmId + "_vm_image_one.webp";
                let response = await UploadImagetoS3(
                  file,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[0] = response["Location"];
              }
              if (fileTwo) {
                let filename = response_data_vmId + "_vm_image_two.webp";
                let response = await UploadImagetoS3(
                  fileTwo,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[1] = response["Location"];
              }
              if (fileThree) {
                let filename = response_data_vmId + "_vm_image_three.webp";
                let response = await UploadImagetoS3(
                  fileThree,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[2] = response["Location"];
              }
              if (fileFour) {
                let filename = response_data_vmId + "_vm_image_four.webp";
                let response = await UploadImagetoS3(
                  fileFour,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[3] = response["Location"];
              }
              if (fileFive) {
                let filename = response_data_vmId + "_vm_image_five.webp";
                let response = await UploadImagetoS3(
                  fileFive,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[4] = response["Location"];
              }
              // ========== updating with image s3 urls =================== //
              await updateVendingMachineImage(
                response_data_vmId,
                finalImagesS3Urls
              )
                .then((updateRes) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.message, {
                      position: "top-right",
                    });
                    // return;
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.message, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            console.log("error meg", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
    // update mode //
    else {
      setSpinner(true);
      data["vm_shipping_zip_codes"] = selectedZipCodeIds;
      data.vm_id = id;
      // ================== images related ===================== //
      let folderPath =
        "Common/Stores/" + data.vm_store + "/VendingMachines/" + id;
      if (file) {
        let filename = id + "_vm_image_one.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        updatefinalImagesS3Urls[0] = response["Location"];
      }
      if (fileTwo) {
        let filename = id + "_vm_image_two.webp";
        let response = await UploadImagetoS3(fileTwo, folderPath, filename);
        updatefinalImagesS3Urls[1] = response["Location"];
      }
      if (fileThree) {
        let filename = id + "_vm_image_three.webp";
        let response = await UploadImagetoS3(fileThree, folderPath, filename);
        updatefinalImagesS3Urls[2] = response["Location"];
      }
      if (fileFour) {
        let filename = id + "_vm_image_four.webp";
        let response = await UploadImagetoS3(fileFour, folderPath, filename);
        updatefinalImagesS3Urls[3] = response["Location"];
      }
      if (fileFive) {
        let filename = id + "_vm_image_five.webp";
        let response = await UploadImagetoS3(fileFive, folderPath, filename);
        updatefinalImagesS3Urls[4] = response["Location"];
      }
      data["vm_images"] = updatefinalImagesS3Urls;
      // console.log("Updated data", data);
      await updateVendingMachine(data)
        .then((updateRes) => {
          if (updateRes.data.status === 1) {
            resetValues();
            setvmImage("");
            setvmImageTwo("");
            setvmImageThree("");
            setvmImageFour("");
            setvmImageFive("");
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setvmImage("");
            setvmImageTwo("");
            setvmImageThree("");
            setvmImageFour("");
            setvmImageFive("");
            console.log("error", updateRes.data.message);
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/inventory-dashboard/store`}>Store Management</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <span className="text-primary" style={{ cursor: "pointer" }}>
                  {storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Vending Machine" : "Edit Vending Machine"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Vending Machine" : "Edit Vending Machine"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label
                  htmlFor="inputName"
                  className="col-sm-2 col-form-label text-dark"
                >
                  Vending Machine Name
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Vending Machine Name"
                    {...register("vm_name")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="Textarea"
                  className="col-sm-2 col-form-label text-dark"
                >
                  Vending Machine Description
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Description here..."
                    rows={3}
                    {...register("vm_description")}
                  ></textarea>
                </Col>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="Textarea"
                  className="col-sm-2 col-form-label text-dark "
                >
                  Vending Machine Address
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Address here..."
                    rows={3}
                    {...register("vm_address")}
                  ></textarea>
                </Col>
              </Row>
              <div className="row mb-3 ">
                <div className="col mt-2 text-dark">
                  <p>Vending Machine Shipping Zip Codes</p>
                </div>
                <div className="col-10 mt-2">
                  <SelectDropDown
                    finalArray={finalZipcodesArray}
                    defaultArray={defaultZipcodesArray}
                    setDefaultArray={setDefaultZipcodesArray}
                  />
                </div>
              </div>
              <Row className="mb-3">
                {/* <Col sm="6">
                  <label
                    htmlFor="createdby"
                    className="col-form-label text-dark"
                  >
                    Vending Machine Created By
                  </label>
                  <input
                    type="text"
                    id="createdby"
                    className="form-control"
                    placeholder="created by"
                    {...register("vm_created_by")}
                  />
                </Col> */}
                {/* <Col sm="6"> */}
                <label className="col mt-3 text-dark">Choose Store</label>
                <div className="col-10 mt-2">
                  {allStores.length > 0 ? (
                    <select
                      {...register("vm_store")}
                      className="form-control"
                      onChange={setSelectedOption}
                      value={storeOption}
                    >
                      <option value="">Select...</option>
                      {allStores.map((option) => (
                        <option key={option.store_id} value={option.store_id}>
                          {option.store_name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      {...register("vm_store")}
                      className="form-control"
                      onChange={() => {}}
                      value=""
                    >
                      <option value="">
                        Currently there are no Stores Data ...!
                      </option>
                    </select>
                  )}
                </div>
                {/* </Col> */}
              </Row>
              {/* Images */}
              <div className="row mb-3">
                <div className="col mt-3 ">
                  <label>Vending Machine Images</label>
                  <p className="mb-1 text-warning">
                    Preffered Image Size 580px x 550px{" "}
                  </p>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="vmImage" className="mt-3 mb-1">
                        Vending Machine Image One
                      </label>
                      <input
                        id="vmImage"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFile(webpFile);
                        }}
                      />
                      {!isAddMode && vmImage && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img src={vmImage} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="vmImageTwo" className="mt-3 mb-1">
                        Vending Machine Image Two
                      </label>
                      <input
                        id="vmImageTwo"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileTwo(webpFile);
                        }}
                      />
                      {!isAddMode && vmImageTwo && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={vmImageTwo}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="vmImageThree" className="mt-3 mb-1">
                        Vending Machine Image Three
                      </label>
                      <input
                        id="vmImageThree"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileThree(webpFile);
                        }}
                      />
                      {!isAddMode && vmImageThree && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={vmImageThree}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="vmImageFour" className="mt-3 mb-1">
                        Vending Machine Image four
                      </label>
                      <input
                        id="vmImageFour"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFour(webpFile);
                        }}
                      />
                      {!isAddMode && vmImageFour && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={vmImageFour}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="vmImageFive" className="mt-3 mb-1">
                        Vending Machine Image five
                      </label>
                      <input
                        id="vmImageFive"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFive(webpFile);
                        }}
                      />
                      {!isAddMode && vmImageFive && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={vmImageFive}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

function SelectDropDown({ finalArray, defaultArray, setDefaultArray }) {
  return (
    <Select
      name="select"
      options={finalArray}
      labelField="label"
      valueField="value"
      multi
      onChange={(value) => setDefaultArray(value)}
      values={defaultArray}
      clearable
      color={"#22c595"}
      style={{ padding: 10 }}
    />
  );
}
