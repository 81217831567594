import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getMemberById(id) {
  return await JwtInterceoptor.get(apiUrl + "/member");
}

export async function updateProfile(data) {
  return await JwtInterceoptor.patch(apiUrl + "/member/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateUserPassword(data) {
  return await JwtInterceoptor.patch(apiUrl + "/member/password", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function addMember(data) {
  return await JwtInterceoptor.put(apiUrl + "/member/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateMemberImage(id, image) {
  const data = { member_id: id, member_image: image };
  return await JwtInterceoptor.patch(apiUrl + "/member/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// ======== To display Member Name at Store Handler ======= //
export async function getMemberDataById(id) {
  return await JwtInterceoptor.get(apiUrl + "/member");
}

// =================================

// var SideBarId = "";

// export function setSideBarId(id) {
//   SideBarId = id;
//   console.log('SideBarId in service',SideBarId);
// }

// export function getSideBarId () {
//   return  SideBarId;
// }
