import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { convertToWebP } from "../api-services/FileConvertionService";
import Sidebar from "../layouts/Sidebar.js";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import {
  addSlider,
  updateSliderImage,
  updateSlider,
  getSliderById,
} from "../api-services/SliderOneApi.js";
import HtmlEditor from "../dashboard/websiteManagement/HtmlEditor.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function SliderOneForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [sliderOneState, setsliderOneState] = useState(3);
  const [stateValue, setStateValue] = useState("");
  const [sliderimage, setSliderImage] = useState("");

  // editor values
  const [editorValueOne, setEditorValueOne] = useState("");
  const [editorValueTwo, setEditorValueTwo] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getsliderOneDataById(id);
    }
  }, []);

  const [viewForm, setViewForm] = useState(false);

  const getsliderOneDataById = async (id) => {
    await getSliderById(id)
      .then((res) => {
        if (res.data.status == 1) {
          let data = res.data;
          let slider = data.slider;
          setViewForm(true);
          setValue("slider_one_title", slider.slider_one_title);
          setEditorValueOne(slider.slider_one_description);
          setEditorValueTwo(slider.slider_one_short_description);
          setValue("slider_one_link", slider.slider_one_link);
          setValue("slider_one_image", slider.slider_one_image);
          setsliderOneState(slider.slider_one_state);
          setSliderImage(slider.slider_one_image);
        } else {
          // console.log("error", res.data.message);
          setViewForm(false);
          toast.error(res.data.message, {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // const navigate = useNavigate();

  const form = useForm<formValues>({
    defaultValues: {
      slider_one_title: "",
      slider_one_description: "",
      slider_one_short_description: "",
      slider_one_state: 0,
      slider_one_image: "",
      slider_one_link: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    slider_one_title: string;
    slider_one_description: string;
    slider_one_short_description: string;
    slider_one_state: number;
    slider_one_image: string;
    slider_one_link: string;
  };

  // Form error handling
  const { errors } = formState;

  // Img Upload to s3
  const [file, setFile] = useState<File>();

  // click event on chose file
  // const handleClick = (event: React.FormEvent) => {
  //   const files = (event.target as HTMLInputElement).files;
  //   if (files && files.length > 0) {
  //     setFile(files[0]);
  //   }
  // };

  // state value management //
  let sliderOneStateTypes = [
    { id: 0, stateValue: "normal" },
    { id: 1, stateValue: "featured" },
  ];

  const handleChangeStateType = (event, index) => {
    let value = sliderOneStateTypes[index].stateValue;
    setStateValue(value);
    setsliderOneState(index);
  };

  // reset form
  const resetValues = () => {
    reset();
    setsliderOneState(3);
    setEditorValueOne("");
    setEditorValueTwo("");
    setSpinner(false);
  };

  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    if (isAddMode) {
      setSpinner(true);
      // console.log("data", data);
      data["slider_one_description"] = editorValueOne;
      data["slider_one_short_description"] = editorValueTwo;
      await addSlider(data)
        .then(async (res) => {
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_sliderId = response_data.slider_one_id;
            if (file) {
              let folderPath =
                s3FolderType + "/SliderOne/" + response_data_sliderId;
              let filename = response_data_sliderId + "_sliderOne_image.webp";
              let response = await UploadImagetoS3(file, folderPath, filename);
              let s3Url = response["Location"];
              // console.log("s3Url", s3Url);
              await updateSliderImage(response_data_sliderId, s3Url)
                .then((updateRes) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.mesage, {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.mesage, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= UPDATE ============== //
    } else {
      setSpinner(true);
      data.slider_one_id = id;
      data["slider_one_state"] = sliderOneState;
      data["slider_one_description"] = editorValueOne;
      data["slider_one_short_description"] = editorValueTwo;
      if (file) {
        // let folderPath = "SliderOne";
        let folderPath = s3FolderType + "/SliderOne/" + id;
        let filename = id + "_sliderOne_image.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        data["slider_one_image"] = response["Location"];
      }
      await updateSlider(data)
        .then((updateRes) => {
          if (updateRes.data.status === 1) {
            resetValues();
            setSliderImage("");
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setSliderImage("");
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/website-dashboard/homeslider">
                  Home Slider Management
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Slider" : "Edit Slider"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Slider" : "Edit Slider"}
            </h4>
            {/* {process.env.REACT_APP_S3_FOLDER_NAME} */}
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <div>
          {(viewForm || isAddMode) && (
            <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row className="mb-3">
                    <label
                      htmlFor="inputName"
                      className="col-sm-2 col-form-label"
                    >
                      Slider Name
                    </label>
                    <Col sm="10">
                      <input
                        type="text"
                        id="inputName"
                        className="form-control"
                        placeholder="slider Name"
                        {...register("slider_one_title")}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="Textarea"
                      className="col-sm-2 col-form-label"
                    >
                      Slider Description
                    </label>
                    <Col sm="10">
                      <HtmlEditor
                        editorValue={editorValueOne}
                        setEditorValue={setEditorValueOne}
                      />
                      {/* <textarea
                        className="form-control"
                        id="Textarea"
                        placeholder="Enter Description here..."
                        rows={3}
                        {...register("slider_one_description")}
                      ></textarea> */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="Textarea"
                      className="col-sm-2 col-form-label"
                    >
                      Slider Short Description
                    </label>
                    <Col sm="10">
                      <HtmlEditor
                        editorValue={editorValueTwo}
                        setEditorValue={setEditorValueTwo}
                      />
                      {/* <textarea
                        className="form-control"
                        id="Textarea"
                        placeholder="Enter Description here..."
                        rows={2}
                        {...register("slider_one_short_description")}
                      ></textarea> */}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label
                      htmlFor="inputLink"
                      className="col-sm-2 col-form-label"
                    >
                      Slider Link
                    </label>
                    <Col sm="10">
                      <input
                        type="text"
                        id="inputLink"
                        className="form-control"
                        placeholder="Slider Link"
                        {...register("slider_one_link")}
                      />
                    </Col>
                  </Row>

                  <div className="row mb-3">
                    <div className="col-lg-2 col-sm-12">
                      <p className="mt-3">Choose Slider Image</p>
                    </div>
                    <div className="col-lg-10 col-sm-12">
                      <label
                        htmlFor="sliderImage"
                        className="mb-1 text-warning"
                      >
                        Preffered Image Size 544px x 481px{" "}
                      </label>
                      <input
                        id="sliderImage"
                        type="file"
                        className="form-control"
                        // onChange={handleClick}
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFile(webpFile);
                        }}
                      />
                      {!isAddMode && sliderimage && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={sliderimage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col mt-2">
                      <p>Slider state</p>
                    </div>
                    <div className="col-10 mt-2">
                      <div className="row">
                        {sliderOneStateTypes.map((type, i) => (
                          <div className="col-lg-6" key={i}>
                            <input
                              className="mt-1"
                              id={type.stateValue}
                              key={type.id}
                              type="radio"
                              value={stateValue}
                              checked={sliderOneState === i}
                              onChange={(event) =>
                                handleChangeStateType(event, i)
                              }
                            />
                            <label
                              className="form-label ms-2"
                              htmlFor={type.stateValue}
                            >
                              {type.stateValue}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {spinner && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  )}
                  {/* Button */}
                  <div className="text-end mt-5">
                    <Button
                      type="submit"
                      style={{ paddingLeft: 50, paddingRight: 50 }}
                      variant="primary"
                    >
                      submit
                    </Button>
                  </div>
                </form>
              </Card.Body>
            </Card>
          )}
        </div>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
