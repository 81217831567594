import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllCategoriesData(type) {
  return await axios.get(apiUrl + "/subcategory/subcategories/" + type);
}

export async function addCategory(data) {
  return await JwtInterceoptor.put(apiUrl + "/subcategory/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getCategoryById(id) {
  return await axios.get(apiUrl + "/subcategory/" + id);
}

export async function getAllSubcategories() {
  return await axios.get(apiUrl + "/subcategory/");
}

export async function updateCategory(data) {
  return await JwtInterceoptor.patch(apiUrl + "/subcategory/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteSubCategoryById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/subcategory/", {
    data: {
      sub_category_id: id,
    },
  });
}

export async function updateSubCategoryState(id, state) {
  const data = { sub_category_id: id, sub_category_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/subcategory/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateCategoryImage(id, image) {
  const data = { sub_category_id: id, sub_category_images: image };
  return await JwtInterceoptor.patch(apiUrl + "/subcategory/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
