import React, { useEffect, useState } from "react";
import { Button, Card, Col, Table, Badge, Row } from "react-bootstrap";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Sidebar from "../../layouts/Sidebar";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import { useForm } from "react-hook-form";
import {
  getShopById,
  getProductsByShopId,
} from "../../api-services/Inventory/ShopAPi";
import { getAllStores } from "../../api-services/Inventory/StoreApi";
import { updateShopProduct } from "../../api-services/Inventory/ShopProductAPi";
import { getVaultProductsByStoreId } from "../../api-services/Inventory/Vault";

import Pagination from "../../docs/Pagination";
import { ForecastQueryService } from "aws-sdk";
let PageSize = 5;

// ************************************************ SHOP DETAIL PAGE ************************************************ //

export default function ShopDetailPage() {
  // const { shopId, storeId } = props.route.params;

  const { id } = useParams();

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (location?.state?.name) {
      setStoreName(location.state.name);
    }
    getShopData();
  }, []);

  const navigation = useNavigate();
  const [shopData, setShopData] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  // get products by Shop id //
  const [shopProducts, setShopProducts] = useState([]);
  const [shopProductsTwo, setShopProductsTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    // getAllProductsOfShop(shopId); //commented
    getAllStoresData(); // fetching for store name
  }, []);

  // ======================== Get Stores Data ================================= //

  const [allStores, setAllStores] = useState([]);
  const getAllStoresData = async () => {
    setLoading(true);
    await getAllStores()
      .then((res) => {
        setAllStores(res.data.stores);
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ======================== Get Shop Data Api Call ================================= //

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getShopData = async () => {
    await getShopById(id)
      .then((res) => {
        if (res.data.status == 1) {
          let shop_Data = res.data.shop;
          // console.log("Shop data", shop_Data);
          setShopData(shop_Data);
          // calling shop-products Data after shopData fetched //
          getAllProductsOfShop(id);
        } else {
          console.log(res.data.message);
          setShopData({});
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error("Failed to fetch Shop Data. \n pls try again", {
          position: "top-right",
        });
      });
  };

  // ======================== Get Specific Shop Products ================================= //

  const [totalProductsConnected, setTotalProductsConnected] = useState(0);

  const getAllProductsOfShop = async (shopId) => {
    setLoading(true);
    setShopProducts([]);
    await getProductsByShopId(shopId)
      .then((res) => {
        // console.log("shop products res", res);
        if (res.data.status == 1) {
          setTotalProductsConnected(res.data.products);
          // displaying first 5 values //
          const firstPageIndex = (currentPage - 1) * PageSize;
          const lastPageIndex = firstPageIndex + PageSize;
          let slicedData = res.data.products.slice(
            firstPageIndex,
            lastPageIndex
          );
          setShopProducts(slicedData);
          setShowPagination(true); // showing pagination
          setShopProductsTwo(res.data.products);
          setLoading(false);
        } else {
          // console.log(res.data.message);
          setShopProducts([]);
          setLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setShopProducts([]);
        setLoading(false);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = shopProductsTwo.slice(firstPageIndex, lastPageIndex);
    setShopProducts(slicedData);
  };

  // For Navigation
  const navigate = useNavigate();

  // Search related //
  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = shopProductsTwo.slice(firstPageIndex, lastPageIndex);
      setShopProducts(slicedData);
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = shopProductsTwo.filter(
      (item) => item.product_title.toLowerCase().indexOf(query) > -1
    );
    setShopProducts(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setShopProducts(shopProductsTwo);
    }
  };

  // ====================== Update Product Quantity Related ============================= //
  // setting product values
  const [productImage, setProductImage] = useState();
  const [productName, setProductName] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState("");
  const [shopProductId, setShopProductId] = useState("");

  const form = useForm();

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset, watch } = form;

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
    setProductImage("");
    setProductName("");
    setValue("shop_product_quantity", "");
    setMaximumQuantity("");
    setShopProductId("");
    setShowErrMsg(false);
  };

  const [showErrMsg, setShowErrMsg] = useState(false);
  function handleChange(event) {
    if (
      event.target.value < 0 ||
      event.target.value > watch("shop_product_quantity") + maximumQuantity
    ) {
      setShowErrMsg(true);
    } else {
      setShowErrMsg(false);
    }
  }

  const updateProductQuantity = async (data) => {
    let finalData = {
      shop_product_id: shopProductId,
      shop_product_quantity: data["shop_product_quantity"],
      shop_id: id,
    };
    // console.log("product data", finalData);
    await updateShopProduct(finalData)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          //
          getShopData();
          getAllStoresData(); // fetching for store name
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const getProductVaultQuantity = async (productStoreId, productId) => {
    // console.log("productStoreId", productStoreId);
    await getVaultProductsByStoreId(productStoreId)
      .then((res) => {
        // console.log("store products", res);
        if (res.data.status === 1) {
          if (res.data.count > 0 || res.data?.products) {
            let VaultCurrentProduct = res.data.products.filter(
              (item) => item.product_id === productId
            );
            // console.log("Vault Product", VaultCurrentProduct);
            setMaximumQuantity(
              VaultCurrentProduct[0]?.store_product
                ?.store_product_total_quantity
            );
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/inventory-dashboard/store`}>Store Management</Link>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                // onClick={() => {
                //   navigation(-1);
                // }}
              >
                {/* <span className="text-primary" style={{ cursor: "pointer" }}> */}
                <span>
                  {allStores.find((el) => el.store_id === shopData?.shop_store)
                    ?.store_name || storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Shop Detail Page
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              onClick={() => {
                navigation(-1);
              }}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-sm-inline">Back</span>
            </Button>
          </div>
        </div>

        {/* ============== Store  Details Section ========================= */}
        {shopData && (
          <Row className="g-5">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile mb-3">
                    <div className="media-img mb-3 mb-sm-0">
                      <img
                        src={shopData?.shop_images[0]}
                        className="img-fluid"
                        alt={shopData?.shop_name}
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="main-title mb-0 mt-2">
                        Shop Name :
                        <span className="text-primary">
                          {" "}
                          {shopData?.shop_name}
                        </span>
                      </h5>
                      <Row>
                        <Col xs="6">
                          <p className="mt-3">
                            Connected Store :
                            <span className="text-primary">
                              {" "}
                              {allStores.find(
                                (el) => el.store_id === shopData?.shop_store
                              )?.store_name || ""}
                            </span>
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="mt-3">
                            Shop by :
                            <span className="text-primary">
                              {" "}
                              {shopData?.shop_by}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <p className="mt-2 mb-3">
                            Shop Agent :
                            <span className="text-primary">
                              {" "}
                              {shopData?.shop_agent}
                            </span>
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="mt-2 mb-3">
                            Shop Address :
                            <span className="text-primary">
                              {" "}
                              {shopData?.shop_address}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" className="mt-2">
                          <Row>
                            <Col xs="4" className="text-grey">
                              Shop Shipping zip-codes :
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {shopData?.shop_shipping_zip_codes.length > 0 ? (
                                shopData?.shop_shipping_zip_codes.map(
                                  (item, index) => {
                                    return (
                                      <div className="ps-2" key={index}>
                                        <Badge
                                          bg="success mb-2"
                                          style={{ borderRadius: 10 }}
                                        >
                                          <span className="p-2 fs-6">
                                            {item}
                                          </span>
                                        </Badge>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <p>No Zip Codes Added</p>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mt-2">
                          <div className="">
                            <span className="me-2">
                              No. of Products Connected :
                            </span>
                            <span>
                              <Badge
                                bg="success mb-2"
                                style={{ borderRadius: 10 }}
                              >
                                <span className="p-2 fs-6">
                                  {totalProductsConnected?.length || 0}
                                </span>
                              </Badge>
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3">{shopData?.shop_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <hr />

        {/* Search */}
        <div className="d-sm-flex align-items-center justify-content-between mt-3">
          <div
            className="form-search me-auto"
            style={{ borderColor: "#0CB785" }}
          >
            <input
              type="text"
              name="text"
              className="form-control"
              placeholder="Search"
              value={q}
              onChange={(e) => searchData(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <i
              className="ri-search-line"
              style={{ cursor: "pointer" }}
              onClick={() => searchData(q)}
            ></i>
          </div>
          <div></div>
        </div>

        {/* =============== (INDIVIDUAL PACKAGE) Store Products List ========================== */}
        <div className="mt-3 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && shopProducts.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Shop-Products Data Currently</h5>
            </div>
          )}
          {!loading && !isError && shopProducts?.length > 0 && (
            <Col xs="12 mt-3 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Quantity</th>
                        {/* <th>Weight</th>
                        <th>Actual Price</th>
                        <th>Discount%</th>
                        <th>Final Price</th> */}
                        <th>Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shopProducts.length > 0 &&
                        shopProducts.map((product, index) => {
                          return (
                            <tr
                              key={product.product_id}
                              className="text-center"
                            >
                              <td>
                                <div className="news-item">
                                  <div className="news-img">
                                    <img
                                      src={product.product_images[0]}
                                      alt="Product Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {product.product_title || ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {product?.shop_product
                                    ?.shop_product_quantity || 0}
                                </span>
                              </td>
                              <td
                              // style={{ textAlign: "center" }}
                              >
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {product.product_type == 1
                                    ? "Fresh"
                                    : "Non-fresh"}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  <Link
                                    to={`/productdetailpage/${product.product_id}`}
                                  >
                                    <Button className="btn btn-primary text-light">
                                      View Product Details
                                    </Button>
                                  </Link>
                                  <Button
                                    className="btn btn-success text-light"
                                    data-bs-target="#updateStoreProduct"
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                      resetValues();
                                      setProductImage(
                                        product.product_images[0]
                                      );
                                      setProductName(product.product_title);
                                      setShopProductId(
                                        product.shop_product.shop_product_id
                                      );
                                      setValue(
                                        "shop_product_quantity",
                                        product.shop_product
                                          .shop_product_quantity
                                      );
                                      getProductVaultQuantity(
                                        shopData?.shop_store,
                                        product?.product_id
                                      );
                                    }}
                                  >
                                    Update Product Quantity
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && shopProductsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={shopProductsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
      </div>

      {/* Update Shop Product popup */}
      <div
        className="modal fade"
        id="updateStoreProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="updateStoreProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateStoreProductLabel">
                <span className="fs-4">Update shop Product Quantity</span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        <div className="mb-2 mt-5">
                          <h5 className="fs-sm fw-medium mb-1">
                            Present Quantity in Vault *
                          </h5>
                          <h3 className="card-value mb-1">
                            <i className="ri-shopping-bag-3-line"></i>
                            {maximumQuantity}
                          </h3>
                        </div>
                        <div className="mb-2 mt-3">
                          <label
                            htmlFor="productQuantity"
                            className="fs-sm fw-medium"
                          >
                            Update Product Quantity
                          </label>
                          <input
                            type="number"
                            id="productQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("shop_product_quantity", {
                              valueAsNumber: true,
                              maxLength: maximumQuantity,
                              message: "value should not be less than 0",
                            })}
                            onChange={handleChange}
                          />
                          {showErrMsg && (
                            <p className="text-danger">{`value should Not be Greater than ${
                              watch("shop_product_quantity") + maximumQuantity
                            } Or Less than 0 `}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                disabled={showErrMsg}
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(updateProductQuantity)}
              >
                <span className="fs-6">Update Quantity</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
