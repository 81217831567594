import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;
// if (process.env.NODE_ENV !== "production") {
//   apiUrl = process.env.REACT_APP_DEV_API_KEY;
// } else {
//   apiUrl = process.env.REACT_APP_DEV_API_KEY;
// }

export async function getFeaturedBrands() {
  return await axios.get(apiUrl + "/brand/brands/1");
}

export async function getNormalBrands() {
  return await axios.get(apiUrl + "/brand/brands/0");
}

export async function getAllBrands() {
  return await axios.get(apiUrl + "/brand");
}

export async function getBrandById(id) {
  return await axios.get(apiUrl + "/brand/" + id);
}

//
export async function addBrand(data) {
  return await JwtInterceoptor.put(apiUrl + "/brand/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateBrand(data) {
  return await JwtInterceoptor.patch(apiUrl + "/brand/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteBrandById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/brand/", {
    data: {
      brand_id: id,
    },
  });
}

export async function updateBrandState(id, state) {
  const data = { brand_id: id, brand_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/brand/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateBrandImage(id, image) {
  const data = { brand_id: id, brand_images: image };
  // console.log("data in service", data);
  return await JwtInterceoptor.patch(apiUrl + "/brand/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
