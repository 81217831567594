import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import { Row, Col, Card, Badge, Button, Table, Nav } from "react-bootstrap";
import { deleteImageFromS3 } from "../../api-services/s3-service";
import Sidebar from "../../layouts/Sidebar";
import toast from "react-hot-toast";
import { deleteShopById } from "../../api-services/Inventory/ShopAPi";
import {
  getPackagesByStoreId,
  deleteStorePackageById,
  inActivatePackageById,
} from "../../api-services/Inventory/PackageApi";
import { getStoreById } from "../../api-services/Inventory/StoreApi";
import { getMemberDataById } from "../../api-services/AuthService";
import {
  getVmsByStoreId,
  deleteVendingMachineById,
} from "../../api-services/VendingMachineApi";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import AuthContext from "../../auth/AuthContext";
import Pagination from "../../docs/Pagination";
let PageSize = 5;

// ************************************************ STORE DETAIL PAGE ************************************************ //

export default function StoreProductManagement() {
  const { userRole } = useContext(AuthContext);
  const { id } = useParams();
  const navigation = useNavigate();
  const [storeData, setStoreData] = useState();
  // pagination related
  const [currentPage, setCurrentPage] = useState(1); // active Packages
  const [inActivecurrentPage, setInactiveCurrentPage] = useState(1); // In-active Packages
  const [showPagination, setShowPagination] = useState(true);
  // const [key, setKey] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    getStoreData(id);
    getStoreVMData(id);
  }, []);

  // get store data //
  const getStoreData = async (storeId) => {
    setDeleteVmData({});
    setDeleteShopData({});
    await getStoreById(storeId)
      .then((res) => {
        if (res.data.status == 1) {
          let store = res.data.store;
          // console.log("store data", store);
          setStoreData(store);
          if (store?.store_handler) {
            getLoggedInUserData(store?.store_handler);
          }
        } else {
          console.log(res.data.message);
          setStoreData({});
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ================ get store Vending Machines List ====================== //
  const [vmsData, setvmsData] = useState([]);
  const getStoreVMData = async (id) => {
    await getVmsByStoreId(id)
      .then((res) => {
        if (res.data.status == 1) {
          let vms_Data = res.data.vms;
          // console.log("vms data", vms_Data);
          setvmsData(vms_Data);
        } else {
          console.log(res.data.message);
          setvmsData({});
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // delete popup //
  const [deletePackageId, setDeletePackageId] = useState();

  const removePackageByID = async () => {
    // console.log('delete id', deletePackageId);
    await deleteStorePackageById(deletePackageId)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success("Package Deleted.", {
            position: "top-right",
          });
          getAllPackagesDataByStore();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // =========================== delete Shop popup ====================== //
  const [deleteShopData, setDeleteShopData] = useState({});

  const removeShopByID = async () => {
    await deleteShopById(deleteShopData.shop_id)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          for (let i = 0; i < deleteShopData.shop_images.length; i++) {
            let imgUrl = deleteShopData.shop_images[i];
            let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
            await deleteImageFromS3(imgname);
            console.log("image ======= ", i, " ======== deleted");
          }
          getStoreData(id);
        } else if (res.data.status === 0) {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  //================= delete VM popup /================/
  // const [deleteVmId, setDeleteVmData] = ue("");
  const [deleteVmData, setDeleteVmData] = useState({});

  const removeVmByID = async () => {
    await deleteVendingMachineById(deleteVmData.vm_id)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          for (let i = 0; i < deleteVmData.vm_images.length; i++) {
            let imgUrl = deleteVmData.vm_images[i];
            let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
            await deleteImageFromS3(imgname);
            console.log("image ======= ", i, " ======== deleted");
          }
          getStoreData(id);
        } else if (res.data.status === 0) {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  // const toTimeStamp = (strDate) => {
  //     const dt = Date.parse(strDate);
  //     return dt / 1000;
  // }

  // ================================ packages related starts =============================== //
  // packages Array Variable
  const [activePackages, setActivePackages] = useState([]);
  const [activePackagesTwo, setActivePackagesTwo] = useState([]);

  const [inactivePackages, setinactivePackages] = useState([]);
  const [inactivePackagesTwo, setinactivePackagesTwo] = useState([]);

  // Get Api Call
  useEffect(() => {
    if (userRole === 7 || userRole === 5) {
      getAllPackagesDataByStore();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [packagesCount, setpackagesCount] = useState(0);

  const getAllPackagesDataByStore = async () => {
    setLoading(true);
    await getPackagesByStoreId(id)
      .then((res) => {
        // console.log("P res", res);
        setpackagesCount(res.data?.all_packages);
        // ============ Active Packages ====================== //

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        let slicedData = res.data.active_packages.slice(
          firstPageIndex,
          lastPageIndex
        );
        setActivePackages(slicedData);
        setShowPagination(true); // showing pagination
        setActivePackagesTwo(res.data.active_packages);

        // ============ In-Active Packages =================== //

        const firstPageIndex_ia = (currentPage - 1) * PageSize;
        const lastPageIndex_ia = firstPageIndex_ia + PageSize;
        let slicedData_ia = res.data.inactive_packages.slice(
          firstPageIndex_ia,
          lastPageIndex_ia
        );
        setinactivePackages(slicedData_ia);
        setinactivePackagesTwo(res.data.inactive_packages);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        // console.log("error", err.response?.data);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // showing sliced data for every page change click event - Active Packages //
  useEffect(() => {
    setSlicedPackageData(currentPage);
  }, [currentPage]);
  const setSlicedPackageData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = activePackagesTwo.slice(firstPageIndex, lastPageIndex);
    setActivePackages(slicedData);
  };

  // showing sliced data for every page change click event - InActive Packages //
  useEffect(() => {
    setSlicedInActivePackageData(inActivecurrentPage);
  }, [inActivecurrentPage]);
  const setSlicedInActivePackageData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = inactivePackagesTwo.slice(firstPageIndex, lastPageIndex);
    setinactivePackages(slicedData);
  };

  const [memberName, setMemberName] = useState("");
  const getLoggedInUserData = async (user_Id) => {
    await getMemberDataById(user_Id)
      .then((res) => {
        if (res.data.status === 1) {
          setMemberName(res.data.member.member_name);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.response?.data?.message?.message, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/inventory-dashboard/store`}>Store Management</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Store Detail Page
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            {(userRole === 7 || userRole === 5) && (
              <>
                <Button
                  className="d-flex align-items-center gap-2"
                  onClick={() => {
                    navigation("/packageform", {
                      state: {
                        name: storeData?.store_name,
                        id: storeData?.store_id,
                      },
                    });
                  }}
                  type="button"
                  size="md"
                >
                  <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                  <span className="d-none d-sm-inline">Package</span>
                </Button>
                <Button
                  className="text-white d-flex align-items-center gap-2"
                  size="md"
                  variant="success"
                  onClick={() => {
                    navigation(`/vaultManagement/${id}`);
                  }}
                >
                  <i className="ri-eye-line fs-18 lh-1"></i>View Vault
                </Button>
              </>
            )}
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => {
                navigation("/inventory-dashboard/store");
              }}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {/* ============== Store  Details Section ========================= */}
        {storeData && (
          <Row className="g-5">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile mb-3">
                    <div className="media-body">
                      <h5 className="main-title mb-4 mt-2">
                        Store Name :
                        <span className="text-primary">
                          {" "}
                          {storeData?.store_name}
                        </span>
                      </h5>
                      {storeData?.store_by && (
                        <p className="">
                          Store by :
                          <span className="text-primary">
                            {" "}
                            {storeData?.store_by}
                          </span>
                        </p>
                      )}
                      {storeData?.store_handler && (
                        <p className="">
                          Store Handler :
                          <span className="text-primary"> {memberName}</span>
                        </p>
                      )}
                      <div className="mt-2">
                        <span className="me-2">
                          No. of Packages Connected :
                        </span>
                        <span>
                          <Badge bg="success mb-2" style={{ borderRadius: 10 }}>
                            <span className="p-2 fs-6">
                              {packagesCount?.length || 0}
                            </span>
                          </Badge>
                        </span>
                      </div>
                      <p className="mt-2">{storeData?.store_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {/* Connected Shops and vending machines List */}
        <Row className="justify-content-center">
          <Col xs="6">
            <Card className="card-one" style={{ padding: 15 }}>
              <Card.Body>
                <Row>
                  <Col xs="7">
                    <h3 className="card-value mb-1">
                      {storeData?.store_shops?.length}
                    </h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Connected SHOPS
                    </label>
                  </Col>
                  <Col xs="5" className="displayCenter">
                    {(userRole === 7 || userRole === 5) && (
                      <Button
                        className="d-flex align-items-center gap-2"
                        onClick={() => {
                          navigation("/shopform", {
                            state: {
                              id: id,
                              name: storeData?.store_name,
                            },
                          });
                        }}
                        type="button"
                        size="md"
                      >
                        <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                        <span className="d-none d-sm-inline">Shop</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="card-one" style={{ padding: 15 }}>
              <Card.Body>
                <Row>
                  <Col xs="7">
                    <h3 className="card-value mb-1">{vmsData?.length || 0}</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Connected VENDING MACHINES
                    </label>
                  </Col>
                  <Col xs="5" className="displayCenter">
                    {(userRole === 7 || userRole === 5) && (
                      <Button
                        className="d-flex align-items-center gap-2"
                        onClick={() => {
                          navigation("/vendingMachineform", {
                            state: {
                              id: id,
                              name: storeData?.store_name,
                            },
                          });
                        }}
                        type="button"
                        size="md"
                      >
                        <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                        <span className="d-none d-sm-inline">
                          Vending Machine
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          {/* ======================= Connexted SHOPS table ================*/}
          {storeData?.store_shops?.length > 0 && (
            <Col xs="6 mt-2 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Shop Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {storeData?.store_shops &&
                        storeData?.store_shops.map((item) => {
                          return (
                            <tr key={item.shop_id} className="text-center">
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.shop_name}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 5) && (
                                    <>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={() => {
                                          navigation(
                                            `/shopform/${item.shop_id}`,
                                            {
                                              state: {
                                                name: storeData?.store_name,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Edit Shop
                                      </Button>

                                      <button
                                        className="btn btn-info btn-sm text-light"
                                        onClick={() => {
                                          setDeleteShopData(item);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delShopPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                  <Button
                                    className="btn btn-success text-light"
                                    size="sm"
                                    onClick={() => {
                                      navigation(
                                        `/shopDetailPage/${item.shop_id}`,
                                        {
                                          state: {
                                            name: storeData?.store_name,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    View Shop Details
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
          {/* ======================= Connected VMS Table ================= */}
          {vmsData.length > 0 && (
            <Col xs="6 mt-2 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        {/* <th>Image</th> */}
                        <th>Vending Machine Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vmsData &&
                        vmsData.map((item) => {
                          return (
                            <tr key={item.vm_id} className="text-center">
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.vm_name}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 5) && (
                                    <>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={() => {
                                          navigation(
                                            `/vendingMachineform/${item.vm_id}`,
                                            {
                                              state: {
                                                name: storeData?.store_name,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Edit Vending Machine
                                      </Button>
                                      <button
                                        className="btn btn-info btn-sm text-light"
                                        onClick={() => {
                                          setDeleteVmData(item);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delVMPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}

                                  <Button
                                    className="btn btn-success text-light"
                                    size="sm"
                                    onClick={() => {
                                      navigation(
                                        `/vendingMachineProduct/${item.vm_id}`,
                                        {
                                          state: {
                                            name: storeData?.store_name,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    View VM details
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <hr />

        {/* ============== TABS Section starts ========================= */}
        {(userRole === 7 || userRole === 5) && (
          <div className="mt-3">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="Active Packages"
            >
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link
                    eventKey="Active Packages"
                    // onClick={() => setKey("Active")}
                  >
                    Active Packages
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="InActive Packages"
                    // onClick={() => setKey("InActive")}
                    // disabled={true}
                  >
                    InActive Packages
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="Active Packages">
                  <div className="mt-4 shadow">
                    {loading && <SkeletonLoading />}
                    {!loading && isError && <ServiceUnavailable />}
                    {!loading && !isError && activePackages.length === 0 && (
                      <div className="text-center">
                        <h5 style={{ padding: 30 }}>
                          {" "}
                          No Active Packages Data Currently !
                        </h5>
                      </div>
                    )}
                    {!loading && !isError && activePackages.length > 0 && (
                      <Col xs="12 mb-3">
                        <Card className="card-one">
                          <Card.Body>
                            <Table
                              className="table-agent  table-hover mb-0"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#dae0e6bf" }}>
                                <tr className="text-center">
                                  <th>Package Title</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {activePackages.length > 0 &&
                                  activePackages.map((packages, index) => {
                                    return (
                                      <tr
                                        key={packages.package_id}
                                        className="text-center"
                                      >
                                        <td className="h6">
                                          <span
                                            className={
                                              skin == "dark"
                                                ? "category-title text-success"
                                                : "category-title"
                                            }
                                          >
                                            {packages.package_name}
                                          </span>
                                        </td>
                                        <td>
                                          <div className="d-flex gap-2 ms-2 justify-content-center">
                                            <Button
                                              variant="primary"
                                              className="d-flex align-items-center gap-2"
                                              size="md"
                                              // onClick={() => {
                                              //   navigation(`/packageform/${packages.package_id}`);
                                              // }}
                                              onClick={() => {
                                                navigation(
                                                  `/packageform/${packages.package_id}`,
                                                  {
                                                    state: {
                                                      name: storeData?.store_name,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="ri-edit-box-line fs-18 lh-1"></i>
                                              Edit
                                              <span className="d-none d-sm-inline">
                                                Package
                                              </span>
                                            </Button>
                                            <Button
                                              className="btn btn-success text-light d-flex align-items-center gap-2"
                                              onClick={() => {
                                                navigation(
                                                  `/inventory-dashboard/package/${packages.package_id}`,
                                                  {
                                                    state: {
                                                      name: storeData?.store_name,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="ri-eye-line fs-18 lh-1"></i>
                                              View
                                              <span className="d-none d-sm-inline">
                                                Package Details
                                              </span>
                                            </Button>
                                            <Button
                                              className="d-flex align-items-center gap-2 text-white"
                                              onClick={() => {
                                                setDeletePackageId(
                                                  packages.package_id
                                                );
                                              }}
                                              size="md"
                                              data-bs-toggle="modal"
                                              data-bs-target="#delPopup"
                                              variant="info"
                                            >
                                              <i className="ri-delete-bin-line"></i>
                                              <span className="d-none d-sm-inline">
                                                Remove Package
                                              </span>
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </div>
                  {/* Pagination */}
                  {showPagination && activePackagesTwo?.length > 5 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={activePackagesTwo.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="InActive Packages">
                  <div className="mt-4 shadow">
                    {!loading && !isError && inactivePackages.length === 0 && (
                      <div className="text-center">
                        <h5 style={{ padding: 30 }}>
                          {" "}
                          No In-Active Packages Data Currently !
                        </h5>
                      </div>
                    )}
                    {!loading && !isError && inactivePackages.length > 0 && (
                      <Col xs="12 mb-3">
                        <Card className="card-one">
                          <Card.Body>
                            <Table
                              className="table-agent  table-hover mb-0"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#dae0e6bf" }}>
                                <tr className="text-center">
                                  <th>Package Title</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {inactivePackages.length > 0 &&
                                  inactivePackages.map((packages, index) => {
                                    return (
                                      <tr
                                        key={packages.package_id}
                                        className="text-center"
                                      >
                                        <td className="h6">
                                          <span
                                            className={
                                              skin == "dark"
                                                ? "category-title text-success"
                                                : "category-title"
                                            }
                                          >
                                            {packages.package_name}
                                          </span>
                                        </td>
                                        <td>
                                          <div className="d-flex gap-2 ms-2 justify-content-center">
                                            <Button
                                              variant="primary"
                                              className="d-flex align-items-center gap-2"
                                              size="md"
                                              // onClick={() => {
                                              //   navigation(`/packageform/${packages.package_id}`);
                                              // }}
                                              onClick={() => {
                                                navigation(
                                                  `/packageform/${packages.package_id}`,
                                                  {
                                                    state: {
                                                      name: storeData?.store_name,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="ri-edit-box-line fs-18 lh-1"></i>
                                              Edit
                                              <span className="d-none d-sm-inline">
                                                Package
                                              </span>
                                            </Button>
                                            <Button
                                              className="btn btn-success text-light d-flex align-items-center gap-2"
                                              onClick={() => {
                                                navigation(
                                                  `/inventory-dashboard/package/${packages.package_id}`,
                                                  {
                                                    state: {
                                                      name: storeData?.store_name,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="ri-eye-line fs-18 lh-1"></i>
                                              View
                                              <span className="d-none d-sm-inline">
                                                Package Details
                                              </span>
                                            </Button>
                                            {/* <Button
                                      className="d-flex align-items-center gap-2"
                                      onClick={() => {
                                        setDeletePackageId(
                                          packages.package_id
                                        );
                                      }}
                                      size="md"
                                      data-bs-toggle="modal"
                                      data-bs-target="#delPopup"
                                      variant="danger"
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                      <span className="d-none d-sm-inline">
                                        Remove Package
                                      </span>
                                    </Button> */}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </div>
                  {showPagination && inactivePackagesTwo?.length > 5 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={inActivecurrentPage}
                      totalCount={inactivePackagesTwo.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setInactiveCurrentPage(page)}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
        {/* ============== TABS Section Ends ========================= */}
      </div>

      {/* delete package from store Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removePackageByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete Shop Modal */}
      <div
        className="modal fade"
        id="delShopPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delShopPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delShopPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeShopByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete VM Modal */}
      <div
        className="modal fade"
        id="delVMPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delVMPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delVMPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeVmByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
