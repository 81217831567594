import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>
        {process.env.REACT_APP_BRANCH_NAME} &copy; 2024. Vending Grocery. All
        Rights Reserved. Developed by Immersion Software Labs Pvt. Ltd.
      </span>
    </div>
  );
}
