import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function addStoreProduct(data) {
  return await JwtInterceoptor.put(apiUrl + "/store_product", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getStoreProductById(id) {
  return await axios.get(apiUrl + "/store_product/" + id);
}

export async function updateStoreProduct(data) {
  return await JwtInterceoptor.patch(apiUrl + "/store_product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteStoreProductById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/store_product", {
    data: {
      store_product_id: id,
    },
  });
}
