import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  getAllContacts,
  updateContacts,
  addContacts,
} from "../../api-services/ContactsApi";

export default function ContactsManagement() {
  const { userRole } = useContext(AuthContext);

  const [dataChanged, setDataCahanged] = useState(false);

  const [contactsData, setContactsData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [spinner, setSpinner] = useState(false);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    getContactsData();
  }, [dataChanged]);

  const [contactStatus, setContactStatus] = useState("add");
  const [contactId, setContactId] = useState("");
  const getContactsData = async () => {
    setLoading(true);
    await getAllContacts()
      .then((res) => {
        if (res.data.status == 1) {
          if (res.data.count > 0) {
            setContactStatus("update");
            let contacts = res.data.contacts[0];
            setContactsData(contacts);
            setContactId(contacts.contact_id);
            setValue("contact_phone_primary", contacts.contact_phone_primary);
            setValue(
              "contact_phone_secondary",
              contacts.contact_phone_secondary
            );
            setValue(
              "contact_address_primary",
              contacts.contact_address_primary
            );
            setValue(
              "contact_address_secondary",
              contacts.contact_address_secondary
            );
            setValue("contact_email_primary", contacts.contact_email_primary);
            setValue(
              "contact_email_secondary",
              contacts.contact_email_secondary
            );
          } else {
            setContactStatus("add");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("contacts", contactsData);
  };

  // ====================== Form Related ===================== //
  const form = useForm({
    defaultValues: {
      contact_phone_primary: "",
      contact_phone_secondary: "",
      contact_address_primary: "",
      contact_address_secondary: "",
      contact_email_primary: "",
      contact_email_secondary: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, setValue, reset } = form;

  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    if (contactStatus == "add") {
      await addContacts(data)
        .then((addRes) => {
          if (addRes.data.status === 1) {
            resetValues();
            toast.success(addRes.data.message, {
              position: "top-right",
            });
            setDataCahanged((value) => !value);
          } else {
            resetValues();
            // console.log("error", addRes.data.message);
            toast.error(addRes.data.message, {
              position: "top-right",
            });
            setDataCahanged((value) => !value);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    } else if (contactStatus == "update") {
      data.contact_id = contactId;
      await updateContacts(data)
        .then((updateRes) => {
          if (updateRes.data.status === 1) {
            resetValues();
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            setDataCahanged((value) => !value);
          } else {
            resetValues();
            // console.log("error", updateRes.data.message);
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
            setDataCahanged((value) => !value);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Contacts Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Contacts Management
            </h4>
          </div>
          <div></div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Contact Phone Primary
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Contact Phone Primary"
                    {...register("contact_phone_primary")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Contact Phone Secondary
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Contact Phone Secondary"
                    {...register("contact_phone_secondary")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Contact Email Primary
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Contact Email Primary"
                    {...register("contact_email_primary")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Contact Email Secondary
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Contact Email Secondary"
                    {...register("contact_email_secondary")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                  Contact Address Primary
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Contact Address Primary"
                    rows={3}
                    {...register("contact_address_primary")}
                  ></textarea>
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                  Contact Address Secondary
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Contact Address Secondary"
                    rows={3}
                    {...register("contact_address_secondary")}
                  ></textarea>
                </Col>
              </Row>
              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  disabled={userRole === 5}
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  {contactStatus == "add" ? "Add" : "Update"} Data
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
