import React from "react";
// import S3 from "react-aws-s3";
import { SecretsManager } from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

var bucket_accessKey = process.env.REACT_APP_SECRETS_ACCESSKEY;
var bucket_secretKey = process.env.REACT_APP_SECRET_SECRETKEY;

// const getSecrets = () => {
//   const secretsManager = new SecretsManager({
//     accessKeyId: process.env.REACT_APP_SECRETS_ACCESSKEY,
//     secretAccessKey: process.env.REACT_APP_SECRET_SECRETKEY,
//     region: process.env.REACT_APP_BUCKET_LOCATION,
//     // signatureVersion: 'v4',
//   });
//   // console.log("secretsManager", secretsManager);
//   return secretsManager;
// };

// const getKeys = async () => {
//   const secretName = "dev/isl/ar";

//   let secretData;
//   // Retrieve the secret value
//   await new Promise((resolve) => {
//     getSecrets().getSecretValue({ SecretId: secretName }, (err, data) => {
//       // console.log("data:",data);
//       if (err) {
//         console.error("Error retrieving secrets:", err);
//       } else {
//         if ("SecretString" in data) {
//           // console.log('SECRET DATA', data);
//           secretData = JSON.parse(data.SecretString);
//           // console.log('SECRET STRING', secretData);
//           // Get the access key and secret key from the secret
//           // console.log('AWS Access Key: ', secretData.isl_ar_access_key);
//           // console.log('AWS Secret Key: ', secretData.isl_ar_secret_key);

//           bucket_accessKey = secretData.isl_ar_access_key;
//           bucket_secretKey = secretData.isl_ar_secret_key;
//         }
//       }
//       resolve(secretData);
//     });
//     return secretData;
//   });
// };

export function UploadImagetoS3(file, folderPath, filename) {
  return new Promise(async (resolve, reject) => {
    // let secretKeys = await getKeys();
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: folderPath /* optional */,
      region: process.env.REACT_APP_BUCKET_LOCATION,
      accessKeyId: bucket_accessKey,
      secretAccessKey: bucket_secretKey,
      // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    };

    // const ReactS3Client = new S3(config);
    // ReactS3Client.uploadFile(file, filename)
    //   .then((data) =>
    //     // console.log(data),
    //     resolve(data),
    //   )
    //   .catch(err =>
    //     // console.log(err),
    //     reject(err),
    //   );
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: folderPath + "/" + filename, //to change
      Body: file,
      ACL: process.env.REACT_APP_BUCKET_ACCESS_TYPE,
      ContentType: file.type,
    };

    const s3 = new S3(config);

    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
  });
}

// export function deleteFile(folderPath, fileId, filename) {
//   return new Promise(async (resolve, reject) => {
//     let secretKeys = await getKeys();
//     const config = {
//       bucketName: process.env.REACT_APP_BUCKET_NAME,
//       dirName: folderPath  /* optional */,
//       region: process.env.REACT_APP_BUCKET_LOCATION,
//       accessKeyId: bucket_accessKey,
//       secretAccessKey: bucket_secretKey,
//       // s3Url: filename, /* optional */
//     };
//     console.log("config", config);
//     // const s3 = new S3(config);
//     const ReactS3Client = new S3(config);
//     ReactS3Client.deleteFile(filename)
//       .then((response) => resolve(response))
//       .catch((err) => reject(err));
//   });
// }

export async function deleteImageFromS3(imagepath) {
  return new Promise(async (resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: imagepath,
    };
    // console.log("params", params);
    // let secretKeys = await getKeys();
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      accessKeyId: bucket_accessKey,
      secretAccessKey: bucket_secretKey,
      region: process.env.REACT_APP_BUCKET_LOCATION,
      // apiVersion: "2006-03-01",
    };
    const s3 = new S3(config);
    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.log("There was an error deleting your Image: ", err.message);
        reject(err);
      } else {
        console.log("Successfully deleted Image.", data);
      }
      resolve(data);
    });
  });
}
