// import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import { Toaster } from "react-hot-toast";

// AUTH related //
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import Home from "./pages/LandingPage";
import Signin from "./pages/Signin";
import { AuthContextProvider } from "./auth/AuthContext";
import AuthProtectedRoute from "./auth/AuthProtectedRoute";

// import css
import "./assets/css/remixicon.css";
// import scss
import "./scss/style.scss";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            {/*===================== PRIVATE ROUTES ============================== */}
            <Route path="/" element={<Main />}>
              {protectedRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={
                      <AuthProtectedRoute accessBy={route.accessBy}>
                        {route.element}
                      </AuthProtectedRoute>
                    }
                    key={index}
                  ></Route>
                );
              })}
            </Route>
            {/*===================== PRIVATE ROUTES ============================== */}

            {/* <Route path="/login" element={<Signin />}></Route> */}

            {/*===================== PUBLIC ROUTES ============================== */}
            {publicRoutes.map((route, index) => {
              return (
                <Route path={route.path} element={route.element} key={index} />
              );
            })}
            {/*===================== PUBLIC ROUTES ============================== */}

            <Route path="*" element={<NotFound />} />
          </Routes>

          <Toaster position="top-center" reverseOrder={true} />
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
}
