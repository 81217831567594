import axios from "axios";

const JwtInterceoptor = axios.create({});

JwtInterceoptor.interceptors.request.use((config) => {
  let AuthToken = JSON.parse(localStorage.getItem("access_token"));
  if (AuthToken) {
    config.headers.Authorization = `Bearer ${AuthToken}`;
  }
  // config.headers.common["Authorization"] = `bearer ${tokensData.auth_token}`;
  return config;
});

// JwtInterceoptor.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     // if (error.response.status === 401 || error.response.status === 500) {
//     //   if(error.response?.data?.message?.message === "jwt expired" ){
//     //     console.log("in jwt" , error.response?.data?.message?.message);
//     //     localStorage.clear();  
//     //     window.location.reload();    
//     //   }
//     // }
//     //   if (error.response.status === 401) {
//     //     const authToken = JSON.parse(localStorage.getItem("access_token"));
//     //     const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
//     //     const payload = {
//     //       auth_token: authToken,
//     //       refresh_token: refreshToken,
//     //     };

//     //     let apiResponse = await axios.post(
//     //       "https://sbbackend.vendinggrocery.com/auth/gentoken",
//     //       payload,
//     //     );
//     //     localStorage.setItem(
//     //       "access_token",
//     //       JSON.stringify(apiResponse.data.auth_token),
//     //     );
//     //     localStorage.setItem(
//     //       "refres_token",
//     //       JSON.stringify(apiResponse.data.refresh_token),
//     //     );
//     //     error.config.headers[
//     //       "Authorization"
//     //     ] = `bearer ${apiResponse.data.auth_token}`;
//     //     return axios(error.config);
//     //   } else {
//     //     return Promise.reject(error);
//     //   }
//   },
// );

JwtInterceoptor.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if ((error.response.status === 401 || error.response.status === 500) && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.status === 401 || error.response.status === 500) {
        if (error.response?.data?.message?.message === "jwt expired") {
          console.log("in jwt", error.response?.data?.message?.message);
          localStorage.clear();
          window.location.reload();
        }
      }
      // window.location.reload();
      // try {
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   const response = await axios.post('/api/refresh-token', { refreshToken });
      //   const { token } = response.data;

      //   localStorage.setItem('token', token);

      //   // Retry the original request with the new token
      //   originalRequest.headers.Authorization = `Bearer ${token}`;
      //   return axios(originalRequest);
      // } catch (error) {
      //   // Handle refresh token error or redirect to login
      // }
    }

    return Promise.reject(error);
  }
);

export default JwtInterceoptor;
