import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAvailableProducts() {
  return await axios.get(apiUrl + "/product/products/available/");
}

export async function getFilterdProducts(state) {
  return await axios.get(apiUrl + "/product/products/featured/" + state);
}

export async function getAllProducts() {
  return await axios.get(apiUrl + "/product/");
}

export async function addProduct(data) {
  return await JwtInterceoptor.put(apiUrl + "/product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getProductById(id) {
  return await axios.get(apiUrl + "/product/" + id);
}

export async function updateProduct(data) {
  return await JwtInterceoptor.patch(apiUrl + "/product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteProductById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/product/", {
    data: {
      product_id: id,
    },
  });
}

export async function updateProductState(id, state) {
  const data = { product_id: id, product_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateProductImage(id, image) {
  const data = { product_id: id, product_images: image };
  return await JwtInterceoptor.patch(apiUrl + "/product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
