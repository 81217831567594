import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Table, Badge } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { getAllFaqs, deleteFaqById } from "../../api-services/FaqApi";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import { deleteImageFromS3 } from "../../api-services/s3-service";
import Footer from "../../layouts/Footer";

import faqImage from "../../assets/img/faqImage.png";

export default function FaqsManagement() {
  const { userRole } = useContext(AuthContext);

  const [faqsData, setFaqsData] = useState([]);
  const [faqsDataTwo, setFaqsDataTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // delete popup //
  const [deleteFaqId, setDeleteFaqId] = useState("");
  const [deleteFaqImageUrl, setDeleteFaqImageUrl] = useState("");

  const removeFaq = async () => {
    await deleteFaqById(deleteFaqId)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          let imgUrl = deleteFaqImageUrl;
          let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
          await deleteImageFromS3(imgname);
          // console.log("=============== image deleted ================");
          getAllFaqsData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    getAllFaqsData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllFaqsData = async () => {
    setLoading(true);
    await getAllFaqs()
      .then((res) => {
        setFaqsData(res.data.faq);
        setFaqsDataTwo(res.data.faq);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("faqsData", faqsData);
  };

  // For Navigation
  const navigate = useNavigate();

  const searchData = (val) => {
    setQ(val);
    if (val == "") {
      setFaqsData(faqsDataTwo);
      return;
    }
    const query = val.toLowerCase();
    const allData = faqsData.filter(
      (item) => item.faq_question.toLowerCase().indexOf(query) > -1
    );
    setFaqsData(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setFaqsData(faqsDataTwo);
    }
  };

  const getDateByTimestamp = (timestamp) => {
    const newDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
    return newDate;
  };

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Faq Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">Welcome to Faq Management</h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Faq Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>

              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {(userRole === 7 || userRole === 6) && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    size="md"
                    onClick={() => {
                      navigate("/faqform");
                    }}
                  >
                    <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                    <span className="d-none d-sm-inline">Faq</span>
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Faq's List Started */}

        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && faqsData.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Faqs Data Currently</h5>
            </div>
          )}
          {!loading && !isError && faqsData.length > 0 && (
            <Col xs="12 mt-5">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Question</th>
                        <th>Created On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {faqsData &&
                        faqsData.map((item) => {
                          return (
                            <tr key={item.faq_id} className="text-center">
                              <td>
                                {item.faq_image ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={item.faq_image}
                                        alt="Faq_Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={faqImage}
                                        alt="Faq_Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.faq_question}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {getDateByTimestamp(item.faq_created_on)}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Link to={`/faqform/${item.faq_id}`}>
                                        <Button variant="primary">
                                          Edit Faq
                                        </Button>
                                      </Link>

                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteFaqId(item.faq_id);
                                          setDeleteFaqImageUrl(item.faq_image);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        <Footer />
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeFaqPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeFaq}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}
    </React.Fragment>
  );
}
