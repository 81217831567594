import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm, FieldErrors } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../layouts/Header.js";
import Sidebar from "../layouts/Sidebar";
import {
  addPackage,
  updatePackage,
  getPackageById,
} from "../api-services/Inventory/PackageApi.js";
import { getAllStores } from "../api-services/Inventory/StoreApi.js";

export default function PackageForm() {
  const { id } = useParams();
  const isAddMode = !id;

  const [spinner, setSpinner] = useState(false);
  const navigation = useNavigate();
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (!isAddMode) {
      if (location?.state?.name) {
        setValue("package_store", location?.state?.name); // to display store name in form
      }
      // get and set form fields
      getStoreDataById(id);
    }
    if (isAddMode) {
      if (location?.state?.id) {
        setValue("package_store", location.state.name);
        setstoreOption(location.state.id);
      }
    }
    if (location?.state?.name) {
      setStoreName(location.state.name);
    }
  }, []);

  const getStoreDataById = async (id) => {
    await getPackageById(id)
      .then((res) => {
        if (res.data.status === 1) {
          let data = res.data;
          let packageData = data.packages;
          //
          setValue("package_name", packageData.package_name);
          // setValue("package_by", packageData.package_by);
          setstoreOption(packageData.package_store);
        } else {
          // console.log("error", res.data.message);
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const [storeOption, setstoreOption] = useState("");

  // Form Configuration

  const form = useForm<formValues>({
    defaultValues: {
      package_name: "",
      // package_by: "",
      package_store: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState,
    watch,
    control,
    setValue,
    reset,
    setError,
  } = form;

  // Interface
  type formValues = {
    package_name: String;
    // package_by: String;
    package_store: String;
  };

  // reset form
  const resetValues = () => {
    reset();
    setSpinner(false);
    setstoreOption("");
    // setStoreName("");
  };

  // =================== submit finction ====================== //
  const onSubmit = async (data: any) => {
    // console.log("data", data);
    if (isAddMode) {
      setSpinner(true);
      data["package_store"] = storeOption;
      // console.log("data", data);
      await addPackage(data)
        .then((res: any) => {
          // console.log("add response", res);
          if (res.data.status === 1) {
            resetValues();
            toast.success(res.data.message, {
              position: "top-right",
            });
            // return;
            navigation(-1);
          } else if (res.data.status === 0) {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            return;
          }
        })
        .catch((err: any) => {
          // console.log(err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ========== Update =========== //
    } else {
      setSpinner(true);
      data["package_store"] = storeOption;
      data.package_id = id;
      // data.package_state = packageState;
      // console.log("update data", data);
      await updatePackage(data)
        .then((res: any) => {
          // console.log("update response", res);
          if (res.data.status === 1) {
            resetValues();
            toast.success(res.data.message, {
              position: "top-right",
            });
            // return;
            navigation(-1);
          } else if (res.data.status === 0) {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            return;
          }
        })
        .catch((err: any) => {
          console.log(err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/inventory-dashboard/store">Store Management</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <span className="text-primary" style={{ cursor: "pointer" }}>
                  {storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Package" : "Edit Package"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Package" : "Edit Package"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">
                Back to Store Details Page
              </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label
                  htmlFor="inputName"
                  className="col-sm-2 col-form-label text-dark"
                >
                  Package Name
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Package Name"
                    {...register("package_name")}
                  />
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <label
                  htmlFor="inputName"
                  className="col-sm-2 col-form-label text-dark"
                >
                  Package By
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Package By"
                    {...register("package_by")}
                  />
                </Col>
              </Row> */}
              <Row className="mb-3">
                <label
                  htmlFor="inputName"
                  className="col-sm-2 col-form-label text-dark"
                >
                  Package Store
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Package store"
                    {...register("package_store")}
                    readOnly={true}
                  />
                </Col>
              </Row>

              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  // disabled={!isDirty || !isValid}
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
