import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Row, Col, Card, Badge, Button, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import {
  getProductsByVmId,
  getVendingMachineById,
} from "../../api-services/VendingMachineApi.js";
import {
  deleteVmProductById,
  updateVmProduct,
} from "../../api-services/VendingMachineProductApi.js";
import { getAllStores } from "../../api-services/Inventory/StoreApi";
import { getVaultProductsByStoreId } from "../../api-services/Inventory/Vault";

import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";

import Pagination from "../../docs/Pagination";
let PageSize = 5;

export default function VendingMachineProductPage() {
  const { id } = useParams();
  // const [storeId, setstoreId] = useState();

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (location?.state?.name) {
      setStoreName(location?.state?.name);
    }
    getVmData(id);
  }, []);

  const navigation = useNavigate();
  const [vmData, setVmData] = useState();

  // pagination related
  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  // get products by VM id //
  const [vmProducts, setVmProducts] = useState([]);
  const [vmProductsTwo, setVmProductsTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    getAllProductsOfVm(id);
    getAllStoresData(); // fetching for store name
  }, []);

  // ======================== Get Stores Data ================================= //

  const [allStores, setAllStores] = useState([]);
  const getAllStoresData = async () => {
    setLoading(true);
    await getAllStores()
      .then((res) => {
        setAllStores(res.data.stores);
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ======================== Get VM Data Api Call ================================= //

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getVmData = async (vmId) => {
    getVendingMachineById(vmId)
      .then((res) => {
        if (res.data.status == 1) {
          let Vm = res.data.vm;
          // console.log("vm data", Vm);
          setVmData(Vm);
          // setstoreId(Vm?.vm_store);
        } else {
          console.log(res.data.message);
          setVmData({});
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error("Failed to fetch Vending Machine Data. \n pls try again", {
          position: "top-right",
        });
      });
  };

  // ============================= get specific Vending Machine Products =================== //

  const [totalProductsConnected, setTotalProductsConnected] = useState(0);

  const getAllProductsOfVm = async (vmId) => {
    setLoading(true);
    setVmProducts([]);

    await getProductsByVmId(vmId)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("res", res);
          setTotalProductsConnected(res.data.products);
          // displaying first 5 values //
          const firstPageIndex = (currentPage - 1) * PageSize;
          const lastPageIndex = firstPageIndex + PageSize;
          let slicedData = res.data.products.slice(
            firstPageIndex,
            lastPageIndex
          );
          setVmProducts(slicedData);
          setShowPagination(true); // showing pagination
          setVmProductsTwo(res.data.products);
          setLoading(false);
        } else {
          console.log(res.data.message);
          setVmProducts([]);
          setLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setVmProducts([]);
        setLoading(false);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = vmProductsTwo.slice(firstPageIndex, lastPageIndex);
    setVmProducts(slicedData);
  };

  // Search related //
  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = vmProductsTwo.slice(firstPageIndex, lastPageIndex);
      setVmProducts(slicedData);
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = vmProductsTwo.filter(
      (item) => item.product_title.toLowerCase().indexOf(query) > -1
    );
    setVmProducts(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setVmProducts(vmProductsTwo);
    }
  };

  // ====================== Update Product Quantity Related ============================= //
  // setting product values
  const [productImage, setProductImage] = useState();
  const [productName, setProductName] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState("");
  const [vmProductId, setVmProductId] = useState("");

  const form = useForm();

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset, watch } = form;

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
    setProductImage("");
    setProductName("");
    setValue("vm_product_quantity", "");
    setMaximumQuantity("");
    setVmProductId("");
    setShowErrMsg(false);
  };

  const [showErrMsg, setShowErrMsg] = useState(false);
  function handleChange(event) {
    if (
      event.target.value < 0 ||
      event.target.value > watch("vm_product_quantity") + maximumQuantity
    ) {
      setShowErrMsg(true);
    } else {
      setShowErrMsg(false);
    }
  }

  const getProductVaultQuantity = async (productStoreId, productId) => {
    console.log("productStoreId", productStoreId);
    await getVaultProductsByStoreId(productStoreId)
      .then((res) => {
        // console.log("store products", res);
        if (res.data.status === 1) {
          if (res.data.count > 0 || res.data?.products) {
            let VaultCurrentProduct = res.data.products.filter(
              (item) => item.product_id === productId
            );
            // console.log("Vault Product", VaultCurrentProduct);
            setMaximumQuantity(
              VaultCurrentProduct[0]?.store_product
                ?.store_product_total_quantity
            );
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const updateProductQuantity = async (data) => {
    let finalData = {
      vm_product_id: vmProductId,
      vm_product_quantity: data["vm_product_quantity"],
      vm_id: id,
    };
    // console.log("product data", finalData);
    await updateVmProduct(finalData)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          //
          getVmData(id);
          getAllProductsOfVm(id);
          getAllStoresData();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/inventory-dashboard/store">Store Management</Link>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                // onClick={() => {
                //   navigation(-1);
                // }}
              >
                {/* <span className="text-primary" style={{ cursor: "pointer" }}> */}
                <span>
                  {allStores.find((el) => el.store_id === vmData?.vm_store)
                    ?.store_name || storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Vending Machine Detail Page
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              // size="md"
              onClick={() => {
                navigation(-1);
              }}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back</span>
            </Button>
          </div>
        </div>

        {/* ============== Vending Machine Details Section ========================= */}
        {vmData && (
          <Row className="g-5 mb-3">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile">
                    <div className="media-img mb-3 mb-sm-0">
                      <img
                        src={vmData?.vm_images[0]}
                        className="img-fluid"
                        alt={vmData?.vm_name}
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="media-name mb-4 mt-2 text-primary">
                        {vmData?.vm_name}
                      </h5>
                      <Row>
                        <Col xs="6">
                          <p className="">
                            Connected Store :
                            <span className="text-primary">
                              {" "}
                              {allStores.find(
                                (el) => el.store_id === vmData?.vm_store
                              )?.store_name || ""}
                            </span>
                          </p>
                        </Col>
                        <Col xs="6">
                          <p className="">
                            Created By :
                            <span className="text-primary">
                              {" "}
                              {vmData?.vm_created_by}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" className="mt-2">
                          <Row>
                            <Col xs="4" className="text-grey">
                              Vending Machine Shipping zip-codes :
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {vmData?.vm_shipping_zip_codes.length > 0 ? (
                                vmData?.vm_shipping_zip_codes.map(
                                  (item, index) => {
                                    return (
                                      <div className="ps-2" key={index}>
                                        <Badge
                                          bg="success mb-2"
                                          style={{ borderRadius: 10 }}
                                        >
                                          <span className="p-2 fs-6">
                                            {item}
                                          </span>
                                        </Badge>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <p>No Zip Codes Added</p>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="6" className="mt-2">
                          <p className="mb-3">
                            Vending Machine Address :
                            <span className="text-primary">
                              {" "}
                              {vmData?.vm_address}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        <span className="me-2">
                          No. of Products Connected :
                        </span>
                        <span>
                          <Badge bg="success mb-2" style={{ borderRadius: 10 }}>
                            <span className="p-2 fs-6">
                              {totalProductsConnected?.length || 0}
                            </span>
                          </Badge>
                        </span>
                      </div>
                      <p className="mt-3">{vmData?.vm_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {/* Search */}
        <div className="d-sm-flex align-items-center justify-content-between mt-3">
          <div
            className="form-search me-auto"
            style={{ borderColor: "#0CB785" }}
          >
            <input
              type="text"
              name="text"
              className="form-control"
              placeholder="Search"
              value={q}
              onChange={(e) => searchData(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <i
              className="ri-search-line"
              style={{ cursor: "pointer" }}
              onClick={() => searchData(q)}
            ></i>
          </div>
          <div></div>
        </div>

        {/* =============== Vending Machine Products List ========================== */}
        <div className="mt-2 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && vmProducts.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}>
                {" "}
                Currently No Products Connected to This Vending Machine !
              </h5>
            </div>
          )}
          {!loading && !isError && vmProducts?.length > 0 && (
            <Col xs="12 mt-3 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Quantity</th>
                        {/* <th>Weight</th>
                        <th>Actual Price</th>
                        <th>Discount%</th>
                        <th>Final Price</th> */}
                        <th>Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vmProducts.length > 0 &&
                        vmProducts.map((product) => {
                          return (
                            <tr
                              key={product.product_id}
                              className="text-center"
                            >
                              <td>
                                <div className="news-item">
                                  <div className="news-img">
                                    <img
                                      src={product?.product_images[0]}
                                      alt="Product Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {product.product_title || ""}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals text-success"
                                      : "ff-numerals"
                                  }
                                >
                                  {product?.vending_machine_product
                                    ?.vm_product_quantity || 0}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {product.product_type == 1
                                    ? "Fresh"
                                    : "Non-fresh"}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  <Link
                                    to={`/productdetailpage/${product.product_id}`}
                                  >
                                    <Button className="btn btn-primary text-light">
                                      View Product Details
                                    </Button>
                                  </Link>
                                  <Button
                                    className="btn btn-success text-light"
                                    data-bs-target="#updateStoreProduct"
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                      resetValues();
                                      setProductImage(
                                        product.product_images[0]
                                      );
                                      setProductName(product.product_title);
                                      setVmProductId(
                                        product?.vending_machine_product
                                          ?.vm_product_id
                                      );
                                      setValue(
                                        "vm_product_quantity",
                                        product?.vending_machine_product
                                          ?.vm_product_quantity
                                      );
                                      getProductVaultQuantity(
                                        vmData?.vm_store,
                                        product?.product_id
                                      );
                                    }}
                                  >
                                    Update Product Quantity
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && vmProductsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={vmProductsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
      </div>

      {/* Update Shop Product popup */}
      <div
        className="modal fade"
        id="updateStoreProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="updateStoreProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateStoreProductLabel">
                <span className="fs-4">
                  Update Vending Machine Product Quantity
                </span>
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        <div className="mb-2 mt-5">
                          <h5 className="fs-sm fw-medium mb-1">
                            Present Quantity in Vault *
                          </h5>
                          <h3 className="card-value mb-1">
                            <i className="ri-shopping-bag-3-line"></i>
                            {maximumQuantity}
                          </h3>
                        </div>
                        <div className="mb-2 mt-3">
                          <label
                            htmlFor="productQuantity"
                            className="fs-sm fw-medium"
                          >
                            Update Product Quantity
                          </label>
                          <input
                            type="number"
                            id="productQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("vm_product_quantity", {
                              valueAsNumber: true,
                              maxLength: maximumQuantity,
                              message: "value should not be less than 0",
                            })}
                            onChange={handleChange}
                          />
                          {showErrMsg && (
                            <p className="text-danger">{`value should Not be Greater than ${
                              watch("vm_product_quantity") + maximumQuantity
                            } Or Less than 0 `}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                disabled={showErrMsg}
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(updateProductQuantity)}
              >
                <span className="fs-6">Update Quantity</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
