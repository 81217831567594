import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { deleteImageFromS3 } from "../api-services/s3-service";
import Footer from "../layouts/Footer";
import {
  getAllProducts,
  deleteProductById,
  updateProductState,
} from "../api-services/ProductApi";
import { getAllBrands } from "../api-services/BrandApi";
import { getAllSubcategories } from "../api-services/SubCategoryApi";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import SkeletonLoading from "../pages/SkeletonLoading";
import Pagination from "../docs/Pagination";
import AuthContext from "../auth/AuthContext";
let PageSize = 5;

export default function ProductManagement() {
  const { userRole } = useContext(AuthContext);
  // pagination related
  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  const [allProducts, setAllProducts] = useState([]);
  const [allProductsTwo, setAllProductsTwo] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // delete popup //
  const [deleteProduct, setDeleteProduct] = useState({});

  const removeProductByID = async () => {
    await deleteProductById(deleteProduct.product_id)
      .then(async (res) => {
        if (res.data.status == 1) {
          toast.success("Product Deleted.", {
            position: "top-right",
          });
          for (let i = 0; i < deleteProduct?.product_images.length; i++) {
            let imgUrl = deleteProduct.product_images[i];
            let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
            await deleteImageFromS3(imgname);
            console.log("image ======= ", i, " ======== deleted");
          }
          getAllProductsData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    getAllProductsData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllProductsData = async () => {
    setLoading(true);
    await getAllProducts()
      .then((res) => {
        // displaying first 5 values //
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        let slicedData = res.data.products.slice(firstPageIndex, lastPageIndex);
        setAllProducts(slicedData);
        setShowPagination(true); // showing pagination
        setAllProductsTwo(res.data.products);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ========  showing sliced data for every page change click event ========== //
  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = allProductsTwo.slice(firstPageIndex, lastPageIndex);
    setAllProducts(slicedData);
  };
  // Get Api Call

  useEffect(() => {
    getAllBrandsData();
    getAllCategoriesData();
  }, []);

  const getAllBrandsData = async () => {
    await getAllBrands()
      .then((res) => {
        if (res.data.status === 1) {
          setAllBrands(res.data.brands);
        } else {
          console.log("Error", res.data.message);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const getAllCategoriesData = async () => {
    await getAllSubcategories()
      .then((res) => {
        if (res.data.status === 1) {
          setAllCategories(res.data.sub_categories);
        } else {
          console.log("Error", res.data.message);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // For Navigation
  const navigate = useNavigate();

  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = allProductsTwo.slice(firstPageIndex, lastPageIndex);
      setAllProducts(slicedData); // assigning sliced data
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = allProductsTwo.filter(
      (item) => item.product_title.toLowerCase().indexOf(query) > -1
    );
    setAllProducts(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setAllProducts(allProductsTwo);
    }
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/product-dashboard">Product Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Product Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Product Management
            </h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Product Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>

              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {(userRole === 7 || userRole === 6) && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    size="md"
                    onClick={() => {
                      navigate("/productform");
                    }}
                  >
                    <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                    <span className="d-none d-sm-inline">Product</span>
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Products List Started */}
        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && allProducts.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Products Data Currently</h5>
            </div>
          )}
          {!loading && !isError && allProducts.length > 0 && (
            <Col xs="12 mt-5 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allProducts &&
                        allProducts.map((item) => {
                          return (
                            <tr key={item.product_id} className="text-center">
                              <td>
                                {item.product_images.length > 0 ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={item.product_images[0]}
                                        alt={item.product_title}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item">
                                    <div className="interest-icon bg-light ">
                                      {/* <i className="ri-github-fill"></i> */}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.product_title}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Button
                                        variant="primary"
                                        onClick={() => {
                                          navigate(
                                            `/productform/${item.product_id}`,
                                            {
                                              state: {
                                                name: item?.product_title,
                                                id: item?.product_id,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Edit Product
                                      </Button>
                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteProduct(item);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                  <Link
                                    to={`/productdetailpage/${item.product_id}`}
                                  >
                                    <Button variant="success">
                                      View Details
                                    </Button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && allProductsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={allProductsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}

        <Footer />
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeProductByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}
    </React.Fragment>
  );
}
