import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function addVmProduct(data) {
  return await JwtInterceoptor.put(apiUrl + "/vending_machine_product", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getVmProductById(id) {
  return await axios.get(apiUrl + "/vending_machine_product/" + id);
}

export async function updateVmProduct(data) {
  return await JwtInterceoptor.patch(
    apiUrl + "/vending_machine_product/",
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export async function deleteVmProductById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/vending_machine_product", {
    data: {
      vm_product_id: id,
    },
  });
}
