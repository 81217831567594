import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import {
  getMediaById,
  updateMedia,
  addMedia,
  updateMediaImage,
} from "../api-services/SocialMediaApi";
import { convertToWebP } from "../api-services/FileConvertionService";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function SocialMediaForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getMediaDataById(id);
    }
  }, []);

  const [mediaimage, setMediaImage] = useState("");

  const getMediaDataById = async (id) => {
    await getMediaById(id)
      .then((res) => {
        if (res.data.status === 1) {
          let data = res.data;
          let media = data.medias[0];
          // console.log("res", media);
          setValue("media_title", media.media_title);
          setValue("media_image", media.media_image);
          setValue("media_url", media.media_url);
          setMediaImage(media.media_image);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      media_title: "",
      media_image: "",
      media_url: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    media_title: String;
    media_image: String;
    media_url: String;
  };

  // Form error handling
  // const { errors } = formState;

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  // const handleClick = (event: React.FormEvent) => {
  //   const files = (event.target as HTMLInputElement).files;
  //   if (files && files.length > 0) {
  //     setFile(files[0]);
  //   }
  // };

  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    if (isAddMode) {
      setSpinner(true);
      await addMedia(data)
        .then(async (res) => {
          // console.log("add response", res);
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_mediaId = response_data.media_id;
            if (file) {
              let folderPath = s3FolderType + "/SocialMedia";
              let filename = response_data_mediaId + "_media_image.webp";
              let response = await UploadImagetoS3(file, folderPath, filename);
              let s3Url = response["Location"];
              await updateMediaImage(response_data_mediaId, s3Url)
                .then((updateRes) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.message, {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.message, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            // console.log("error meg", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= Update =============== //
    } else {
      setSpinner(true);
      data.media_id = id;
      if (file) {
        let folderPath = s3FolderType + "/SocialMedia";
        let filename = id + "_media_image.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        data["media_image"] = response["Location"];
      }
      await updateMedia(data)
        .then((res) => {
          if (res.data.status === 1) {
            resetValues();
            setMediaImage("");
            toast.success(res.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setMediaImage("");
            // console.log("error", res.data.message);
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/website-dashboard/socialmedia">
                  Social Media Management
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Social Media" : "Edit Social Media"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Social Media" : "Edit Social Media"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Social Media Name
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Social Media Name"
                    {...register("media_title")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputUrl" className="col-sm-2 col-form-label">
                  Social Media Url
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputUrl"
                    className="form-control"
                    placeholder="Social Media Url"
                    {...register("media_url")}
                  />
                </Col>
              </Row>

              <div className="row mb-3">
                <div className="col-lg-2 col-sm-12">
                  <label htmlFor="mediaImage" className="mt-3">
                    Social Media Image
                  </label>
                </div>
                <div className="col-lg-10 col-sm-12 mt-2">
                  {/* <label className="mb-1 text-warning">Preffered Image Size 162px x 162px </label> */}
                  <input
                    type="file"
                    className="form-control"
                    id="mediaImage"
                    // onChange={handleClick}
                    onChange={async (e) => {
                      let webpFile: any = await convertToWebP(
                        e.target.files[0]
                      );
                      setFile(webpFile);
                    }}
                  />
                  {!isAddMode && mediaimage && (
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Current Image</label>
                      <div className="news-item">
                        <div className="news-img fullwidth-img">
                          <img src={mediaimage} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
