import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import HtmlEditor from "./HtmlEditor";
import { getService, updateService } from "../../api-services/ContactsApi";

export default function ServicesManagement() {
  const { userRole } = useContext(AuthContext);
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [dataChanged, setDataCahanged] = useState(false);

  const [serviceData, setServiceData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const [editorValueOne, setEditorValueOne] = useState();
  const [editorValueTwo, setEditorValueTwo] = useState();
  const [editorValueThree, setEditorValueThree] = useState();

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    getServiceData();
  }, [dataChanged]);

  const getServiceData = async () => {
    setLoading(true);
    await getService()
      .then((res) => {
        let service = res.data.service;
        setServiceData(service);
        //
        setEditorValueOne(service.service_disclaimer);
        setEditorValueTwo(service.service_privacy_policy);
        setEditorValueThree(service.service_terms);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ====================== Form Related ===================== //
  const form = useForm({
    defaultValues: {
      service_disclaimer: "",
      service_privacy_policy: "",
      service_terms: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, setValue, reset } = form;

  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    data.service_id = "1";
    data["service_disclaimer"] = editorValueOne;
    data["service_privacy_policy"] = editorValueTwo;
    data["service_terms"] = editorValueThree;
    // console.log("data in update", data);
    await updateService(data)
      .then((updateRes) => {
        if (updateRes.data.status === 1) {
          resetValues();
          toast.success(updateRes.data.message, {
            position: "top-right",
          });
          setDataCahanged((value) => !value);
        } else {
          resetValues();
          // console.log("error", updateRes.data.message);
          toast.error(updateRes.data.message, {
            position: "top-right",
          });
          setDataCahanged((value) => !value);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Service Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Service Management
            </h4>
          </div>
          <div></div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Desclaimer
                </label>
                <Col sm="10">
                  <HtmlEditor
                    editorValue={editorValueOne}
                    setEditorValue={setEditorValueOne}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Privacy Policy
                </label>
                <Col sm="10">
                  <HtmlEditor
                    editorValue={editorValueTwo}
                    setEditorValue={setEditorValueTwo}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Terms
                </label>
                <Col sm="10">
                  <HtmlEditor
                    editorValue={editorValueThree}
                    setEditorValue={setEditorValueThree}
                  />
                </Col>
              </Row>
              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  disabled={userRole === 5}
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  Update Data
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
