import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import {
  getMemberById,
  updateProfile,
  addMember,
  updateMemberImage,
} from "../api-services/AuthService";
import { getAllStores } from "../api-services/Inventory/StoreApi";
import { convertToWebP } from "../api-services/FileConvertionService";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function ProfileForm() {
  const { id } = useParams();
  const isAddMode = !id;

  const [spinner, setSpinner] = useState(false);
  const navigation = useNavigate();
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [selectedRoletype, setSelectedRoletype] = useState(""); // choose Role

  const [showUserRole, setShowUserRole] = useState(false);

  const [userRole, setUserRole] = useState(0);
  useEffect(() => {
    if (location?.state?.role) {
      setUserRole(location.state.role);
      // console.log("user role", location.state.role);
    }

    // to display User role in Form //
    if (location?.state?.type) {
      if (location?.state?.type === "AddingMember") {
        setShowUserRole(true);
      } else {
        setShowUserRole(false);
      }
    } else {
      setShowUserRole(false);
    }

    if (!isAddMode) {
      // get and set form fields
      getUserDataById(id);
    }

    getAllStoresData();
  }, []);

  const [userimage, setUserImage] = useState("");

  const form = useForm<formValues>({
    defaultValues: {
      member_name: "",
      member_phone: 0,
      member_address: "",
      member_documents: [
        {
          title: "",
          url: "",
        },
      ],
      // member_role: 0,
      member_bio: "",
      // member_state: 0,
      member_email: "",
      member_image: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    member_name: String;
    member_bio: String;
    member_phone: number;
    member_email: String;
    member_image: String;
    member_address: String;
    member_documents: {
      title: string;
      url: string;
    }[];
    // member_role: number,
    // member_state: number,
  };

  // Form error handling
  const { errors } = formState;

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  // const handleClick = (event: React.FormEvent) => {
  //   const files = (event.target as HTMLInputElement).files;
  //   if (files && files.length > 0) {
  //     setFile(files[0]);
  //   }
  // };

  // reset form
  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  // get brand by id api //
  const getUserDataById = async (id) => {
    await getMemberById(id)
      .then((res: any) => {
        if (res.data.status === 1) {
          let data = res.data;
          let member = data.member;

          setValue("member_name", member?.member_name);
          setValue("member_phone", member?.member_phone);
          setValue("member_address", member?.member_address);
          setValue("member_bio", member?.member_bio);
          setValue("member_email", member?.member_email);
          setValue("member_image", member?.member_image);
          setUserImage(member?.member_image);
          setValue("member_documents", member?.member_documents); // s3 image urls
        }
      })
      .catch((err: any) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ============================ Field Array related ============================== //

  const fieldArray2 = useFieldArray({
    name: "member_documents",
    control,
  });

  const handleDocumentsImages = async (e, index) => {
    // s3
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    //
    let file = e.target.files[0];
    let fileType = file.name.split(".");
    let fileExt = fileType.pop();
    let filename =
      watch(`member_documents.${index}.title`).split(" ").join("") +
      "." +
      fileExt;
    if (file) {
      let folderPath = s3FolderType + "/Admins/" + id + "/" + "documents";
      let response = await UploadImagetoS3(file, folderPath, filename);
      let s3Url = response["Location"];
      toast.success("image uploaded", {
        position: "top-right",
      });
      fieldArray2.fields[index].url = s3Url;
      fieldArray2.fields[index].title = watch(
        `member_documents.${index}.title`
      );
    }
  };
  // ============================= Field Array related ============================== //

  const onSubmit = async (data: any) => {
    // s3
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    // console.log("data", data);
    if (isAddMode) {
      setSpinner(true);
      data["member_documents"] = [];
      data["member_role"] = Number(selectedRoletype);
      if (selectedRoletype === "5") {
        data.store_id = storeId;
      }
      // console.log("final Add Member Data", data);
      await addMember(data)
        .then(async (res: any) => {
          if (res.data.status === 1) {
            let response = res;
            let adminId = response.data.member_id;
            if (file) {
              let folderPath = s3FolderType + "/Admins/" + adminId;
              let filename = adminId + "_member_image.webp";
              let response = await UploadImagetoS3(file, folderPath, filename);
              let memberImage = response["Location"];
              // ========= update member with image ================= //
              await updateMemberImage(adminId, memberImage)
                .then((updateResponse: any) => {
                  if (updateResponse.data.status === 1) {
                    resetValues();
                    toast.success("Member Created SuccessFully", {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else if (updateResponse.data.status === 0) {
                    resetValues();
                    // console.log("error", updateResponse.data.message);
                    toast.error(updateResponse.data.message, {
                      position: "top-right",
                    });
                  }
                })
                .catch((err: any) => {
                  // console.log("err", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
              // ========= update member with image ================= //
            } else {
              resetValues();
              setUserRole(0);
              toast.success("Member Created Successfully", {
                position: "top-right",
              });
              navigation(-1);
            }
          } else if (res.data.status === 0) {
            resetValues();
            // setUserRole(0);
            // console.log("error", res.data.message);
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err: any) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ================= UPDATE STARTS =================== //
    } else {
      setSpinner(true);
      data.member_id = id;
      if (file) {
        let folderPath = s3FolderType + "/Admins/" + id;
        let filename = id + "_member_image.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        data["member_image"] = response["Location"];
      }
      for (let i = 0; i < fieldArray2.fields.length; i++) {
        fieldArray2.fields[i].title = watch(`member_documents.${i}.title`)
          .split(" ")
          .join("");
      }
      data["member_documents"] = fieldArray2.fields;
      // console.log("final data", data);
      await updateProfile(data)
        .then((updateRes: any) => {
          if (updateRes.data.status === 1) {
            resetValues();
            setUserImage("");
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else if (updateRes.data.status === 0) {
            resetValues();
            setUserImage("");
            // console.log("error", updateRes.data.message);
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err: any) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  let imageFormats = ["jpg", "jpeg", "gif", "png", "webp", "svg", "avif"];

  // ========================= get Stores data ===================== //
  const [allStores, setAllStores] = useState([]);
  const getAllStoresData = async () => {
    await getAllStores()
      .then((res) => {
        if (res.data.status === 1) {
          setAllStores(res.data.stores);
        } else {
          setAllStores([]);
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  const [storeId, setStoreId] = useState("");
  const setSelectedStoreOption = (event) => {
    const option = allStores.find(
      (option) => option.store_id === event.target.value
    );
    setStoreId(option.store_id);
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                {userRole === 7 && (
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    Super Admin
                  </span>
                )}
                {userRole === 6 && (
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    Site Admin
                  </span>
                )}
                {userRole === 5 && (
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    Store Admin
                  </span>
                )}
              </li>
              {userRole === 7 && (
                <li className="breadcrumb-item active" aria-current="page">
                  {isAddMode ? "Add New Member" : "Edit Member"}
                </li>
              )}
              {(userRole === 6 || userRole === 5) && (
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Member
                </li>
              )}
            </ol>
            {userRole === 7 && (
              <h4 className="main-title mb-0 mt-3">
                {isAddMode ? "Add New Member" : "Edit member"}
              </h4>
            )}
            {(userRole === 6 || userRole === 5) && (
              <h4 className="main-title mb-0 mt-3">Edit member</h4>
            )}
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline"> Back </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-4">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Member Name
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Member Name"
                    {...register("member_name")}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                  Member Bio
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Bio here..."
                    rows={3}
                    {...register("member_bio")}
                  ></textarea>
                </Col>
              </Row>
              <Row className="mb-4">
                <label
                  htmlFor="Textareatwo"
                  className="col-sm-2 col-form-label"
                >
                  Member Address
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textareatwo"
                    placeholder="Enter Address here..."
                    rows={3}
                    {...register("member_address")}
                  ></textarea>
                </Col>
              </Row>

              {/* ======== ONLY VISIBLE FOR SUPER ADMIN , WHEN ADDING SITE ADMIN , STORE ADMIN =========== */}
              {userRole === 7 && showUserRole && (
                <div className="row mb-4">
                  <div className="col-lg-2 col-sm-12">
                    <label htmlFor="userimage">Choose Member Role</label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <select
                      className="form-control"
                      id="roletype"
                      value={selectedRoletype}
                      onChange={(e) => setSelectedRoletype(e.target.value)}
                    >
                      <option value="">Select Role...</option>
                      <option value={6}>Site Admin</option>
                      <option value={5}>Store Admin</option>
                    </select>
                  </div>
                </div>
              )}

              {selectedRoletype === "5" && (
                <>
                  {allStores?.length > 0 ? (
                    <div className="row mb-3">
                      <div className="col-lg-2 col-sm-12">
                        <label className="">Choose Store</label>
                      </div>
                      <div className="col-lg-10 col-sm-12">
                        <div>
                          <select
                            // {...register("store_id")}
                            className="form-control"
                            onChange={setSelectedStoreOption}
                            value={storeId}
                          >
                            <option value="">Select Store ... </option>
                            {allStores.map((option) => (
                              <option
                                key={option.store_id}
                                value={option.store_id}
                              >
                                {option.store_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mb-4">
                        <div className="col-lg-2 col-sm-12"></div>
                        <div className="col-lg-10 col-sm-12">
                          <p className="card-value mb-1 text-danger">
                            <i className="ri-information-fill fs-10 lh-1 text-danger"></i>
                            Please add One Store. To Create Store Admin .
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {/* ======== ONLY VISIBLE FOR SUPER ADMIN , WHEN ADDING SITE ADMIN , STORE ADMIN =========== */}

              <div className="row mb-4">
                <div className="col-lg-2 col-sm-12">
                  <label htmlFor="userimage">Member Image</label>
                </div>
                <div className="col-lg-10 col-sm-12">
                  <input
                    type="file"
                    className="form-control"
                    id="userimage"
                    // onChange={handleClick}
                    onChange={async (e) => {
                      let webpFile: any = await convertToWebP(
                        e.target.files[0]
                      );
                      setFile(webpFile);
                    }}
                  />
                  {userimage && (
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Current Image</label>
                      <div className="news-item">
                        <div className="news-img fullwidth-img">
                          <img src={userimage} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Row>
                <Col sm="6">
                  <div className="mb-3">
                    <label
                      htmlFor="inputNumber"
                      className="col-form-label text-dark "
                    >
                      Member Phone Number
                    </label>
                    <input
                      type="number"
                      id="inputNumber"
                      className="form-control"
                      placeholder="Member Phone"
                      {...register("member_phone", {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3">
                    <label
                      htmlFor="inputEmail"
                      className="col-form-label text-dark "
                    >
                      Member Email
                    </label>
                    <input
                      type="text"
                      id="inputEmail"
                      className="form-control"
                      placeholder="Member Email"
                      {...register("member_email", {
                        required: "Please Enter Your Email!",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please Enter A Valid Email!",
                        },
                      })}
                    />
                    <p className="text-danger">
                      {errors?.member_email?.message}
                    </p>
                  </div>
                </Col>
              </Row>

              {/* Documents */}
              {!isAddMode && (
                <div className="row mb-3">
                  <div className="col-lg-2 col-sm-12">
                    <p>Member Documents</p>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <div>
                      {fieldArray2.fields.map((field, index) => {
                        return (
                          <div className="row mb-2" key={field.id}>
                            <div className="col-lg-5 col-xl-5 col-md-5">
                              <label>Title</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register(
                                  `member_documents.${index}.title` as const
                                )}
                              />
                            </div>

                            <div className="col-lg-5 col-xl-5 col-md-5">
                              <label>File</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  handleDocumentsImages(e, index);
                                }}
                              />
                              {imageFormats.includes(
                                fieldArray2.fields[index].url.split(".").pop()
                              ) ? (
                                <div className="mt-3 mb-3">
                                  <label className="mb-2">Current Image</label>
                                  <div className="news-item">
                                    <div className="news-img fullwidth-img">
                                      <img
                                        src={fieldArray2.fields[index].url}
                                        alt={fieldArray2.fields[index].title}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                fieldArray2.fields[index].url && (
                                  <div className="mt-3 mb-3">
                                    <label className="mb-2">
                                      Current File :
                                      <span className="text-primary">
                                        {" "}
                                        {fieldArray2.fields[index].url
                                          .split(".")
                                          .pop()}
                                      </span>
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="col-lg-2 col-xl-2 col-md-3">
                              {index > 0 && (
                                <div className="mt-3">
                                  <button
                                    type="button"
                                    className="btn btn-danger mt-1"
                                    onClick={() => fieldArray2.remove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          fieldArray2.append({ title: "", url: "" })
                        }
                      >
                        + Add Field
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
