import axios from "axios";
import JwtInterceoptor from "../../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllShops() {
  return await axios.get(apiUrl + "/shop");
}

export async function addShop(data) {
  return await JwtInterceoptor.put(apiUrl + "/shop/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getShopById(id) {
  return await axios.get(apiUrl + "/shop/" + id);
}

export async function updateShop(data) {
  return await JwtInterceoptor.patch(apiUrl + "/shop/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteShopById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/shop/", {
    data: {
      shop_id: id,
    },
  });
}

export async function getProductsByShopId(id) {
  return await axios.get(apiUrl + "/shop/products/" + id);
}

export async function updateShopImage(id, image) {
  const data = { shop_id: id, shop_images: image };
  // console.log("data in service",data);
  return await JwtInterceoptor.patch(apiUrl + "/shop/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
