import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Badge, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import Sidebar from "../layouts/Sidebar";
import {
  getAllBrands,
  deleteBrandById,
  updateBrandState,
} from "../api-services/BrandApi";
import {
  getAllSubcategories,
  deleteSubCategoryById,
  updateSubCategoryState,
} from "../api-services/SubCategoryApi";
import {
  getAllCategories,
  deleteCategoryById,
  updateCategoryState,
} from "../api-services/CategoryApi";
import { getAllProducts } from "../api-services/ProductApi";
import { deleteImageFromS3 } from "../api-services/s3-service";
import AuthContext from "../auth/AuthContext";

export default function Commondetailpage() {
  const { userRole } = useContext(AuthContext);
  const { id } = useParams();

  const [dataChanged, setDataChanged] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const [pageData, setPageData] = useState({});
  const [pageType, setPageType] = useState("");
  const [formType, setFormType] = useState("");

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [views, setViews] = useState("");

  const [allCategories, setAllCategories] = useState([]);

  // for Brands
  const [brandProducts, setBrandProducts] = useState([]);
  // for Categories
  const [subCategories, setSubCategories] = useState([]);
  // for Sub Categories
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  // move to normal/featured related
  const [stateById, changeStateById] = useState("");

  // delete popup //
  const [deleteData, setDeleteData] = useState({});

  const removeByID = async () => {
    if (pageType === "Brand") {
      // console.log(" Brand Page Data", pageData);
      await deleteBrandById(deleteData?.brand_id)
        .then(async (res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            for (let i = 0; i < pageData?.brand_images.length; i++) {
              let imgUrl = pageData?.brand_images[i];
              let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
              await deleteImageFromS3(imgname);
              console.log("image ======= ", i, " ======== deleted");
            }
            navigate("/product-dashboard/Brand");
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= category ============ //
    } else if (pageType === "Category") {
      // console.log("Category Page Data", pageData);
      await deleteCategoryById(deleteData?.category_id)
        .then(async (res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            for (let i = 0; i < pageData?.category_images.length; i++) {
              let imgUrl = pageData?.category_images[i];
              let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
              await deleteImageFromS3(imgname);
              console.log("image ======= ", i, " ======== deleted");
            }
            navigate("/product-dashboard/Category");
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= sub category ============ //
    } else if (pageType === "SubCategory") {
      // console.log("SubCategory Page Data", pageData);
      await deleteSubCategoryById(deleteData?.sub_category_id)
        .then(async (res) => {
          if (res.data.status === 1) {
            toast.success("Category Deleted.", {
              position: "top-right",
            });
            for (let i = 0; i < pageData?.sub_category_images.length; i++) {
              let imgUrl = pageData?.sub_category_images[i];
              let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
              await deleteImageFromS3(imgname);
              console.log("image ======= ", i, " ======== deleted");
            }
            navigate("/product-dashboard/Subcategory");
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  const updateStateByID = async (state) => {
    if (pageType === "Brand") {
      await updateBrandState(stateById, state)
        .then((res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            setDataChanged(true);
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= category ============ //
    } else if (pageType === "Category") {
      await updateCategoryState(stateById, state)
        .then((res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            setDataChanged(true);
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= sub category ============ //
    } else if (pageType === "SubCategory") {
      await updateSubCategoryState(stateById, state)
        .then((res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            setDataChanged(true);
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  useEffect(() => {
    async function fetchData() {
      setPageData({}); // making Page Data Variable state empty in beginning
      setDataFetched(false);
      // =================== Brands Data ========================= //
      await getAllBrands()
        .then((res) => {
          if (res.data.status === 1) {
            // console.log("entered brands");
            let brandData = res.data.brands;
            for (const el of brandData) {
              // console.log("brand loop");
              if (el.brand_id === id) {
                setPageData(el);
                setPageType("Brand");
                setFormType("brandform");
                setTitle(el.brand_title);
                // setImage(JSON.parse(el.brand_image)[0]);
                setImage(el.brand_images[0]);
                setState(el.brand_state);
                setDescription(el.brand_description);
                setBrandProducts(el.products);
                setViews(el.brand_views);
                setDataFetched(true);
                break;
              }
            }
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      //
      // ======================= Categories Data ========================= //
      await getAllCategories()
        .then((res) => {
          if (res.data.status === 1) {
            // console.log("enterd categories");
            let categoriesData = res.data.categories;
            setAllCategories(categoriesData);
            for (const el of categoriesData) {
              if (pageType == "") {
                // console.log("category loop");
                if (el.category_id === id) {
                  setPageData(el);
                  setPageType("Category");
                  setFormType("categoryform");
                  setTitle(el.category_title);
                  // setImage(JSON.parse(el.category_image)[0]);
                  setImage(el.category_images[0]);
                  setState(el.category_state);
                  setDescription(el.category_description);
                  setSubCategories(el.sub_categories);
                  setDataFetched(true);
                  break;
                }
              }
            }
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ======================= Sub Categories Data ========================= //
      await getAllSubcategories()
        .then((res) => {
          if (res.data.status === 1) {
            // console.log("ented sub categories");
            let subCategoryData = res.data.sub_categories;
            for (const el of subCategoryData) {
              if (pageType == "") {
                // console.log("Sub category loop");
                if (el.sub_category_id === id) {
                  setPageData(el);
                  setPageType("SubCategory");
                  setFormType("subcategoryform");
                  setTitle(el.sub_category_title);
                  // setImage(JSON.parse(el.sub_category_image)[0]);
                  setImage(el.sub_category_images[0]);
                  setState(el.sub_category_state);
                  setDescription(el.sub_category_description);
                  setCategories(el.sub_category_categories);
                  setProducts(el.products);
                  setDataFetched(true);
                  break;
                }
              }
            }
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      console.log("loop ended");
    }
    fetchData();
  }, [dataChanged]);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const navigate = useNavigate();

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  ///// Skin Switch /////

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      {!dataFetched ? (
        <div className="main main-app p-5 p-lg-4">
          <div className="parentContainer">
            <div className="childContainer">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/product-dashboard">Product Dashboard</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/product-dashboard/${pageType}`}>
                    {pageType} Management
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {pageType} Detail Page
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              {(userRole === 7 || userRole === 6) && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(`/${formType}/${id}`, {
                        state: {
                          name: title,
                          id: id,
                        },
                      });
                    }}
                  >
                    Edit {pageType}
                  </Button>

                  {state === 0 ? (
                    <Button
                      variant="success"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#featuredPopup"
                      onClick={() => {
                        changeStateById(id);
                      }}
                    >
                      Make Featured
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#NormalPopup"
                      onClick={() => {
                        changeStateById(id);
                      }}
                    >
                      Make Normal
                    </Button>
                  )}
                  {pageData && (
                    <button
                      className="btn btn-info text-light"
                      onClick={() => {
                        setDeleteData(pageData);
                      }}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#delPopup"
                    >
                      Delete {pageType}
                    </button>
                  )}
                </>
              )}
              <Button
                style={{
                  backgroundColor: "#dae0e6bf",
                  color: "black",
                  borderColor: "#dae0e6bf",
                }}
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="ri-arrow-left-fill fs-18 lh-1"></i>
                <span className="d-sm-inline"> Back </span>
              </Button>
            </div>
          </div>

          {/*  */}

          <Row className="g-5">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile mb-3">
                    <div className="media-img mb-3 mb-sm-0">
                      <img src={image} className="img-fluid" alt={title} />
                    </div>
                    <div className="media-body">
                      <h5 className="media-name mb-4 mt-2">{title}</h5>
                      {/* <p className="d-flex gap-2 mb-4">
                                             <i className="ri-map-pin-line"></i> San Francisco, California
                                        </p> */}
                      <p className="mt-2">{description}</p>
                    </div>
                  </div>

                  <Row className="row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5">
                    {pageType === "Brand" && (
                      <Col>
                        <div className="profile-item">
                          <i className="ri-suitcase-line"></i>
                          <div className="profile-item-body">
                            <p>{brandProducts?.length || 0}</p>
                            <span>Products</span>
                          </div>
                        </div>
                      </Col>
                    )}
                    {pageType === "Category" && (
                      <Col>
                        <div className="profile-item">
                          <i className="ri-suitcase-line"></i>
                          <div className="profile-item-body">
                            <p>{subCategories?.length || 0}</p>
                            <span>Sub Categories</span>
                          </div>
                        </div>
                      </Col>
                    )}
                    {pageType === "SubCategory" && (
                      <>
                        <Col>
                          <div className="profile-item">
                            <i className="ri-suitcase-line"></i>
                            <div className="profile-item-body">
                              <p>{categories?.length || 0}</p>
                              <span>Categories</span>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="profile-item">
                            <i className="ri-suitcase-line"></i>
                            <div className="profile-item-body">
                              <p>{products?.length || 0}</p>
                              <span>Products</span>
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col>
                      <div className="profile-item">
                        <i className="ri-suitcase-line"></i>
                        <div className="profile-item-body">
                          <p>{state === 0 ? "Normal" : "Featured"}</p>
                          <span>State</span>
                        </div>
                      </div>
                    </Col>
                    {pageType === "Brand" && (
                      <Col>
                        <div className="profile-item">
                          <i className="ri-team-line"></i>
                          <div className="profile-item-body">
                            <p>{views || 0}</p>
                            <span>Views</span>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <div className="card mt-3">
            <div className="card-body">
              <Row>
                <Col xs="4" className="text-end text-primary">
                  Status :
                </Col>
                <Col>
                  {state === 0 ? (
                    <Badge bg="secondary p-2" pill>
                      Normal
                    </Badge>
                  ) : (
                    <Badge bg="success p-2" pill>
                      Featurd
                    </Badge>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Description :
                </Col>
                <Col>{description}</Col>
              </Row>
              <hr />
              {pageType === "Brand" && (
                <>
                  <Row className="mt-2 p-2">
                    <Col xs="4" className="text-end text-primary">
                      Products Connected :
                    </Col>
                    <Col>
                      {brandProducts?.length > 0
                        ? brandProducts.map((item, index) => {
                            return (
                              <div
                                className="d-sm-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <Badge
                                  bg="success mb-2"
                                  style={{
                                    borderRadius: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/productdetailpage/${item.product_id}`
                                    );
                                  }}
                                >
                                  <span className="p-2 fs-6">
                                    {item.product_title}
                                  </span>
                                </Badge>
                              </div>
                            );
                          })
                        : 0}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-2 p-2">
                    <Col xs="4" className="text-end text-primary">
                      Views :
                    </Col>
                    <Col>{views}</Col>
                  </Row>
                  <hr />
                </>
              )}
              {pageType === "SubCategory" && (
                <>
                  <Row className="mt-2 p-2">
                    <Col xs="4" className="text-end text-primary">
                      Products Connected :
                    </Col>
                    <Col>
                      {products?.length > 0
                        ? products.map((item, index) => {
                            return (
                              <div
                                className="d-sm-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <Badge
                                  bg="success mb-2"
                                  style={{
                                    borderRadius: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/productdetailpage/${item.product_id}`
                                    );
                                  }}
                                >
                                  <span className="p-2 fs-6">
                                    {item.product_title}
                                  </span>
                                </Badge>
                              </div>
                            );
                          })
                        : 0}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-2 p-2">
                    <Col xs="4" className="text-end text-primary">
                      Categories Connected :
                    </Col>
                    <Col>
                      {categories
                        ? categories.map((item, index) => {
                            return (
                              <div
                                className="d-sm-flex align-items-center justify-content-between"
                                key={index}
                              >
                                <Badge
                                  bg="success mb-2"
                                  style={{
                                    borderRadius: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(`/commondetailpage/${item}`);
                                    setDataChanged(true);
                                    window.location.reload();
                                  }}
                                >
                                  <span className="p-2 fs-6">
                                    {allCategories.find(
                                      (el) => el.category_id === item
                                    )?.category_title || ""}
                                  </span>
                                </Badge>
                              </div>
                            );
                          })
                        : 0}
                    </Col>
                  </Row>
                </>
              )}
              {pageType === "Category" && (
                <Row className="mt-2 p-2">
                  <Col xs="4" className="text-end text-primary">
                    Sub Categories Connected :
                  </Col>
                  <Col>
                    {subCategories
                      ? subCategories.map((item, index) => {
                          return (
                            <div
                              className="d-sm-flex align-items-center justify-content-between"
                              key={index}
                            >
                              <Badge
                                bg="success mb-2"
                                style={{ borderRadius: 10, cursor: "pointer" }}
                                onClick={() => {
                                  navigate(
                                    `/commondetailpage/${item.sub_category_id}`
                                  );
                                  setDataChanged(true);
                                  window.location.reload();
                                }}
                              >
                                <span className="p-2 fs-6">
                                  {item.sub_category_title}
                                </span>
                              </Badge>
                            </div>
                          );
                        })
                      : 0}
                  </Col>
                </Row>
              )}
              <hr />
            </div>
          </div>
          {/*  */}
        </div>
      )}

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}

      {/* Make Featured  */}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Move it to Featured?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateStateByID(1)}
              >
                Make Featured
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Featured  */}

      {/* Make Normal  */}
      <div
        className="modal fade"
        id="NormalPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="NormalPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="NormalPopupLabel">
                Are You want to Move it to Normal?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateStateByID(0)}
              >
                Make Normal
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Normal */}
    </>
  );
}
