//import liraries
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Table, Badge, Row } from "react-bootstrap";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useForm } from "react-hook-form";
import {
  addStoreProduct,
  deleteStoreProductById,
  getStoreProductById,
  updateStoreProduct,
} from "../../api-services/StoreProductApi";
import { ThreeDots } from "react-loader-spinner";
import { getAllStores } from "../../api-services/Inventory/StoreApi";
import { getAllProducts } from "../../api-services/ProductApi";
import {
  getPackageById,
  getProductsByPackageId,
  inActivatePackageById,
  completePackage,
} from "../../api-services/Inventory/PackageApi";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";

import Pagination from "../../docs/Pagination";
let PageSize = 5;

// ************************************************ Package DETAIL PAGE ************************************************ //

export default function PackageDetailPage() {
  const { id } = useParams();
  const navigation = useNavigate();
  const [packageData, setPackageData] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  // get products by package id //
  const [storeProducts, setStoreProducts] = useState([]);
  const [storeProductsTwo, setStoreProductsTwo] = useState([]);

  // setting product values
  const [productImage, setProductImage] = useState();
  const [productName, setProductName] = useState();
  const [productId, setProductId] = useState();
  const [storeProductId, setStoreProductId] = useState("");

  // setting form type
  const [storeFormType, setStoreFormType] = useState("add");

  // search related
  const [q, setQ] = useState("");

  const [completePackageId, setcompletePackageId] = useState();
  // const [packageBy, setPackageBy] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (location?.state?.name) {
      setStoreName(location.state.name);
    }
    getAllTemplateProductsData();
    getAllProductsOfPackage(id);
    getAllStoresData(); // fetching all stores data for connected store name
  }, []);

  const [allTemplateProducts, setAllTemplateProducts] = useState([]);
  const getAllTemplateProductsData = async () => {
    setAllTemplateProducts([]);
    await getAllProducts()
      .then((res) => {
        setAllTemplateProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
        setAllTemplateProducts([]);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ======================== Get Package Data Api Call ================================= //
  useEffect(() => {
    getPackageData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getPackageData = async () => {
    // setLoading(true);
    await getPackageById(id)
      .then((res) => {
        if (res.data.status === 1) {
          let package_Data = res.data.packages;
          // console.log("package data", package_Data);
          setPackageData(package_Data);
        } else {
          // console.log(res.data.message);
          setPackageData({});
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const checkProductQuantity = async () => {
    let isAnyProductQuantityisZero;
    storeProductsTwo.every((el) => {
      if (el.product_quantity === 0) {
        toast.error(
          "Product Should not Have Zero Quantity , To Close Package",
          {
            position: "top-right",
          }
        );
        isAnyProductQuantityisZero = true;
        return false;
      } else {
        isAnyProductQuantityisZero = false;
        return true;
      }
    });
    return isAnyProductQuantityisZero;
  };

  const makePackageCompleteByID = async () => {
    let isAnyProductQuantityisZero = await checkProductQuantity();
    // console.log("isAnyProductQuantityisZero",isAnyProductQuantityisZero);
    if (!isAnyProductQuantityisZero) {
      await completePackage(completePackageId)
        .then((res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            getPackageData();
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  //============================== delete product related =================================== //
  const [deleteProductId, setDeleteProductId] = useState();
  const removeProductByID = async () => {
    // console.log('delete id', deleteProductId);
    await deleteStoreProductById(deleteProductId)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success("Product Deleted.", {
            position: "top-right",
          });
          getAllProductsOfPackage(id);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm({
    defaultValues: {
      store_id: "",
      product_id: "",
      store_product_quantity: 0,
      store_product_package_id: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
  };

  // ======================== Get Package All Products ================================= //

  const getAllProductsOfPackage = async (packageId) => {
    setLoading(true);
    setStoreProducts([]);
    await getProductsByPackageId(packageId)
      .then((res) => {
        if (res.data.status == 1) {
          // console.log("store products res", res);
          // displaying first 5 values //
          const firstPageIndex = (currentPage - 1) * PageSize;
          const lastPageIndex = firstPageIndex + PageSize;
          let slicedData = res.data.products.slice(
            firstPageIndex,
            lastPageIndex
          );
          setStoreProducts(slicedData);
          setShowPagination(true); // showing pagination
          setStoreProductsTwo(res.data.products);
          setLoading(false);
        } else {
          // console.log(res.data.message);
          setStoreProducts([]);
          setLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setStoreProducts([]);
        setLoading(false);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = storeProductsTwo.slice(firstPageIndex, lastPageIndex);
    setStoreProducts(slicedData);
  };

  const searchData = (val) => {
    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = storeProductsTwo.slice(firstPageIndex, lastPageIndex);
      setStoreProducts(slicedData);
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = storeProductsTwo.filter(
      (item) => item.product_title.toLowerCase().indexOf(query) > -1
    );
    setStoreProducts(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setStoreProducts(storeProductsTwo);
    }
  };

  // For Navigation
  const navigate = useNavigate();

  // ============================= Get Total Products ===================================== //
  const [productsLoader, setProductsLoader] = useState(true);
  var filteredProducts = [];
  const [allProducts, setallProducts] = useState([]);
  const getTotalProducts = async () => {
    setProductsLoader(false);
    setallProducts([]);
    await getAllProducts()
      .then((res) => {
        if (res.data.status == 1) {
          let all_Products = res.data.products;
          // console.log("Total Products", all_Products);
          // ========= hiding already added products from ALL PRODUCTS LIST =========== //
          filteredProducts = [];
          for (let i = 0; i < all_Products.length; i++) {
            let filteredProduct = storeProductsTwo.find(
              (item) => item.product_id == all_Products[i].product_id
            );
            if (!filteredProduct) {
              filteredProducts.push(all_Products[i]);
            }
          }
          // console.log("filteredProducts", filteredProducts);
          // ========= hiding already added products from ALL PRODUCTS LIST =========== //
          setallProducts(filteredProducts);
          // setallProducts(all_Products);
          setProductsLoader(true);
        } else {
          // console.log(res.data.message);
          setallProducts([]);
          setProductsLoader(true);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // ============================ Add / update Store Product ================================== //
  const addNewStoreProduct = async (data) => {
    // console.log("data", data);
    data["store_id"] = packageData?.package_store;
    data["product_id"] = productId;
    data["store_product_package_id"] = id;
    // console.log("data", data);
    if (storeFormType == "add") {
      await addStoreProduct(data)
        .then((res) => {
          if (res.data.status == 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            resetValues();
            getAllProductsOfPackage(id);
          } else {
            console.log("error", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    } else if (storeFormType == "update") {
      data.store_product_id = storeProductId;
      // console.log("update data", data);
      await updateStoreProduct(data)
        .then((res) => {
          if (res.data.status == 1) {
            toast.success(res.data.message, {
              position: "top-right",
            });
            resetValues();
            getAllProductsOfPackage(id);
          } else {
            console.log("error", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  // ============================ assign Value during update ================================ //
  const setProductData = (product) => {
    setStoreProductId(product.store_product_id);
    setValue("store_product_quantity", product.store_product_quantity);
    setProductId(product.product_id);

    setProductImage(productImage);
    setProductName(productName);
  };

  const [allStores, setAllStores] = useState([]);
  const getAllStoresData = async () => {
    setLoading(true);
    await getAllStores()
      .then((res) => {
        setAllStores(res.data.stores);
      })
      .catch((err) => {
        setAllStores([]);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/inventory-dashboard/store`}>Store Management</Link>
              </li>
              <li
                className="breadcrumb-item"
                aria-current="page"
                onClick={() => {
                  navigation(-1);
                }}
              >
                <span className="text-primary" style={{ cursor: "pointer" }}>
                  {storeName}
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Package Detail Page
              </li>
            </ol>
            <h6 className="main-title mb-0 mt-5">
              Package Name :
              <span className="text-primary"> {packageData?.package_name}</span>
            </h6>
            <p className="mt-3">
              Connected Store :
              <span className="text-primary">
                {" "}
                {allStores.find(
                  (el) => el.store_id === packageData?.package_store
                )?.store_name || ""}
              </span>
            </p>
            <p className="">
              Package by :
              <span className="text-primary"> {packageData?.package_by}</span>
            </p>
            <div className="mt-3">
              <span className="me-2">No. of Products Connected :</span>
              <span>
                <Badge bg="success mb-2" style={{ borderRadius: 10 }}>
                  <span className="p-2 fs-6">{storeProductsTwo?.length}</span>
                </Badge>
              </span>
            </div>
          </div>
          <div className="d-flex gap-2 mt-3">
            {packageData && packageData?.package_state !== 0 && (
              <>
                <Button
                  className="d-flex align-items-center gap-2"
                  onClick={() => {
                    getTotalProducts();
                    setStoreFormType("add");
                  }}
                  type="button"
                  size="md"
                  data-bs-toggle="modal"
                  data-bs-target="#addProductsPopup"
                >
                  <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                  <span className="d-sm-inline">Store Product</span>
                </Button>
                <Button
                  className="text-white"
                  size="md"
                  data-bs-toggle="modal"
                  data-bs-target="#toggleStatePopup"
                  variant="info"
                  onClick={() => {
                    setcompletePackageId(packageData?.package_id);
                    // setPackageBy(packageData?.package_by)
                  }}
                >
                  Close Package
                </Button>
              </>
            )}
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              onClick={() => {
                navigation(-1);
              }}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-sm-inline">Back to Store Detail Page</span>
            </Button>
          </div>
        </div>

        {/* Search */}
        <div className="d-sm-flex align-items-center justify-content-between">
          <div
            className="form-search me-auto"
            style={{ borderColor: "#0CB785" }}
          >
            <input
              type="text"
              name="text"
              className="form-control"
              placeholder="Search"
              value={q}
              onChange={(e) => searchData(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <i
              className="ri-search-line"
              style={{ cursor: "pointer" }}
              onClick={() => searchData(q)}
            ></i>
          </div>
          <div></div>
        </div>
        {/* =============== (INDIVIDUAL PACKAGE) Store Products List ========================== */}
        <div className="mt-4 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && storeProducts.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Store-Products Data Currently</h5>
            </div>
          )}
          {!loading && !isError && storeProducts?.length > 0 && (
            <Col xs="12 mt-2 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Quantity</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {storeProducts.length > 0 &&
                        storeProducts.map((product, index) => {
                          return (
                            <tr
                              key={product.product_id}
                              className="text-center"
                            >
                              <td>
                                <div className="news-item">
                                  <div className="news-img">
                                    <img
                                      // src={
                                      //   allTemplateProducts.find(
                                      //     (el) => el.product_id === product.product_id,
                                      //   )?.product_images[0] || ""
                                      // }
                                      src={product.product_images[0]}
                                      alt="Product Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {/* {allTemplateProducts.find(
                                    (el) => el.product_id === product.product_id,
                                  )?.product_title || ""} */}
                                  {product.product_title}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {product.product_quantity || 0}
                                </span>
                              </td>
                              {/* <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex align-items-center gap-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addStoreProduct"
                                    size="md"
                                    onClick={() => {
                                      setStoreFormType("update");
                                      setProductData(product);

                                      setProductImage(product.product_images[0]);
                                      setProductName(product.product_title)
                                    }}
                                  >
                                    <i className="ri-edit-box-line fs-18 lh-1"></i>
                                    Edit
                                    <span className="d-none d-sm-inline">
                                      Product
                                    </span>
                                  </Button>
                                  <Button
                                    className="d-flex align-items-center gap-2"
                                    onClick={() => {
                                      setDeleteProductId(
                                        product.product_id,
                                      );
                                    }}
                                    size="md"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delPopup"
                                    variant="danger"
                                    disabled={true}
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                    <span className="d-none d-sm-inline">
                                      Remove Product
                                    </span>
                                  </Button>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>

        {/* Pagination */}
        {showPagination && storeProductsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={storeProductsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
      </div>

      {/* Add Products / get All products Modal */}
      <div
        className="modal fade"
        id="addProductsPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addProductsPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addProductsPopupLabel">
                Total Products
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!productsLoader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <Row className="g-3">
                  {allProducts.length > 0 ? (
                    allProducts.map((product, index) => {
                      return (
                        <Col sm="3" key={index}>
                          {
                            // storeProducts.includes(product) &&
                            <Card className="card-one">
                              <Card.Body className="p-3">
                                <div className="text-center p-3 bg-white rounded mb-3">
                                  <img
                                    src={product.product_images[0]}
                                    className="img-fluid"
                                    alt={product.product_title}
                                  />
                                </div>
                                <h6 className="fw-semibold text-dark lh-4">
                                  {product.product_title}
                                </h6>
                                <div className="d-grid">
                                  <Button
                                    variant="primary"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addStoreProduct"
                                    onClick={() => {
                                      setProductImage(
                                        product.product_images[0]
                                      );
                                      setProductName(product.product_title);
                                      setProductId(product.product_id);
                                    }}
                                  >
                                    Add to Store
                                  </Button>
                                </div>
                              </Card.Body>
                            </Card>
                          }
                        </Col>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <h5 style={{ padding: 30 }}>
                        {" "}
                        No Products Data Currently !
                      </h5>
                    </div>
                  )}
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add to store popup */}
      <div
        className="modal fade"
        id="addStoreProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addStoreProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addStoreProductLabel">
                {storeFormType == "add" && (
                  <span className="fs-4">Add Product to store</span>
                )}
                {storeFormType == "update" && (
                  <span className="fs-4">Update Store Product </span>
                )}
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={productImage}
                    className="img-fluid"
                    alt={productName}
                  />
                  <h5 className="media-name mt-2 text-primary">
                    {productName}
                  </h5>
                </div>
                <div className="col-lg-6">
                  <form noValidate>
                    <Row>
                      <Col sm="12">
                        <div className="mb-2 mt-5">
                          <label
                            htmlFor="productQuantity"
                            className="col-form-label text-dark"
                          >
                            Choose Product Quantity
                          </label>
                          <input
                            type="number"
                            id="productQuantity"
                            className="form-control"
                            placeholder="Product Quantity"
                            {...register("store_product_quantity", {
                              valueAsNumber: true,
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
              {/* Form */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(addNewStoreProduct)}
              >
                {storeFormType == "add" && (
                  <span className="fs-6">Add to Store</span>
                )}
                {storeFormType == "update" && (
                  <span className="fs-6">Update Store Product</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete product from store Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeProductByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* make package Close Modal */}
      <div
        className="modal fade"
        id="toggleStatePopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="toggleStatePopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="toggleStatePopupLabel">
                Are You Sure want to Make this Package Close?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={makePackageCompleteByID}
              >
                Close Package
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
