import axios from "axios";
import JwtInterceoptor from "../../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function addPackage(data) {
  return await JwtInterceoptor.put(apiUrl + "/package/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updatePackage(data) {
  return await JwtInterceoptor.patch(apiUrl + "/package/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteStorePackageById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/package/", {
    data: {
      package_id: id,
    },
  });
}

export async function inActivatePackageById(id, package_by) {
  const data = { package_id: id, package_state: 0, package_by: package_by };
  return await JwtInterceoptor.patch(apiUrl + "/package/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function completePackage(id) {
  const data = { package_id: id };
  return await JwtInterceoptor.patch(apiUrl + "/package/complete", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getPackagesByStoreId(id) {
  return await JwtInterceoptor.get(apiUrl + "/package/packages/" + id);
}

export async function getPackageById(id) {
  return await JwtInterceoptor.get(apiUrl + "/package/" + id);
}

export async function getProductsByPackageId(id) {
  return await JwtInterceoptor.get(apiUrl + "/package/products/" + id);
}
