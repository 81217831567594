import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useContext } from "react";
import AuthContext from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import BrandImage from "../assets/img/brand.png";
import CategoryImage from "../assets/img/category.png";
import ProductImage from "../assets/img/Products.png";

const ProductDashboard = () => {
  // const { user } = useContext(AuthContext);
  // const navigate = useNavigate();
  // useEffect(() => {

  // }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Product Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Product Dashboard
            </h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0"></div>
        </div>

        <Row className="g-3">
          <Col sm="4">
            <Link to="/product-dashboard/Brand">
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      {/* <i className={"ri-shopping-bag-3-line"}></i> */}
                      <img src={BrandImage} className="img-fluid p-3" />
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Brand Management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm="4">
            <Link to="/product-dashboard/Category">
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <img src={CategoryImage} className="img-fluid p-3" />
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Category management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm="4">
            <Link to="/product-dashboard/Subcategory">
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <img src={CategoryImage} className="img-fluid p-3" />
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Sub Category management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm="4">
            <Link to="/product-dashboard/product">
              <Card className="card-one dashboardCard">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="helpdesk-icon text-white bg-primary">
                      <img src={ProductImage} className="img-fluid p-3" />
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        Product management
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductDashboard;
