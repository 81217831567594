import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

// ============================= Footer Section ============================= //

export async function getFooter() {
  return await axios.get(apiUrl + "/footer/");
}

export async function addFooter(data) {
  return await JwtInterceoptor.put(apiUrl + "/footer/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateFooter(data) {
  return await JwtInterceoptor.patch(apiUrl + "/footer/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// ========================== About Us =================================== //

export async function getAboutUs() {
  return await axios.get(apiUrl + "/aboutus/allaboutus");
}

export async function updateAboutUs(data) {
  return await JwtInterceoptor.put(apiUrl + "/aboutus", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
