import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

// ================================ Contacts Apis ============================ //

export async function getAllContacts() {
  return await axios.get(apiUrl + "/contact/");
}

export async function addContacts(data) {
  return await JwtInterceoptor.put(apiUrl + "/contact/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateContacts(data) {
  return await JwtInterceoptor.patch(apiUrl + "/contact/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteContactById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/contact/", {
    data: {
      contact_id: id,
    },
  });
}

// ================================ Service Apis ============================ //

export async function addService(data) {
  return await JwtInterceoptor.put(apiUrl + "/service/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateService(data) {
  return await JwtInterceoptor.patch(apiUrl + "/service/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getService() {
  return await axios.get(apiUrl + "/service/");
}
