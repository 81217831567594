import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import { convertToWebP } from "../api-services/FileConvertionService";
import { getAboutUs, updateAboutUs } from "../api-services/FooterAPi";
import HtmlEditor from "../dashboard/websiteManagement/HtmlEditor";
import toast from "react-hot-toast";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function AboutUsForm() {
  const [spinner, setSpinner] = useState(false);
  const [aboutUsImage, setaboutUsImage] = useState("");
  const [aboutUsImageTwo, setaboutUsImageTwo] = useState("");
  const [aboutUsVideo, setaboutUsVideo] = useState("");
  const [editorValueOne, setEditorValueOne] = useState();
  const [editorValueTwo, setEditorValueTwo] = useState();
  const [dataChanged, setDataCahanged] = useState(false);
  const navigation = useNavigate();

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    getAboutUsData();
  }, [dataChanged]);

  const getAboutUsData = async () => {
    await getAboutUs()
      .then((res: any) => {
        let data = res.data;
        // console.log("data", data);
        if (data.status == 1) {
          let aboutUsData = data.aboutus;
          setValue("aboutus_title", aboutUsData?.aboutus_title);
          setValue("aboutus_misc", aboutUsData?.aboutus_misc);
          setValue("count_members", aboutUsData?.count_members);
          setValue("count_customers", aboutUsData?.count_customers);
          setValue("count_orders", aboutUsData?.count_orders);
          setValue("aboutus_estd", aboutUsData?.aboutus_estd);
          setValue("aboutus_image_1", aboutUsData?.aboutus_image_1);
          setValue("aboutus_image_2", aboutUsData?.aboutus_image_2);
          setValue("aboutus_video", aboutUsData?.aboutus_video);
          //
          setEditorValueOne(aboutUsData?.aboutus_description);
          setEditorValueTwo(aboutUsData?.aboutus_sub_description);
          setaboutUsImage(aboutUsData?.aboutus_image_1);
          setaboutUsImageTwo(aboutUsData?.aboutus_image_2);
          setaboutUsVideo(aboutUsData?.aboutus_video);
        } else {
          toast.error(res.dat.message, {
            position: "top-right",
          });
        }
      })
      .catch((err: any) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      aboutus_title: "",
      aboutus_description: "",
      aboutus_sub_description: "",
      aboutus_misc: "",
      aboutus_image_1: "",
      aboutus_image_2: "",
      aboutus_video: "",
      count_members: 0,
      count_customers: 0,
      count_orders: 0,
      aboutus_estd: "",
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  type formValues = {
    aboutus_title: string;
    aboutus_description: string;
    aboutus_sub_description: string;
    aboutus_estd: string;
    count_members: number;
    count_customers: number;
    count_orders: number;
    aboutus_misc: string;
    aboutus_image_1: string;
    aboutus_image_2: string;
    aboutus_video: string;
  };

  // Img Upload to s3
  const [imageOneFile, setImageOneFile] = useState<File>();
  const [imageTwoFile, setImageTwoFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();

  const resetValues = () => {
    reset();
    setSpinner(false);
    setaboutUsImage("");
    setaboutUsImageTwo("");
    setaboutUsVideo("");
  };

  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    setSpinner(true);
    data["aboutus_description"] = editorValueOne;
    data["aboutus_sub_description"] = editorValueTwo;
    data.aboutus_id = "1";
    // console.log("data", data);
    if (imageOneFile || imageTwoFile || videoFile) {
      let folderPath = s3FolderType + "/aboutus";
      if (imageOneFile) {
        let filename = "aboutus_image_one_.webp";
        let response = await UploadImagetoS3(
          imageOneFile,
          folderPath,
          filename
        );
        data["aboutus_image_1"] = response["Location"];
      }
      if (imageTwoFile) {
        let filename = "aboutus_image_two_.webp";
        let response = await UploadImagetoS3(
          imageTwoFile,
          folderPath,
          filename
        );
        data["aboutus_image_2"] = response["Location"];
      }
      if (videoFile) {
        let filename = "aboutus_video_.mp4";
        let response = await UploadImagetoS3(videoFile, folderPath, filename);
        data["aboutus_video"] = response["Location"];
      }
    }

    await updateAboutUs(data)
      .then((updateRes: any) => {
        if (updateRes.data.status === 1) {
          resetValues();
          toast.success(updateRes.data.message, {
            position: "top-right",
          });
          setDataCahanged((value) => !value);
        } else {
          resetValues();
          // console.log("error", updateRes.data.message);
          toast.error(updateRes.data.message, {
            position: "top-right",
          });
          setDataCahanged((value) => !value);
        }
      })
      .catch((err) => {
        resetValues();
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                AboutUs Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">Edit AboutUs</h4>
          </div>
          <div>
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline"> Back </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}

        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <Col sm="6">
                  <label htmlFor="inputName" className="col-form-label">
                    About Us Title
                  </label>
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="About Us Title"
                    {...register("aboutus_title")}
                  />
                </Col>
                <Col sm="6">
                  <label htmlFor="estd" className="col-form-label">
                    About Us Estd
                  </label>
                  <input
                    type="text"
                    id="estd"
                    className="form-control"
                    placeholder="About Us Estd"
                    {...register("aboutus_estd")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="4">
                  <label htmlFor="countmembers" className="col-form-label">
                    Count Members
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Count Members"
                    {...register("count_members", {
                      valueAsNumber: true,
                    })}
                  />
                </Col>
                <Col sm="4">
                  <label className="col-form-label">Count Customers</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Count Customers"
                    {...register("count_customers", {
                      valueAsNumber: true,
                    })}
                  />
                </Col>
                <Col sm="4">
                  <label className="col-form-label">Count Orders</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Count Orders"
                    {...register("count_orders", {
                      valueAsNumber: true,
                    })}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="12">
                  <label htmlFor="misc" className="col-form-label">
                    About Us Misc
                  </label>
                  <input
                    type="text"
                    id="misc"
                    className="form-control"
                    placeholder="About Us Misc"
                    {...register("aboutus_misc")}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <label htmlFor="inputName" className="col-form-label">
                  About Us Description
                </label>
                <div>
                  <HtmlEditor
                    editorValue={editorValueOne}
                    setEditorValue={setEditorValueOne}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-form-label">
                  About Us Sub Description
                </label>
                <div>
                  <HtmlEditor
                    editorValue={editorValueTwo}
                    setEditorValue={setEditorValueTwo}
                  />
                </div>
              </Row>

              <Row className="mb-3">
                <Col sm="6">
                  <label htmlFor="aboutusimageone" className="col-form-label">
                    About Us Image-One
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="aboutusimageone"
                    accept="image/*"
                    onChange={async (e) => {
                      let webpFile: any = await convertToWebP(
                        e.target.files[0]
                      );
                      setImageOneFile(webpFile);
                    }}
                  />
                  {aboutUsImage && (
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Current Image</label>
                      <div className="news-item">
                        <div className="news-img">
                          <img
                            src={aboutUsImage}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col sm="6">
                  <label htmlFor="aboutusimagetwo" className="col-form-label">
                    About Us Image-Two
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="aboutusimagetwo"
                    accept="image/*"
                    onChange={async (e) => {
                      let webpFile: any = await convertToWebP(
                        e.target.files[0]
                      );
                      setImageTwoFile(webpFile);
                    }}
                  />
                  {aboutUsImageTwo && (
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Current Image</label>
                      <div className="news-item">
                        <div className="news-img">
                          <img
                            src={aboutUsImageTwo}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="6">
                  <label htmlFor="aboutusVideo" className="col-form-label">
                    About Us Video
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="aboutusVideo"
                    accept=".mp4"
                    onChange={async (e) => {
                      setVideoFile(e.target.files[0]);
                    }}
                  />
                  {aboutUsVideo && (
                    <div className="parentDiv mt-2">
                      <video controls>
                        <source src={aboutUsVideo} type="video/mp4" />
                      </video>
                    </div>
                  )}
                </Col>
              </Row>

              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}

              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
