import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllVendingMachines() {
  return await axios.get(apiUrl + "/vending_machine");
}

export async function addVendingMachine(data) {
  return await JwtInterceoptor.put(apiUrl + "/vending_machine/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getVendingMachineById(id) {
  return await axios.get(apiUrl + "/vending_machine/" + id);
}

export async function updateVendingMachine(data) {
  return await JwtInterceoptor.patch(apiUrl + "/vending_machine/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteVendingMachineById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/vending_machine/", {
    data: {
      vm_id: id,
    },
  });
}

export async function updateVendingMachineImage(id, image) {
  const data = { vm_id: id, vm_images: image };
  // console.log("data in service",data);
  return await JwtInterceoptor.patch(apiUrl + "/vending_machine/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getVmsByStoreId(id) {
  return await axios.get(apiUrl + "/vending_machine/store/" + id);
}

export async function getProductsByVmId(id) {
  return await axios.get(apiUrl + "/vending_machine/products/" + id);
}
