import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";

import Header from "../layouts/Header";
// import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import {
  addStore,
  updateStore,
  getStoreById,
  updateStoreImage,
} from "../api-services/Inventory/StoreApi.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function StoreForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);

  // const [storehandler, setSelectedStorehandler] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getStoreDataById(id);
    }
  }, []);

  // get store by id api //
  const getStoreDataById = async (id) => {
    // =========== Get Store By Id ============ //
    await getStoreById(id)
      .then((res) => {
        let data = res.data;
        let store = data.store;
        //
        setValue("store_name", store.store_name);
        setValue("store_description", store.store_description);
        setValue("store_handler", store.store_handler);
        // setValue("store_by", store.store_by);
        // setValue("store_updated_by", store.store_updated_by);
        // setSelectedStorehandler(store.store_handler);
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      store_name: "",
      store_description: "",
      store_handler: "",
      // store_by: "",
      // store_updated_by: "",
      // store_orders: [],
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Interface
  type formValues = {
    store_name: String;
    store_description: String;
    store_handler: String;
    // store_by: String;
    // store_updated_by: String;
    // store_orders: string[];
  };

  // Form error handling
  const { errors } = formState;

  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  const onSubmit = async (data: any) => {
    if (isAddMode) {
      setSpinner(true);
      // console.log("data", data);
      await addStore(data)
        .then((res: any) => {
          // console.log("add response", res);
          if (res.data.status === 1) {
            resetValues();
            toast.success(res.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            // console.log("error msg", res.data.message);
          }
        })
        .catch((err: any) => {
          toast.error(err.message, {
            position: "top-right",
          });
          // console.log("error meg", err);
        });
      //=========== Update Functionality =============//
    } else {
      setSpinner(true);
      data.store_id = id;
      // console.log("Updated data", data);
      await updateStore(data)
        .then((res: any) => {
          // console.log("update response", res);
          if (res.data.status === 1) {
            resetValues();
            toast.success(res.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            // console.log("error msg", res.data.message);
          }
        })
        .catch((err: any) => {
          toast.error(err.message, {
            position: "top-right",
          });
          // console.log("error meg", err);
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory-dashboard">Inventory Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/inventory-dashboard/store">Store Management</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Store" : "Edit Store"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Store" : "Edit Store"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Back to Dashboard</span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              {isAddMode && (
                <div className="">
                  <label
                    htmlFor="inputName"
                    className="col-form-label text-dark"
                  >
                    Store Name
                  </label>
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Store Name"
                    {...register("store_name")}
                  />
                </div>
              )}
              {!isAddMode && (
                <Row className="mb-3">
                  <Col sm="6">
                    <div className="mt-3">
                      <label
                        htmlFor="inputName"
                        className="col-form-label text-dark"
                      >
                        Store Name
                      </label>
                      <input
                        type="text"
                        id="inputName"
                        className="form-control"
                        placeholder="Store Name"
                        {...register("store_name")}
                      />
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="mt-3">
                      <label
                        htmlFor="storehandler"
                        className="col-form-label text-dark"
                      >
                        Store Handler
                      </label>
                      <div>
                        <input
                          type="text"
                          id="storehandler"
                          className="form-control"
                          placeholder="Store Handler"
                          {...register("store_handler")}
                        />
                        {/* <select
                          {...register("store_handler")}
                          className="form-control"
                          id="storehandler"
                          value={storehandler}
                          onChange={(e) =>
                            setSelectedStorehandler(e.target.value)
                          }
                        >
                          <option value="">Select...</option>
                          <option value="Admin">Admin</option>
                          <option value="Super-Admin">Super-Admin</option>
                        </select> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <div className="mb-3">
                <label htmlFor="Textarea" className="col-form-label">
                  Store Description
                </label>
                <textarea
                  className="form-control"
                  id="Textarea"
                  placeholder="Enter Description here..."
                  rows={3}
                  {...register("store_description")}
                ></textarea>
              </div>
              {/* {!isAddMode && (
                <>
                  <div className="row mb-3 ">
                    <div className="col mt-2">
                      <p>Store Delivery Agents</p>
                    </div>
                    <div className="col-10 mt-2">
                      <SelectDropDown
                        finalArray={finalAgentsArray}
                        defaultArray={defaultAgentsArray}
                        setDefaultArray={setDefaultAgentsArray}
                      />
                    </div>
                  </div>
                </>
              )} */}
              {spinner && (
                <div className="d-flex justify-content-center mt-2">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
// function SelectDropDown({ finalArray, defaultArray, setDefaultArray }) {
//   return (
//     <Select
//       name="select"
//       options={finalArray}
//       labelField="label"
//       valueField="value"
//       multi
//       onChange={(value) => setDefaultArray(value)}
//       values={defaultArray}
//       clearable
//       color={"#22c595"}
//       style={{ padding: 10 }}
//     />
//   );
// }
