const pagesMenu = [
  {
    label: "User Pages",
    icon: "ri-account-circle-line",
    submenu: [
      {
        label: "User Profile",
        link: "/pages/profile",
      },
      {
        label: "People & Groups",
        link: "/pages/people",
      },
      {
        label: "Activity Log",
        link: "/pages/activity",
      },
      {
        label: "Events",
        link: "/pages/events",
      },
      {
        label: "Settings",
        link: "/pages/settings",
      },
    ],
  },
];

const inventoryManagementMenu = [
  {
    label: "Inventory Dashboard",
    icon: "ri-shopping-bag-3-fill",
    link: "/inventory-dashboard",
    id: "inventory",
    submenu: [
      {
        label: "Store Management",
        link: "/inventory-dashboard/store",
      },
    ],
  },
];

const websiteMenu = [
  {
    label: "Website Dashboard",
    icon: "ri-computer-fill",
    link: "/website-dashboard",
    id: "website",
    submenu: [
      {
        label: "Home Slider",
        link: "/website-dashboard/homeslider",
      },
      {
        label: "Offer Slider",
        link: "/website-dashboard/offerslider",
      },
      {
        label: "Social Media",
        link: "/website-dashboard/socialmedia",
      },
      {
        label: "Footer",
        link: "/website-dashboard/footer",
      },
      {
        label: "Contacts",
        link: "/website-dashboard/contacts",
      },
      {
        label: "Services",
        link: "/website-dashboard/services",
      },
      {
        label: "Enquiries",
        link: "/website-dashboard/enquiries",
      },
      {
        label: "Subscribers",
        link: "/website-dashboard/subscribers",
      },
      {
        label: "News Letters",
        link: "/website-dashboard/newsletters",
      },
      {
        label: "Faq",
        link: "/website-dashboard/faqs",
      },
      {
        label: "AboutUs",
        link: "/website-dashboard/aboutusform",
      },
    ],
  },
];

const uiElementsMenu = [
  {
    label: "Getting Started",
    icon: "ri-pencil-ruler-2-line",
    submenu: [
      {
        label: "Grid System",
        link: "/docs/layout/grid",
      },
      {
        label: "Columns",
        link: "/docs/layout/columns",
      },
      {
        label: "Gutters",
        link: "/docs/layout/gutters",
      },
    ],
  },
];

const productsMenu = [
  {
    label: "Product Dashboard",
    icon: "ri-suitcase-2-fill",
    link: "/product-dashboard",
    id: "products",
    submenu: [
      {
        label: "Brand Monitoring",
        link: "/product-dashboard/Brand",
        // icon: "ri-suitcase-2-line",
      },
      {
        label: "Category Monitoring",
        link: "/product-dashboard/Category",
        // icon: "ri-suitcase-2-line",
      },
      {
        label: "Sub Category Monitoring",
        link: "/product-dashboard/Subcategory",
        // icon: "ri-suitcase-2-line",
      },
      {
        label: "Product Monitoring",
        link: "/product-dashboard/product",
        // icon: "ri-suitcase-2-line",
      },
    ],
  },
];

export {
  pagesMenu,
  uiElementsMenu,
  inventoryManagementMenu,
  websiteMenu,
  productsMenu,
};
