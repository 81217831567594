import React from "react";

// Pages
// import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
// import Profile from "../pages/Profile";
// import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";

// UI Elements
import Avatars from "../docs/Avatars";

// Product Related //
import BrandManagement from "../dashboard/BrandManagement";
import CategoryManagement from "../dashboard/CategoryManagement";
import SubCategoryManagement from "../dashboard/SubCategoryManagement";
import ProductManagement from "../dashboard/ProductManagement";

// Website Related //
import SliderOneManagement from "../dashboard/websiteManagement/SliderOneManagement";
import SliderTwoManagement from "../dashboard/websiteManagement/SliderTwoManagement.";
import SocialMediaManagement from "../dashboard/websiteManagement/SocialMediaManagement";
import FooterManagement from "../dashboard/websiteManagement/FooterManagement";
import ContactsManagement from "../dashboard/websiteManagement/ContactsManagement";
import ServicesManagement from "../dashboard/websiteManagement/ServiceManagement";
import EnquiryManagement from "../dashboard/websiteManagement/EnquiryManagement";
import SubscribersManagement from "../dashboard/websiteManagement/SubscribersManagement";
import NewsLettersManagement from "../dashboard/websiteManagement/NewsLettersManagement";

// Inventory Related //
import StoresManagement from "../dashboard/InventoryManagement/StoresManagement";
// import ShopManagement from "../dashboard/InventoryManagement/ShopManagement";
// import VendingMachineManagement from "../dashboard/InventoryManagement/VendingMachineManagement";

// Forms //
import BrandForm from "../forms/BrandForm";
import CategoryForm from "../forms/CategoryForm";
import Subcategoryform from "../forms/SubCategoryForm";
import SliderOneForm from "../forms/SliderOneForm";
import SliderTwoForm from "../forms/SliderTwoForm";
import ProductForm from "../forms/ProductForm";
import SocialMediaForm from "../forms/SocialMediaForm";
import FooterForm from "../forms/FooterForm.tsx";
import NewsLetterForm from "../forms/NewsLetterForm";
import StoreForm from "../forms/StoreForm";
import VendingMachineForm from "../forms/VendingMachineForm";
import PackageForm from "../forms/PackageForm";
import ShopForm from "../forms/ShopForm";
import FaqForm from "../forms/FaqForm";
import AboutUsForm from "../forms/AboutUsForm";

// DEtail Pages //
import Commondetailpage from "../dashboard/CommonDetailPage";
import Productdetailpage from "../dashboard/ProductDetailPage";
import StoreProductManagement from "../dashboard/InventoryManagement/StoreProductManagement";
import VendingMachineProductPage from "../dashboard/InventoryManagement/VendingMachineProductPage";
import PackageDetailPage from "../dashboard/InventoryManagement/PackageDetailPage";
import ShopDetailPage from "../dashboard/InventoryManagement/ShopDetailPage";
// Vault
import VaultManagement from "../dashboard/InventoryManagement/VaultManagement";

import LandingPage from "../pages/LandingPage";

// User Related //
import ProfileDetailPage from "../dashboard/UserManagement/ProfileDetailPage";
import ProfileForm from "../forms/ProfileForm";

import ProductDashboard from "../dashboard/ProductDashboard";
import InventoryDashboard from "../dashboard/InventoryManagement/InventoryDashboard";
import WebsiteDashboard from "../dashboard/websiteManagement/WebsiteDashboard";
import FaqsManagement from "../dashboard/websiteManagement/FaqsManagement.js";

const protectedRoutes = [
  // ================ Inventory Management Related ====================== //
  { path: "/", element: <LandingPage />, accessBy: "non-authenticated" },
  { path: "/dashboard", element: <LandingPage />, accessBy: "authenticated" },
  {
    path: "/product-dashboard",
    element: <ProductDashboard />,
    accessBy: "authenticated",
  },
  {
    path: "/inventory-dashboard",
    element: <InventoryDashboard />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard",
    element: <WebsiteDashboard />,
    accessBy: "authenticated",
  },

  {
    path: "inventory-dashboard/store",
    element: <StoresManagement />,
    accessBy: "authenticated",
  },

  // ================  Products Related ====================== //
  {
    path: "product-dashboard/Brand",
    element: <BrandManagement />,
    accessBy: "authenticated",
  },
  {
    path: "product-dashboard/Category",
    element: <CategoryManagement />,
    accessBy: "authenticated",
  },
  {
    path: "product-dashboard/Subcategory",
    element: <SubCategoryManagement />,
    accessBy: "authenticated",
  },
  {
    path: "product-dashboard/product",
    element: <ProductManagement />,
    accessBy: "authenticated",
  },

  // ================ website Related ====================== //
  {
    path: "website-dashboard/homeslider",
    element: <SliderOneManagement />,
    accessBy: "authenticated",
  },
  {
    path: "website-dashboard/offerslider",
    element: <SliderTwoManagement />,
    accessBy: "authenticated",
  },
  {
    path: "website-dashboard/socialmedia",
    element: <SocialMediaManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/footer",
    element: <FooterManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/contacts",
    element: <ContactsManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/services",
    element: <ServicesManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/enquiries",
    element: <EnquiryManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/subscribers",
    element: <SubscribersManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/newsletters",
    element: <NewsLettersManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/faqs",
    element: <FaqsManagement />,
    accessBy: "authenticated",
  },
  {
    path: "/website-dashboard/aboutusform",
    element: <AboutUsForm />,
    accessBy: "authenticated",
  },

  // pages
  // { path: "pages/pricing", element: <Pricing /> , accessBy:"authenticated" },
  { path: "pages/faq", element: <Faq />, accessBy: "authenticated" },
  // { path: "pages/profile", element: <Profile /> , accessBy:"authenticated" },
  // { path: "pages/people", element: <People /> , accessBy:"authenticated" },
  { path: "pages/activity", element: <Activity />, accessBy: "authenticated" },
  { path: "pages/events", element: <Events />, accessBy: "authenticated" },
  { path: "pages/settings", element: <Settings />, accessBy: "authenticated" },
  // docs
  { path: "docs/com/avatars", element: <Avatars />, accessBy: "authenticated" },

  // New Routes //
  { path: "brandform", element: <BrandForm />, accessBy: "authenticated" },
  { path: "brandform/:id", element: <BrandForm />, accessBy: "authenticated" },
  {
    path: "categoryform",
    element: <CategoryForm />,
    accessBy: "authenticated",
  },
  ,
  {
    path: "categoryform/:id",
    element: <CategoryForm />,
    accessBy: "authenticated",
  },
  ,
  {
    path: "subcategoryform",
    element: <Subcategoryform />,
    accessBy: "authenticated",
  },
  ,
  {
    path: "subcategoryform/:id",
    element: <Subcategoryform />,
    accessBy: "authenticated",
  },
  ,
  { path: "productform", element: <ProductForm />, accessBy: "authenticated" },
  {
    path: "productform/:id",
    element: <ProductForm />,
    accessBy: "authenticated",
  },
  //
  {
    path: "homeSliderform",
    element: <SliderOneForm />,
    accessBy: "authenticated",
  },
  ,
  {
    path: "homeSliderform/:id",
    element: <SliderOneForm />,
    accessBy: "authenticated",
  },
  {
    path: "offerSliderform",
    element: <SliderTwoForm />,
    accessBy: "authenticated",
  },
  {
    path: "offerSliderform/:id",
    element: <SliderTwoForm />,
    accessBy: "authenticated",
  },
  {
    path: "socialmediaform",
    element: <SocialMediaForm />,
    accessBy: "authenticated",
  },
  {
    path: "socialmediaform/:id",
    element: <SocialMediaForm />,
    accessBy: "authenticated",
  },
  { path: "footerform", element: <FooterForm />, accessBy: "authenticated" },
  {
    path: "newsletterform",
    element: <NewsLetterForm />,
    accessBy: "authenticated",
  },
  {
    path: "newsletterform/:id",
    element: <NewsLetterForm />,
    accessBy: "authenticated",
  },
  {
    path: "faqform",
    element: <FaqForm />,
    accessBy: "authenticated",
  },
  ,
  {
    path: "faqform/:id",
    element: <FaqForm />,
    accessBy: "authenticated",
  },
  //
  { path: "storeform", element: <StoreForm />, accessBy: "authenticated" },
  { path: "storeform/:id", element: <StoreForm />, accessBy: "authenticated" },
  { path: "packageform", element: <PackageForm />, accessBy: "authenticated" },
  {
    path: "packageform/:id",
    element: <PackageForm />,
    accessBy: "authenticated",
  },
  { path: "shopform", element: <ShopForm />, accessBy: "authenticated" },
  { path: "shopform/:id", element: <ShopForm />, accessBy: "authenticated" },
  {
    path: "vendingMachineForm",
    element: <VendingMachineForm />,
    accessBy: "authenticated",
  },
  {
    path: "vendingMachineForm/:id",
    element: <VendingMachineForm />,
    accessBy: "authenticated",
  },
  // Detail Pages //
  {
    path: "commondetailpage/:id",
    element: <Commondetailpage />,
    accessBy: "authenticated",
  },
  {
    path: "productdetailpage/:id",
    element: <Productdetailpage />,
    accessBy: "authenticated",
  },
  {
    path: "store/:id",
    element: <StoreProductManagement />,
    accessBy: "authenticated",
  }, // ** STORE DETAIL PAGE ** //
  {
    path: "/inventory-dashboard/package/:id",
    element: <PackageDetailPage />,
    accessBy: "authenticated",
  },
  {
    path: "shopDetailPage/:id",
    element: <ShopDetailPage />,
    accessBy: "authenticated",
  },
  {
    path: "vendingMachineProduct/:id",
    element: <VendingMachineProductPage />,
    accessBy: "authenticated",
  }, // ** VM DETAIL PAGE ** //

  {
    path: "vaultManagement/:id",
    element: <VaultManagement />,
    accessBy: "authenticated",
  },

  // User Related //
  {
    path: "/ProfileDetailPage",
    element: <ProfileDetailPage />,
    accessBy: "authenticated",
  },
  { path: "/Profileform", element: <ProfileForm />, accessBy: "authenticated" },
  {
    path: "/Profileform/:id",
    element: <ProfileForm />,
    accessBy: "authenticated",
  },
];

export default protectedRoutes;
