import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { ListGroup, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { getFooter } from "../../api-services/FooterAPi";

export default function FooterManagement() {
  const { userRole } = useContext(AuthContext);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const navigate = useNavigate();

  // Get Api Call
  useEffect(() => {
    getFooterData();
  }, []);

  const [footerData, setFooterData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getFooterData = async () => {
    setLoading(true);
    await getFooter()
      .then((res) => {
        // console.log("footer", res.data.footer);
        setFooterData(res.data.footer);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("footer", footerData);
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Footer Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Footer Management
            </h4>
          </div>
          <div>
            <Button
              disabled={userRole === 5}
              variant="primary"
              className="d-flex align-items-center gap-2"
              size="md"
              onClick={() => {
                navigate("/footerform");
              }}
            >
              <i className="ri-edit-box-line fs-18 lh-1"></i>Edit
              <span className="d-none d-sm-inline">Footer</span>
            </Button>
          </div>
        </div>

        <Card className="card-one mb-5">
          <Card.Body>
            <Row className="p-3">
              <Col xl="4" className="text-center">
                <h5 className="main-subtitle text-center">Footer Icon</h5>
                <img
                  src={footerData?.footer_icon}
                  alt=""
                  className="img-fluid"
                />
              </Col>
              <Col xl="8">
                <h5 className="main-subtitle">Footer Description</h5>
                <p>{footerData?.footer_description}</p>
                <h5 className="main-subtitle">Footer Desclaimer</h5>
                <p>{footerData?.footer_disclaimer}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className="g-3">
          <Col sm="12" xl="6" lg="6" md="6">
            <h5 className="main-subtitle">Payment Links</h5>
            <div className="card">
              <div className="card-body">
                {footerData?.footer_payment_links?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="row ps-3 pe-3">
                        <div className="col-lg-6 text-center">
                          <label className="text-center mb-1">Image</label>
                          <img className="img-fluid" src={item.image_url} />
                        </div>
                        <div className="col-lg-6 text-center">
                          <label className="text-center">Title</label>
                          <h6 className="fw-semibold text-dark mt-3">
                            {item.title}
                          </h6>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="6" sm="12">
            <h5 className="main-subtitle">Quick Links</h5>
            <ListGroup>
              {footerData?.footer_quick_links?.map((item, index) => {
                return (
                  <div key={index}>
                    <ListGroup.Item>
                      <div className="news-item">
                        <div className="news-body">
                          <h3 className="d-block mb-1 fs-sm text-dark">
                            {item.title}
                          </h3>
                          <p className="news-title fw-semibold">
                            <a href="" target="_blank" className="text-primary">
                              {item.image_url}
                            </a>
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </div>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
