import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getActiveSliders() {
  return await axios.get(apiUrl + "/slider_two/filter/1");
}

export async function getInactiveSliders() {
  return await axios.get(apiUrl + "/slider_two/filter/0");
}

export async function addSlider(data) {
  return await JwtInterceoptor.put(apiUrl + "/slider_two", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getAllSliders() {
  return await axios.get(apiUrl + "/slider_two");
}

export async function updateSlider(data) {
  return await JwtInterceoptor.patch(apiUrl + "/slider_two", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteSliderById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/slider_two", {
    data: {
      slider_two_id: id,
    },
  });
}

export async function updateSliderState(id, state) {
  const data = { slider_two_id: id, slider_two_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/slider_two", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateSliderImage(id, image) {
  const data = { slider_two_id: id, slider_two_image: image };
  return await JwtInterceoptor.patch(apiUrl + "/slider_two", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getSliderById(id) {
  return await axios.get(apiUrl + "/slider_two/" + id);
}
