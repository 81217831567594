import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import {
  getAllSubscribers,
  updateSubscriber,
  deleteSubscriber,
  updateSubscriberState,
} from "../../api-services/SubscribersApi";
import ServiceUnavailable from "../../pages/ServiceUnavailable";
import SkeletonLoading from "../../pages/SkeletonLoading";
import Forbidden from "../../pages/Forbidden";

export default function SubscribersManagement() {
  const { userRole } = useContext(AuthContext);
  // subscribers Array Variable
  const [allSubscribers, setAllSubscribers] = useState([]);
  const [allSubscribersTwo, setAllSubscribersTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // move to normal/featured related
  const [moveSubscriberById, setMoveSubscriberId] = useState("");

  // delete popup //
  const [deleteSubscriberId, setDeleteSubscriberId] = useState("");

  const removeSubscriberByID = async () => {
    await deleteSubscriber(deleteSubscriberId)
      .then((res) => {
        // console.log("response", res);
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllSubscribersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    if (userRole === 7 || userRole === 6) {
      getAllSubscribersData();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllSubscribersData = async () => {
    setLoading(true);
    await getAllSubscribers()
      .then((res) => {
        setAllSubscribers(res.data.subscribers);
        setAllSubscribersTwo(res.data.subscribers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("subscribers", allSubscribers);
  };

  // Get Api Call

  const updateSubscriberStateByID = async (state) => {
    await updateSubscriberState(moveSubscriberById, state)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllSubscribersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const searchData = (val) => {
    setQ(val);
    if (val === "") {
      setAllSubscribers(allSubscribersTwo);
      return;
    }
    const query = val.toLowerCase();
    const allData = allSubscribers.filter(
      (item) => item.subscriber_email.toLowerCase().indexOf(query) > -1
    );
    setAllSubscribers(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setAllSubscribers(allSubscribersTwo);
    }
  };

  // response object
  const [modifysubscriber, setModifySubscriber] = useState({});

  // Form Configuration
  const form = useForm({
    defaultValues: {
      subscriber_email: "",
    },
  });

  const { register, handleSubmit, reset, setValue } = form;

  const onSubmit = async (data) => {
    data.subscriber_id = modifysubscriber.subscriber_id;
    await updateSubscriber(data)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          resetValues();
          getAllSubscribersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const resetValues = () => {
    reset();
  };

  if (userRole === 5) {
    return (
      <>
        <div className="main main-app p-3 p-lg-4">
          <Forbidden linkUrl={"/website-dashboard"} />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Subscribers Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Subscribers Management
            </h4>
          </div>
          <div></div>
        </div>
        {/* Search Bar & Add Brand Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>
              <div></div>
            </div>
          </Card.Body>
        </Card>

        {/* Subscribers List Started */}
        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && allSubscribers.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Subscribers Data Currently</h5>
            </div>
          )}
          {!loading && !isError && allSubscribers.length > 0 && (
            <Col xs="12 mt-5">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>SI.no</th>
                        <th>Email</th>
                        <th>State</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSubscribers &&
                        allSubscribers.map((item, index) => {
                          return (
                            <tr
                              key={item.subscriber_id}
                              className="text-center"
                            >
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {index + 1}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.subscriber_email}
                                </span>
                              </td>
                              <td>
                                <span className="ff-numerals">
                                  {item.subscriber_state === 0 ? (
                                    <Badge bg="secondary p-2" pill>
                                      in Active
                                    </Badge>
                                  ) : (
                                    <Badge bg="success p-2" pill>
                                      Active
                                    </Badge>
                                  )}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Button
                                        variant="primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => {
                                          setModifySubscriber(item);
                                          setValue(
                                            "subscriber_email",
                                            item.subscriber_email
                                          );
                                        }}
                                      >
                                        Edit Subscriber
                                      </Button>
                                      {item.subscriber_state === 0 ? (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#featuredPopup"
                                          onClick={() => {
                                            setMoveSubscriberId(
                                              item.subscriber_id
                                            );
                                          }}
                                        >
                                          Make Active
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#NormalPopup"
                                          onClick={() => {
                                            setMoveSubscriberId(
                                              item.subscriber_id
                                            );
                                          }}
                                        >
                                          Make In-Active
                                        </Button>
                                      )}
                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteSubscriberId(
                                            item.subscriber_id
                                          );
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeSubscriberByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}

      {/* Make active  */}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Make it Active?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateSubscriberStateByID(1)}
              >
                Make Active
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make active  */}

      {/* Make in-Active  */}
      <div
        className="modal fade"
        id="NormalPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="NormalPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="NormalPopupLabel">
                Are You want to Make it In-Active?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateSubscriberStateByID(0)}
              >
                Make In-Active
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make in-active */}

      {/* update Popup*/}
      <div
        className="modal fade"
        id="exampleModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Email
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form noValidate>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="main-subtitle">
                    Email address :
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    {...register("subscriber_email")}
                    aria-describedby="emailHelp"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
              >
                update
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
