import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Badge, Button, Card } from "react-bootstrap";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { deleteImageFromS3 } from "../api-services/s3-service";
import Sidebar from "../layouts/Sidebar";
import { getProductById, deleteProductById } from "../api-services/ProductApi";
import { getAllBrands } from "../api-services/BrandApi";
import { getAllSubcategories } from "../api-services/SubCategoryApi";
import { getAllStores } from "../api-services/Inventory/StoreApi";
import AuthContext from "../auth/AuthContext";

export default function Productdetailpage() {
  const { userRole } = useContext(AuthContext);
  const { id } = useParams();

  const [dataFetched, setDataFetched] = useState(false);

  const [productData, setProductData] = useState({});
  const [allBrands, setAllBrands] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allStores, setAllStores] = useState([]);

  const [connectedStores, setConnectedStores] = useState([]);
  const [connectedVms, setConnectedVms] = useState([]);

  useEffect(() => {
    getProductDataById(id);
  }, []);

  const getProductDataById = async (id) => {
    setProductData({});
    setDataFetched(false);
    await getProductById(id)
      .then((res) => {
        // console.log("product data res", res);
        if (res.data.status == 1) {
          let data = res.data;
          let product = data.product;
          setProductData(product);
          // ========= vm data =========== //
          setVmData(data.product.product_stores);
          // ========= Shop data =========== //
          setStoresData(data.product.product_stores);
          //
          setDataFetched(true);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // delete popup //
  // const [deleteProductId, setDeleteProductId] = useState("");
  // const [deleteProduct, setDeleteProduct] = useState({});

  const navigate = useNavigate();
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  useEffect(() => {
    getAllBrandsData();
    getAllCategoriesData();
    getAllStoresData();
  }, []);

  const getAllBrandsData = async () => {
    await getAllBrands()
      .then((res) => {
        if (res.data.status === 1) {
          setAllBrands(res.data.brands);
        } else {
          console.log("Error", res.data.message);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const getAllCategoriesData = async () => {
    await getAllSubcategories()
      .then((res) => {
        if (res.data.status === 1) {
          setAllCategories(res.data.sub_categories);
        } else {
          console.log("Error", res.data.message);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const getAllStoresData = async () => {
    await getAllStores()
      .then((res) => {
        if (res.data.status === 1) {
          setAllStores(res.data.stores);
        } else {
          console.log("Error", res.data.message);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const removeProductByID = async () => {
    await deleteProductById(id)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          for (let i = 0; i < productData?.product_images.length; i++) {
            let imgUrl = productData.product_images[i];
            let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
            await deleteImageFromS3(imgname);
            console.log("image ======= ", i, " ======== deleted");
          }
          navigate("/product-dashboard/product");
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  //
  const setStoresData = (stores) => {
    setConnectedStores([]);
    stores.forEach((store) => {
      store.store_shops.forEach((shop) => {
        // console.log("shop", shop);
        setConnectedStores((connectedStores) => [...connectedStores, shop]);
      });
    });
  };

  const setVmData = (stores) => {
    setConnectedVms([]);
    stores.forEach((store) => {
      store.store_vending_machines.forEach((vending_machine) => {
        // console.log("VM", vending_machine);
        setConnectedVms((connectedVms) => [...connectedVms, vending_machine]);
      });
    });
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      {!dataFetched ? (
        <div className="main main-app p-5 p-lg-4">
          <div className="parentContainer">
            <div className="childContainer">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/product-dashboard">Product Dashboard</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/product-dashboard/product`}>
                    Product Management
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Product Detail Page
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              {(userRole === 7 || userRole === 6) && (
                <>
                  {productData && (
                    <>
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate(`/productform/${id}`, {
                            state: {
                              name: productData?.product_title,
                              id: id,
                            },
                          });
                        }}
                      >
                        Edit Product
                      </Button>
                      <button
                        className="btn btn-info text-light"
                        // onClick={() => {
                        //   setDeleteProductId(id);
                        // }}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#delPopup"
                      >
                        Delete Product
                      </button>
                    </>
                  )}
                </>
              )}

              <Button
                style={{
                  backgroundColor: "#dae0e6bf",
                  color: "black",
                  borderColor: "#dae0e6bf",
                }}
                className="d-flex align-items-center gap-2"
                onClick={() => navigate(-1)}
              >
                <i className="ri-arrow-left-fill fs-18 lh-1"></i>
                <span className=" d-sm-inline"> Back </span>
              </Button>
            </div>
          </div>

          <Row className="g-5">
            <Col xl>
              <div className="card">
                <div className="card-body">
                  <div className="media-profile mb-3">
                    <div className="media-img mb-3 mb-sm-0">
                      <img
                        src={productData.product_images[0]}
                        className="img-fluid"
                        alt={productData.product_title}
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="media-name mb-4 mt-2">
                        {productData.product_title}
                      </h5>
                      <p className="mt-2">{productData.product_description}</p>
                      {/* <Button size="sm"> Connected VENDING MACHINES</Button>
                      <Button size="sm" className="ms-2"> Connected STORES</Button> */}
                    </div>
                  </div>

                  <Row className="row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5">
                    <Col>
                      <div className="profile-item">
                        <i className="ri-suitcase-line"></i>
                        <div className="profile-item-body">
                          <p>
                            {allBrands.find(
                              (el) => el.brand_id === productData.product_brand
                            )?.brand_title || ""}
                          </p>
                          <span>Brand</span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="profile-item">
                        <i className="ri-suitcase-line"></i>
                        <div className="profile-item-body">
                          <p>
                            {allCategories.find(
                              (el) =>
                                el.sub_category_id ===
                                productData.product_sub_category
                            )?.sub_category_title || ""}
                          </p>
                          <span>Sub Category</span>
                        </div>
                      </div>
                    </Col>
                    {/* <Col>
                      <div className="profile-item">
                        <i className="ri-suitcase-line"></i>
                        <div className="profile-item-body">
                          <p>
                            {productData.product_state === 0
                              ? "Normal"
                              : "Featured"}
                          </p>
                          <span>State</span>
                        </div>
                      </div>
                    </Col> */}
                    <Col>
                      <div className="profile-item">
                        <i className="ri-team-line"></i>
                        <div className="profile-item-body">
                          <p>{productData.product_views || 0}</p>
                          <span>Views</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          {/* Connected Stores and vending machines List */}
          <Row className="g-3 justify-content-center">
            <Col sm="6">
              <Card className="card-one" style={{ padding: 15 }}>
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {connectedVms?.length || 0}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Connected VENDING MACHINES
                      </label>
                    </Col>
                    <Col xs="5" className="displayCenter">
                      <Button
                        data-bs-toggle="modal"
                        data-bs-target="#viewVemdingMachines"
                        size="xl"
                        onClick={() => {
                          // setConnectedVms(productData.product_vending_machines);
                          // setVmData(productData.product_stores);
                        }}
                      >
                        {" "}
                        View Vending Machines
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="card-one" style={{ padding: 15 }}>
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {connectedStores?.length}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Connected Shops
                      </label>
                    </Col>
                    <Col xs="5" className="displayCenter">
                      <Button
                        size="xl"
                        data-bs-toggle="modal"
                        data-bs-target="#viewStore"
                        onClick={() => {
                          // setStoresData(productData.product_stores);
                          // setConnectedStores(productData.product_stores);
                        }}
                      >
                        {" "}
                        View Shops
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Details */}
          <div className="card mt-3">
            <div className="card-body">
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Description :
                </Col>
                <Col>{productData.product_description}</Col>
              </Row>
              <hr />

              <Row className="mt-2 p-2">
                <Col xs="4" className="text-end text-primary">
                  Views :
                </Col>
                <Col>{productData.product_views}</Col>
              </Row>
              <hr />
              <Row className="mt-2 p-2">
                <Col xs="4" className="text-end text-primary">
                  Sub Category Connected :
                </Col>
                <Col>
                  <Badge
                    bg="success mb-2"
                    style={{ borderRadius: 10, cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/commondetailpage/${productData.product_sub_category}`
                      );
                    }}
                  >
                    <span className="p-2 fs-6">
                      {allCategories.find(
                        (el) =>
                          el.sub_category_id ===
                          productData.product_sub_category
                      )?.sub_category_title || ""}
                    </span>
                  </Badge>
                </Col>
              </Row>
              <hr />
              <Row className="mt-2 p-2">
                <Col xs="4" className="text-end text-primary">
                  Brand Connected :
                </Col>
                <Col>
                  <Badge
                    bg="success mb-2"
                    style={{ borderRadius: 10, cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/commondetailpage/${productData.product_brand}`
                      );
                    }}
                  >
                    <span className="p-2 fs-6">
                      {allBrands.find(
                        (el) => el.brand_id === productData.product_brand
                      )?.brand_title || ""}
                    </span>
                  </Badge>
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Type :
                </Col>
                <Col>
                  {productData.product_type == 1 ? "Fresh" : "Non-fresh"}
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Items Per Package :
                </Col>
                <Col>{productData.product_items_per_package || 0}</Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Weight :
                </Col>
                <Col>{productData.product_weight || 0}</Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Scale (Weight) :
                </Col>
                <Col>
                  {productData.product_scale == 0 && "grams"}
                  {productData.product_scale == 1 && (
                    <>{productData.product_weight > 1 ? "Kgs" : "Kg"}</>
                  )}
                  {productData.product_scale == 2 && (
                    <>{productData.product_weight > 1 ? "lbs" : "lb"}</>
                  )}
                  {productData.product_scale == 3 && (
                    <>{productData.product_weight > 1 ? "liters" : "liter"}</>
                  )}
                  {productData.product_scale == 4 && "mls"}
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Actual Price :
                </Col>
                <Col>{productData.product_actual_price || 0}</Col>
              </Row>
              <hr />
              {/* <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Quantity :
                </Col>
                <Col>{productData.product_quantity || 0}</Col>
              </Row>
              <hr /> */}
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Packing Type :
                </Col>
                <Col>
                  {productData.product_packing_type == 0 && "Paper"}
                  {productData.product_packing_type == 1 && "Plastic"}
                  {productData.product_packing_type == 2 && "Glass"}
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  {productData?.product_stores?.length > 1
                    ? "Product Stores"
                    : "Product Store"}{" "}
                  :
                </Col>
                <Col>
                  {productData?.product_stores?.length > 0 ? (
                    productData.product_stores.map((item, index) => {
                      return (
                        <div
                          className="d-sm-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <Badge bg="success mb-2" style={{ borderRadius: 10 }}>
                            <span className="p-2 fs-6">
                              {allStores.find(
                                (el) => el.store_id === item.store_id
                              )?.store_name || ""}
                            </span>
                          </Badge>
                        </div>
                      );
                    })
                  ) : (
                    <p>No Store Connected</p>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="p-2">
                <Col xs="4" className="text-end text-primary">
                  Product Add ons :
                </Col>
                <Col>{productData.product_add_ons || ""}</Col>
              </Row>
            </div>
          </div>
        </div>
      )}

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeProductByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* View Connected Stores List Popup */}
      <div
        className="modal fade"
        id="viewStore"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewStoreLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewStoreLabel">
                Connected Shops List
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Row className="g-3">
                {/* // stores // */}
                {connectedStores?.length > 0 ? (
                  connectedStores.map((shop, index) => {
                    return (
                      <Col sm="3" key={index}>
                        {/* // shops // */}
                        {
                          <Card className="card-one">
                            <Card.Body className="p-3">
                              <div className="news-item displayCenter">
                                <div className="news-img  mb-2">
                                  <img
                                    src={shop.shop_images[0]}
                                    alt={shop.shop_name}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <h6 className="fw-semibold text-success lh-4">
                                {shop.shop_name}
                              </h6>
                              <div className="d-grid">
                                <Button
                                  variant="primary"
                                  type="button"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    navigate(`/shopDetailPage/${shop.shop_id}`);
                                  }}
                                >
                                  View shop
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        }
                      </Col>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h5 style={{ padding: 30 }}>
                      {" "}
                      No shops Connected Currently !
                    </h5>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>

      {/* View Connected Vending Machines List Popup */}
      <div
        className="modal fade"
        id="viewVemdingMachines"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewVemdingMachinesLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewVemdingMachinesLabel">
                Connected Vending Machines List
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Row className="g-3">
                {connectedVms?.length > 0 ? (
                  connectedVms.map((item, index) => {
                    return (
                      <Col sm="3" key={item.vm_id}>
                        {
                          <Card className="card-one">
                            <Card.Body className="p-3">
                              <div className="news-item displayCenter">
                                <div className="news-img  mb-2">
                                  <img
                                    src={item.vm_images[0]}
                                    alt={item.vm_name}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <h6 className="fw-semibold text-success lh-4">
                                {item.vm_name}
                              </h6>
                              <div className="d-grid">
                                <Button
                                  variant="primary"
                                  type="button"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    navigate(
                                      `/vendingMachineProduct/${item.vm_id}`
                                    );
                                  }}
                                >
                                  View VM
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        }
                      </Col>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h5 style={{ padding: 30 }}>
                      {" "}
                      No Vending Machines Connected Currently !
                    </h5>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
