import axios from "axios";
import JwtInterceoptor from "../../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllStores() {
  return await axios.get(apiUrl + "/store");
}

export async function addStore(data) {
  return await JwtInterceoptor.put(apiUrl + "/store/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getStoreById(id) {
  return await axios.get(apiUrl + "/store/" + id);
}

export async function updateStore(data) {
  return await JwtInterceoptor.patch(apiUrl + "/store/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteStoreById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/store/", {
    data: {
      store_id: id,
    },
  });
}

export async function updateStoreImage(id, image) {
  const data = { store_id: id, store_images: image };
  // console.log("data in service",data);
  return await JwtInterceoptor.patch(apiUrl + "/store/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
