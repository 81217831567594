import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Col, Table, Badge } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { deleteImageFromS3 } from "../../api-services/s3-service";
import {
  getAllSliders,
  deleteSliderById,
  updateSliderState,
} from "../../api-services/SliderTwoAPi";
import SkeletonLoading from "../../pages/SkeletonLoading";
import ServiceUnavailable from "../../pages/ServiceUnavailable";

export default function SliderTwoManagement() {
  const { userRole } = useContext(AuthContext);

  const [slidersData, setSlidersData] = useState([]);
  const [slidersDataTwo, setSlidersDataTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // move to normal/featured related
  const [moveSliderById, setMoveSlideId] = useState("");

  // delete popup //
  const [deleteSliderId, setDeleteSliderId] = useState("");
  const [deleteSliderImageUrl, setDeleteSliderImageUrl] = useState("");

  const removeSlider = async () => {
    await deleteSliderById(deleteSliderId)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          let imgUrl = deleteSliderImageUrl;
          let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
          await deleteImageFromS3(imgname);
          // console.log("=============== image deleted ================");
          getAllSlidersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    getAllSlidersData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllSlidersData = async () => {
    setLoading(true);
    await getAllSliders()
      .then((res) => {
        setSlidersData(res.data.sliders);
        setSlidersDataTwo(res.data.sliders);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    // console.log("sliders", slidersData);
  };

  // For Navigation
  const navigate = useNavigate();

  const searchData = (val) => {
    setQ(val);
    if (val == "") {
      setSlidersData(slidersDataTwo);
      // getAllSlidersData();
      return;
    }
    const query = val.toLowerCase();
    // setCurrentPage(1);
    const allData = slidersData.filter(
      (item) => item.slider_two_title.toLowerCase().indexOf(query) > -1
    );
    setSlidersData(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setSlidersData(slidersDataTwo);
    }
  };

  const updateSliderStateByID = async (state) => {
    await updateSliderState(moveSliderById, state)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success("Slider State Updated.", {
            position: "top-right",
          });
          getAllSlidersData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Offer Slider Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Offer Slider Management
            </h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Slider Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>

              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {(userRole === 7 || userRole === 6) && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    size="md"
                    onClick={() => {
                      navigate("/offerSliderform");
                    }}
                  >
                    <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                    <span className="d-none d-sm-inline">Slider</span>
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Sliders List Started */}
        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && slidersData.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Offers Sliders Data Currently</h5>
            </div>
          )}
          {!loading && !isError && slidersData.length > 0 && (
            <Col xs="12 mt-5">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>State</th>
                        <th>Code</th>
                        <th>CountDown</th>
                        {/* <th>Created On</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slidersData &&
                        slidersData.map((item) => {
                          return (
                            <tr
                              key={item.slider_two_id}
                              className="text-center"
                            >
                              <td>
                                {item.slider_two_image ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        src={item.slider_two_image}
                                        alt={item.slider_two_title}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item">
                                    <div className="interest-icon bg-light ">
                                      {/* <i className="ri-github-fill"></i> */}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.slider_two_title}
                                </span>
                              </td>
                              <td>
                                <span className="ff-numerals">
                                  {item.slider_two_state == 0 ? (
                                    <Badge bg="secondary p-2" pill>
                                      Normal
                                    </Badge>
                                  ) : (
                                    <Badge bg="success p-2" pill>
                                      Featurd
                                    </Badge>
                                  )}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item.slider_two_code}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item.slider_two_count_down}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Link
                                        to={`/offerSliderform/${item.slider_two_id}`}
                                      >
                                        <Button variant="primary">
                                          Edit Slider
                                        </Button>
                                      </Link>
                                      {item.slider_two_state == 0 ? (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#featuredPopup"
                                          onClick={() => {
                                            setMoveSlideId(item.slider_two_id);
                                          }}
                                        >
                                          Make Featured
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#NormalPopup"
                                          onClick={() => {
                                            setMoveSlideId(item.slider_two_id);
                                          }}
                                        >
                                          Make Normal
                                        </Button>
                                      )}
                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteSliderId(item.slider_two_id);
                                          setDeleteSliderImageUrl(
                                            item.slider_two_image
                                          );
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeSlider}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}

      {/* Make Featured  */}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Move it to Featured?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateSliderStateByID(1)}
              >
                Make Featured
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Featured  */}

      {/* Make Normal  */}
      <div
        className="modal fade"
        id="NormalPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="NormalPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="NormalPopupLabel">
                Are You want to Move it to Normal?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateSliderStateByID(0)}
              >
                Make Normal
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Normal */}
    </React.Fragment>
  );
}
