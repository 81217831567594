import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function addNewsLetter(data) {
  return await JwtInterceoptor.put(apiUrl + "/news_letter/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getAllNewsLetters() {
  return await JwtInterceoptor.get(apiUrl + "/news_letter/");
}

export async function updateNewsLetter(data) {
  return await JwtInterceoptor.patch(apiUrl + "/news_letter/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateNewsLetterState(id, status) {
  const data = { news_letter_id: id, news_letter_status: status };
  return await JwtInterceoptor.patch(apiUrl + "/news_letter/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function sendNewsLetters(data) {
  return await JwtInterceoptor.post(apiUrl + "/news_letter/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateNewsLetterImage(id, imageOne, imageTwo) {
  const data = { news_letter_id: id, news_letter_image1: imageOne };
  return await JwtInterceoptor.patch(apiUrl + "/news_letter/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getNewsLetterById(id) {
  return await JwtInterceoptor.get(apiUrl + "/news_letter/" + id);
}

export async function getFilteredNewsLetters(type) {
  return await JwtInterceoptor.get(apiUrl + "/contact_us/" + type);
}

export async function deleteNewsLetterById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/contact_us/", {
    data: {
      news_letter_id: id,
    },
  });
}
