import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Sidebar from "../layouts/Sidebar";
import { convertToWebP } from "../api-services/FileConvertionService";
import { UploadImagetoS3 } from "../api-services/s3-service.js";

import {
  addCategory,
  getCategoryById,
  updateCategory,
  updateCategoryImage,
} from "../api-services/SubCategoryApi.js";
import { getAllCategories } from "../api-services/CategoryApi";

import Select from "react-dropdown-select";

window.Buffer = window.Buffer || require("buffer").Buffer;
// let renderCount = 0;

export default function SubCategoryForm() {
  // const [dropDownvalues, setdropDownvalues] = useState([]);

  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [categoryimage, setCatgoryImage] = useState("");
  const [categoryImageTwo, setcategoryImageTwo] = useState("");
  const [categoryImageThree, setcategoryImageThree] = useState("");
  const [categoryImageFour, setcategoryImageFour] = useState("");
  const [categoryImageFive, setcategoryImageFive] = useState("");

  // options displaying in
  const [finalOptionsArray, setFinalOptionArray] = useState([]);
  // sub category - categories
  // const [selectedOption, setSelectedOption] = useState(null);

  const [defaultValuesArray, setDefaultValuesArray] = useState([]);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  //===============  Get Api Calls for add operation ======================= //

  useEffect(() => {
    if (isAddMode) {
      getAllCategoriesData();
    }
    if (!isAddMode) {
      if (location?.state?.name) {
        setSubCategoryName(location.state.name);
        setSubCategoryId(location.state.id);
      }
    }
  }, []);

  const getAllCategoriesData = async () => {
    let finalArrayTwo = [];
    await getAllCategories()
      .then((res) => {
        if (res.data.status === 1) {
          let combinedCategories = res.data.categories;

          combinedCategories.forEach((el) => {
            let c_value = el.category_id;
            let c_label = el.category_title;
            let newObj = {
              value: c_value,
              label: c_label,
            };
            finalArrayTwo.push(newObj);
          });

          setFinalOptionArray(finalArrayTwo);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  //===============  Get Api Calls for add operation ======================= //

  //===============  Get Api Calls for Update operation ======================= //

  var selectedCategoryIds;
  useEffect(() => {
    selectedCategoryIds = defaultValuesArray?.map((obj: any) => {
      return obj.value;
    });
  }, [defaultValuesArray]);

  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getcategoryDataById(id);
    }
  }, []);

  var finalArray = [];
  // images array state
  const [updatefinalImagesS3Urls, setupdatefinalImagesS3Urls] = useState([]);
  // get category by id api //
  const getcategoryDataById = async (id: any) => {
    finalArray = [];
    // ============== get Categories List ==================== //
    await getAllCategories()
      .then((response) => {
        if (response.data.status === 1) {
          console.log("response", response);
          let combinedCategories = response.data.categories;
          combinedCategories.forEach((el) => {
            let c_value = el.category_id;
            let c_label = el.category_title;
            let newObj = {
              value: c_value,
              label: c_label,
            };
            finalArray.push(newObj);
          });
          setFinalOptionArray(finalArray);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });

    // ============== get Data By Id ==================== //
    await getCategoryById(id)
      .then((res) => {
        if (res.data.status === 1) {
          let data = res.data;
          let category = data.sub_category;
          //
          setValue("sub_category_title", category.sub_category_title);
          setValue(
            "sub_category_description",
            category.sub_category_description
          );
          setValue("sub_category_images", category.sub_category_images);
          setCategoryState(category.sub_category_state);
          if (category.sub_category_images) {
            // let categoryImages = JSON.parse(category.sub_category_images);
            setCatgoryImage(category.sub_category_images[0]);
            setcategoryImageTwo(category.sub_category_images[1]);
            setcategoryImageThree(category.sub_category_images[2]);
            setcategoryImageFour(category.sub_category_images[3]);
            setcategoryImageFive(category.sub_category_images[4]);
            setupdatefinalImagesS3Urls(category.sub_category_images); // assigning images array to state array
          }
          //============= category sub categories related ================ //
          let subCategory_categories = category.sub_category_categories;
          //
          subCategory_categories.forEach((el: any) => {
            var filteredObject = finalArray.filter((obj) => {
              return obj.value == el;
            });
            oldSelectedCategoriesArray.push(filteredObject[0]);
          });
          //
          setDefaultValuesArray(oldSelectedCategoriesArray);
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  let oldSelectedCategoriesArray = [];

  type formValues = {
    sub_category_title: String;
    sub_category_description: String;
    sub_category_state: number;
    sub_category_images: string[];
    sub_category_categories: string[];
  };

  const form = useForm<formValues>({
    defaultValues: {
      sub_category_title: "",
      sub_category_description: "",
      sub_category_state: 0,
      sub_category_images: [],
      sub_category_categories: [],
    },
  });

  const { register, handleSubmit, setValue, reset } = form;

  // const { errors } = formState;

  const [file, setFile] = useState<File>();
  const [fileTwo, setFileTwo] = useState<File>();
  const [fileThree, setFileThree] = useState<File>();
  const [fileFour, setFileFour] = useState<File>();
  const [fileFive, setFileFive] = useState<File>();

  // const handleClick = (event: React.FormEvent) => {
  //   const files = (event.target as HTMLInputElement).files;
  //   if (files && files.length > 0) {
  //     setFile(files[0]);
  //   }
  // };

  // =========== Category state Attribute =============== //
  let categoryStateTypes = [
    { id: 0, stateValue: "normal" },
    { id: 1, stateValue: "featured" },
  ];

  const handleChangeStateType = (event, index) => {
    console.log(categoryStateTypes[index].stateValue, "stateValue");
    let value = categoryStateTypes[index].stateValue;
    setStateValue(value);
    setCategoryState(index);
  };

  const [categoryState, setCategoryState] = useState(3);
  const [stateValue, setStateValue] = useState("");

  const resetValues = () => {
    reset();
    selectedCategoryIds = [];
    setCategoryState(3);
    setSpinner(false);
    setDefaultValuesArray([]);
  };

  // ========= Form Submit event ================ //
  var finalImagesS3Urls: any = [];

  const onSubmit = async (data: any) => {
    if (isAddMode) {
      setSpinner(true);
      data["sub_category_categories"] = selectedCategoryIds;
      await addCategory(data)
        .then(async (res) => {
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_categoryId = response_data.sub_category_id;
            if (file || fileTwo || fileThree || fileFour || fileFive) {
              let folderPath =
                "Common/SubCategories/" + response_data_categoryId;
              if (file) {
                let filename =
                  response_data_categoryId + "_sub_category_image_one.webp";
                let response = await UploadImagetoS3(
                  file,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[0] = response["Location"];
              }
              if (fileTwo) {
                let filename =
                  response_data_categoryId + "_sub_category_image_two.webp";
                let response = await UploadImagetoS3(
                  fileTwo,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[1] = response["Location"];
              }
              if (fileThree) {
                let filename =
                  response_data_categoryId + "_sub_category_image_three.webp";
                let response = await UploadImagetoS3(
                  fileThree,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[2] = response["Location"];
              }
              if (fileFour) {
                let filename =
                  response_data_categoryId + "_sub_category_image_four.webp";
                let response = await UploadImagetoS3(
                  fileFour,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[3] = response["Location"];
              }
              if (fileFive) {
                let filename =
                  response_data_categoryId + "_sub_category_image_five.webp";
                let response = await UploadImagetoS3(
                  fileFive,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[4] = response["Location"];
              }
              // ========== updating with image s3 urls =================== //
              await updateCategoryImage(
                response_data_categoryId,
                finalImagesS3Urls
              )
                .then((updateRes) => {
                  if (updateRes.data.status === 1) {
                    resetValues();
                    toast.success(updateRes.data.message, {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.message, {
                      position: "top-right",
                    });
                    return;
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
              // ========== updating with image s3 urls =================== //
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            resetValues();
            toast.error(res.data.message, {
              position: "top-right",
            });
            console.log("error meg", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // ============= UPDATE ================ //
    } else {
      setSpinner(true);
      data.sub_category_id = id;
      data["sub_category_state"] = categoryState;
      data["sub_category_categories"] = selectedCategoryIds;
      // ================== images related ===================== //
      let folderPath = "Common/SubCategories/" + id;
      if (file) {
        let filename = id + "_sub_category_image_one.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        updatefinalImagesS3Urls[0] = response["Location"];
      }
      if (fileTwo) {
        let filename = id + "_sub_category_image_two.webp";
        let response = await UploadImagetoS3(fileTwo, folderPath, filename);
        updatefinalImagesS3Urls[1] = response["Location"];
      }
      if (fileThree) {
        let filename = id + "_sub_category_image_three.webp";
        let response = await UploadImagetoS3(fileThree, folderPath, filename);
        updatefinalImagesS3Urls[2] = response["Location"];
      }
      if (fileFour) {
        let filename = id + "_sub_category_image_four.webp";
        let response = await UploadImagetoS3(fileFour, folderPath, filename);
        updatefinalImagesS3Urls[3] = response["Location"];
      }
      if (fileFive) {
        let filename = id + "_sub_category_image_five.webp";
        let response = await UploadImagetoS3(fileFive, folderPath, filename);
        updatefinalImagesS3Urls[4] = response["Location"];
      }
      // console.log("Updated array data two", JSON.stringify(updatefinalImagesS3Urls));
      // data["category_image"] = JSON.stringify(updatefinalImagesS3Urls);
      data["sub_category_images"] = updatefinalImagesS3Urls;
      await updateCategory(data)
        .then((updateRes) => {
          if (updateRes.data.status === 1) {
            resetValues();
            setCatgoryImage("");
            setcategoryImageTwo("");
            setcategoryImageThree("");
            setcategoryImageFour("");
            setcategoryImageFive("");
            toast.success(updateRes.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setCatgoryImage("");
            setcategoryImageTwo("");
            setcategoryImageThree("");
            setcategoryImageFour("");
            setcategoryImageFive("");
            toast.error(updateRes.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/product-dashboard">Product Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/product-dashboard/subcategory">
                  Sub Category Management
                </Link>
              </li>
              {!isAddMode && (
                <li
                  className="breadcrumb-item"
                  aria-current="page"
                  onClick={() => {
                    navigation(`/commondetailpage/${subCategoryId}`);
                  }}
                >
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    {subCategoryName}
                  </span>
                </li>
              )}
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Sub Category" : "Edit Sub Category"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Sub Category" : "Edit Sub Category"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline"> Back </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="mb-3">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                  Sub Category Name
                </label>
                <Col sm="10">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Sub Category Name"
                    {...register("sub_category_title")}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                  Sub Category Description
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Description here..."
                    rows={3}
                    {...register("sub_category_description")}
                  ></textarea>
                </Col>
              </Row>
              <div className="row mb-3 ">
                <div className="col mt-2">
                  <p>Sub Catgeory state</p>
                </div>
                <div className="col-10 mt-2">
                  <div className="row">
                    {categoryStateTypes.map((type, i) => (
                      <div className="col-lg-6" key={i}>
                        <input
                          className="mt-1"
                          id={type.stateValue}
                          key={type.id}
                          type="radio"
                          value={stateValue}
                          checked={categoryState === i}
                          onChange={(event) => handleChangeStateType(event, i)}
                        />
                        <label
                          className="form-label ms-2"
                          htmlFor={type.stateValue}
                        >
                          {type.stateValue}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row mb-3 ">
                <div className="col mt-2">
                  <p>Sub Category Categories</p>
                </div>
                <div className="col-10 mt-2">
                  {finalOptionsArray && !isAddMode ? (
                    <Select
                      name="select"
                      options={finalOptionsArray}
                      labelField="label"
                      valueField="value"
                      multi
                      onChange={(value) => setDefaultValuesArray(value)}
                      values={defaultValuesArray}
                      color={"#22c595"}
                      style={{ padding: 10 }}
                    />
                  ) : (
                    <Select
                      name="select"
                      options={finalOptionsArray}
                      labelField="label"
                      valueField="value"
                      multi
                      onChange={(value) => setDefaultValuesArray(value)}
                      values={defaultValuesArray}
                      clearable
                      color={"#22c595"}
                      style={{ padding: 10 }}
                    />
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col mt-3">
                  <label>Sub Category Images</label>
                  <p className="mb-1 text-warning">
                    Preffered Image Size 580px x 550px{" "}
                  </p>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="categoryimage" className="mt-3 mb-1">
                        Sub Category Image One
                      </label>
                      <input
                        id="categoryimage"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFile(webpFile);
                        }}
                      />
                      {!isAddMode && categoryimage && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={categoryimage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="categoryimageTwo" className="mt-3 mb-1">
                        Sub Category Image Two
                      </label>
                      <input
                        id="categoryimageTwo"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileTwo(webpFile);
                        }}
                      />
                      {!isAddMode && categoryImageTwo && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={categoryImageTwo}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="categoryimageThree" className="mt-3 mb-1">
                        Sub Category Image Three
                      </label>
                      <input
                        id="categoryimageThree"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileThree(webpFile);
                        }}
                      />
                      {!isAddMode && categoryImageThree && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={categoryImageThree}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="categoryimageFour" className="mt-3 mb-1">
                        Sub Category Image four
                      </label>
                      <input
                        id="categoryimageFour"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFour(webpFile);
                        }}
                      />
                      {!isAddMode && categoryImageFour && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={categoryImageFour}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="categoryimageFive" className="mt-3 mb-1">
                        Sub Category Image five
                      </label>
                      <input
                        id="categoryimageFive"
                        type="file"
                        className="form-control"
                        onChange={async (e) => {
                          let webpFile: any = await convertToWebP(
                            e.target.files[0]
                          );
                          setFileFive(webpFile);
                        }}
                      />
                      {!isAddMode && categoryImageFive && (
                        <div className="mt-3 mb-3">
                          <label className="mb-2">Current Image</label>
                          <div className="news-item">
                            <div className="news-img fullwidth-img">
                              <img
                                src={categoryImageFive}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
              {/* Button */}
              <div className="text-end mt-3">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
