import axios from "axios";
import JwtInterceoptor from "../../auth/JwtInterceptor";

let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function addShopProduct(data) {
  return await JwtInterceoptor.put(apiUrl + "/shop_product/", data, {
    headers: { "Content-Type": "application/json" },
  });
}

export async function getShopProductById(id) {
  return await axios.get(apiUrl + "/shop_product/" + id);
}

export async function updateShopProduct(data) {
  return await JwtInterceoptor.patch(apiUrl + "/shop_product/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteShopProductById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/shop_product/", {
    data: {
      shop_product_id: id,
    },
  });
}
