import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
// import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import { getFooter, updateFooter } from "../api-services/FooterAPi";
import { convertToWebP } from "../api-services/FileConvertionService";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function FooterForm() {
  const [spinner, setSpinner] = useState(false);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const navigation = useNavigate();

  useEffect(() => {
    getFooterData();
  }, []);

  const [footerimage, setFooterImage] = useState("");

  const getFooterData = async () => {
    await getFooter()
      .then((res) => {
        let data = res.data;
        let footer = data.footer;

        setValue("footer_description", footer?.footer_description);
        setValue("footer_disclaimer", footer?.footer_disclaimer);
        setValue("footer_icon", footer?.footer_icon);
        setFooterImage(footer?.footer_icon);
        setValue("footer_quick_links", footer?.footer_quick_links); // string
        setValue("footer_payment_links", footer?.footer_payment_links); // s3 image urls
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      footer_icon: "",
      footer_description: "",
      footer_disclaimer: "",
      footer_quick_links: [
        {
          title: "",
          image_url: "",
        },
      ],
      footer_payment_links: [
        {
          title: "",
          image_url: "",
        },
      ],
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, watch, control, setValue, reset } =
    form;

  // Interface
  type formValues = {
    footer_icon: string;
    footer_description: string;
    footer_disclaimer: string;
    footer_quick_links: {
      title: string;
      image_url: string;
    }[];
    footer_payment_links: {
      title: string;
      image_url: string;
    }[];
  };

  const { fields, append, remove } = useFieldArray({
    name: "footer_quick_links",
    control,
  });

  const fieldArray2 = useFieldArray({
    name: "footer_payment_links",
    control,
  });

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  // const handleClick = (event: React.FormEvent) => {
  //   const files = (event.target as HTMLInputElement).files;
  //   if (files && files.length > 0) {
  //     setFile(files[0]);
  //   }
  // };

  const handlePaymentLinksImages = async (e, index) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    let file: any = await convertToWebP(e.target.files[0]);
    // let file = e.target.files[0];
    if (file) {
      let folderPath = s3FolderType + "/footer";
      let filename = index + "_payment_image.webp";
      let response = await UploadImagetoS3(file, folderPath, filename);
      let s3Url = response["Location"];
      toast.success("image uploaded", {
        position: "top-right",
      });
      fieldArray2.fields[index].image_url = s3Url;
      fieldArray2.fields[index].title = watch(
        `footer_payment_links.${index}.title`
      );
      // console.log("fieldArray2", fieldArray2);
    }
  };

  const resetValues = () => {
    reset();
  };

  const onSubmit = async (data: any) => {
    let s3FolderType = process.env.REACT_APP_S3_FOLDER_NAME;
    // console.log("data", data);
    setSpinner(true);
    data.footer_id = "1";
    if (file) {
      let folderPath = s3FolderType + "/footer";
      let filename = "1" + "_footer_icon.webp";
      let response = await UploadImagetoS3(file, folderPath, filename);
      data["footer_icon"] = response["Location"];
    }
    for (let i = 0; i < fieldArray2.fields.length; i++) {
      fieldArray2.fields[i].title = watch(`footer_payment_links.${i}.title`);
    }
    data["footer_payment_links"] = fieldArray2.fields;
    await updateFooter(data)
      .then((updateRes) => {
        if (updateRes.data.status === 1) {
          resetValues();
          setFooterImage("");
          toast.success(updateRes.data.message, {
            position: "top-right",
          });
          navigation(-1);
        } else {
          resetValues();
          setFooterImage("");
          // console.log("error", updateRes.data.message);
          toast.error(updateRes.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/website-dashboard/footer">Footer Management</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Footer
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">Edit Footer</h4>
          </div>
          <div>
            <Button
              style={{ color: "white", borderColor: "#dae0e6bf" }}
              className="d-flex align-items-center gap-2 bg-secondary"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline"> Back </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="row mb-3">
                <div className="col-lg-2 col-sm-12">
                  <label htmlFor="footericon">FooterIcon</label>
                </div>
                <div className="col-lg-10 col-sm-12">
                  <input
                    type="file"
                    className="form-control"
                    id="footericon"
                    // onChange={handleClick}
                    onChange={async (e) => {
                      let webpFile: any = await convertToWebP(
                        e.target.files[0]
                      );
                      setFile(webpFile);
                    }}
                  />
                  {footerimage && (
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Current Image</label>
                      <div className="news-item">
                        <div className="news-img fullwidth-img">
                          <img src={footerimage} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Row className="mb-3">
                <label htmlFor="Textarea" className="col-sm-2 col-form-label">
                  Footer Description
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Description here..."
                    rows={3}
                    {...register("footer_description")}
                  ></textarea>
                </Col>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="TextareaTwo"
                  className="col-sm-2 col-form-label"
                >
                  footer Desclaimer
                </label>
                <Col sm="10">
                  <textarea
                    className="form-control"
                    id="TextareaTwo"
                    placeholder="Enter Description here..."
                    rows={3}
                    {...register("footer_disclaimer")}
                  ></textarea>
                </Col>
              </Row>
              {/* Quick Links */}
              <div className="row mb-5">
                <div className="col-lg-2 col-sm-12">
                  <label>Footer Quick Links</label>
                </div>
                <div className="col-lg-10 col-sm-12">
                  <div>
                    {fields.map((field, index) => {
                      return (
                        <div className="row mb-2" key={field.id}>
                          <div className="col-lg-5 col-xl-5 col-md-5">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(
                                `footer_quick_links.${index}.title` as const
                              )}
                            />
                          </div>
                          <div className="col-lg-5 col-xl-5 col-md-5">
                            <label>Image Url</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(
                                `footer_quick_links.${index}.image_url` as const
                              )}
                            />
                          </div>
                          <div className="col-lg-2 col-xl-2 col-md-3">
                            {index > 0 && (
                              <div className="mt-3">
                                <button
                                  type="button"
                                  className="btn btn-danger mt-1"
                                  onClick={() => remove(index)}
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => append({ title: "", image_url: "" })}
                    >
                      + Add More Field
                    </button>
                  </div>
                </div>
              </div>
              {/* Payment Links */}
              <div className="row mb-3">
                <div className="col-lg-2 col-sm-12">
                  <p>Footer Payment Links</p>
                </div>
                <div className="col-lg-10 col-sm-12">
                  <div>
                    {fieldArray2.fields.map((field, index) => {
                      return (
                        <div className="row mb-2" key={field.id}>
                          <div className="col-lg-5 col-xl-5 col-md-5">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(
                                `footer_payment_links.${index}.title` as const
                              )}
                            />
                          </div>

                          <div className="col-lg-5 col-xl-5 col-md-5">
                            <label>Image Url</label>
                            <input
                              type="file"
                              className="form-control"
                              onChange={(e) => {
                                handlePaymentLinksImages(e, index);
                              }}
                            />
                            {fieldArray2.fields[index].image_url && (
                              <div className="mt-3 mb-3">
                                <label className="mb-2">Current Image</label>
                                <div className="news-item">
                                  <div className="news-img fullwidth-img">
                                    <img
                                      src={fieldArray2.fields[index].image_url}
                                      alt={fieldArray2.fields[index].title}
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-2 col-xl-2 col-md-3">
                            {index > 0 && (
                              <div className="mt-3">
                                <button
                                  type="button"
                                  className="btn btn-danger mt-1"
                                  onClick={() => fieldArray2.remove(index)}
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        fieldArray2.append({ title: "", image_url: "" })
                      }
                    >
                      + Add More Field
                    </button>
                  </div>
                </div>
              </div>

              {/* Button */}
              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>

        {/*=========================== Form ======================================*/}
      </div>
    </>
  );
}
