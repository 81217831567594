import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
  inventoryManagementMenu,
  // dashboardMenu,
  // applicationsMenu,
  pagesMenu,
  uiElementsMenu,
  websiteMenu,
  productsMenu,
} from "../data/Menu";
import logo from "../assets/img/Vending Grocery _Crop _Trans.png";
// import { getSideBarId } from "../api-services/AuthService";
export default class Sidebar extends Component {
  // toggleFooterMenu = (e) => {
  //   e.preventDefault();
  //   let parent = e.target.closest(".sidebar");
  //   parent.classList.toggle("footer-menu-show");
  // }

  // state = {
  //   activeId: ""
  // };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeId: ""
  //   };
  //   this.getSideBarValue();
  // }

  // getSideBarValue = () => {
  //   let res = getSideBarId();
  //   console.log("res",res);
  //   // this.state = {
  //   //   activeId: res
  //   // };
  //   this.setState({ activeId: res });
  // }

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link to="/dashboard" className="">
            <img src={logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={(ref) => (this._scrollBarRef = ref)}
        >
          <SidebarMenu
            onUpdateSize={() => this._scrollBarRef.updateScroll()}
            // s_id={this.getValue}
            // func={this.getSideBarValue}
          />
        </PerfectScrollbar>
      </div>
    );
  }
}

class SidebarMenu extends Component {
  populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" key={key}>
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li
          key={key}
          //  className="nav-item"
          // className={m.id == (this.state?.activeId) ? 'nav-item show' : 'nav-item show'}
          className="nav-item show"
          // onClick={() => {
          //   console.log("id", m.id);
          //   this.setState({
          //     activeId: m.id
          //   })
          // }}
        >
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <NavLink
              to={m.link}
              onClick={this.toggleSubMenu}
              className="nav-link has-sub"
            >
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  toggleSubMenu = (e) => {
    // console.log("submenu clicked");
    // e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;
    // console.log("parent", parent);
    // console.log("node", node);
    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  render() {
    return (
      <React.Fragment>
        <div className="nav-group show">
          {/* <div className="nav-label" onClick={this.toggleMenu}>
            Inventory
          </div> */}
          {this.populateMenu(inventoryManagementMenu)}
        </div>
        <div className="nav-group show">
          {/* <div className="nav-label" onClick={this.toggleMenu}>
            Website
          </div> */}
          {this.populateMenu(productsMenu)}
        </div>
        <div className="nav-group show">
          {/* <div className="nav-label" onClick={this.toggleMenu}>
            Products
          </div> */}
          {this.populateMenu(websiteMenu)}
        </div>
      </React.Fragment>
    );
  }
}

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("sidebar-skin");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});
