import axios from "axios";
import JwtInterceoptor from "../auth/JwtInterceptor";
let apiUrl = process.env.REACT_APP_DEV_API_KEY;

export async function getAllCategoriesData(type) {
  return await axios.get(apiUrl + "/category/categories/" + type);
}

export async function getCategoryById(id) {
  return await axios.get(apiUrl + "/category/" + id);
}

export async function getAllCategories() {
  return await axios.get(apiUrl + "/category/");
}

//
export async function addCategory(data) {
  return await JwtInterceoptor.put(apiUrl + "/category/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateCategory(data) {
  return await JwtInterceoptor.patch(apiUrl + "/category/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteCategoryById(id) {
  return await JwtInterceoptor.delete(apiUrl + "/category/", {
    data: {
      category_id: id,
    },
  });
}

export async function updateCategoryState(id, state) {
  const data = { category_id: id, category_state: state };
  return await JwtInterceoptor.patch(apiUrl + "/category/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateCategoryImage(id, image) {
  const data = { category_id: id, category_images: image };
  return await JwtInterceoptor.patch(apiUrl + "/category/", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
