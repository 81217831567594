import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../auth/AuthContext";
import { Button, Card, Container, Nav } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import { useForm } from "react-hook-form";
import {
  getFilteredEnquiries,
  updateEnquiry,
  updateEnquiryState,
  deleteEnquiryById,
} from "../../api-services/ContactUsApi";
import Forbidden from "../../pages/Forbidden";

export default function EnquiryManagement() {
  const { userRole } = useContext(AuthContext);

  // un-read
  const [enquiryData, setEnquiryData] = useState([]);
  const [enquiryDataTwo, setEnquiryDataTwo] = useState([]);
  // read
  const [readEnquiryData, setReadEnquiryData] = useState([]);
  const [readEnquiryDataTwo, setReadEnquiryDataTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  const [key, setKey] = useState("");

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  // Get Api Call
  useEffect(() => {
    if (userRole === 7 || userRole === 6) {
      getAllUnreadEnquiriesData();
      getAllreadEnquiriesData();
    }
  }, []);

  const getAllUnreadEnquiriesData = async () => {
    await getFilteredEnquiries(0)
      .then((res) => {
        setEnquiryData(res.data.enquiries);
        setEnquiryDataTwo(res.data.enquiries);
      })
      .catch((err) => {
        console.log("error", err);
        // toast.error(err.message, {
        //   position: "top-right",
        // });
      });
    // console.log("un read Enquiries Data", enquiryData);
  };

  const getAllreadEnquiriesData = async () => {
    await getFilteredEnquiries(1)
      .then((res) => {
        setReadEnquiryData(res.data.enquiries);
        setReadEnquiryDataTwo(res.data.enquiries);
      })
      .catch((err) => {
        console.log("error", err);
        // toast.error(err.message, {
        //   position: "top-right",
        // });
      });
    // console.log("Read Enquiries Data", readEnquiryData);
  };

  // response object
  const [responseEnquiry, setResponseEnquiry] = useState({});

  // move to normal/featured related
  const [moveEnquiryById, setMoveEnquiryById] = useState("");

  const updateEnquiryStateByID = async (state) => {
    await updateEnquiryState(moveEnquiryById, state)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllUnreadEnquiriesData();
          getAllreadEnquiriesData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // delete popup //
  const [deleteEnquiryId, setDeleteEnquiryId] = useState("");

  const removeEnquiryByID = async () => {
    await deleteEnquiryById(deleteEnquiryId)
      .then((res) => {
        // console.log("response", res);
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllUnreadEnquiriesData();
          getAllreadEnquiriesData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  // Form Configuration
  const form = useForm({
    defaultValues: {
      enquiry_response: "",
    },
  });

  const { register, handleSubmit, reset } = form;

  const onSubmit = async (data) => {
    data.enquiry_id = responseEnquiry.enquiry_id;
    await updateEnquiry(data)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          getAllUnreadEnquiriesData();
          getAllreadEnquiriesData();
        } else {
          toast.success(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
    resetValues();
  };

  const resetValues = () => {
    reset();
  };

  const searchData = (val) => {
    setQ(val);
    if (val === "") {
      setEnquiryData(enquiryDataTwo);
      setReadEnquiryData(readEnquiryDataTwo);
      return;
    }
    if (key === "unRead") {
      const query = val.toLowerCase();
      const allData = enquiryData.filter(
        (item) => item.enquiry_last_name.toLowerCase().indexOf(query) > -1
      );
      setEnquiryData(allData);
    } else if (key === "read") {
      const query = val.toLowerCase();
      const allData = readEnquiryData.filter(
        (item) => item.enquiry_last_name.toLowerCase().indexOf(query) > -1
      );
      setReadEnquiryData(allData);
    }
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      if (key === "unRead") {
        setEnquiryData(enquiryDataTwo);
      } else if (key === "read") {
        setReadEnquiryData(readEnquiryDataTwo);
      }
    }
  };

  if (userRole === 5) {
    return (
      <>
        <div className="main main-app p-3 p-lg-4">
          <Forbidden linkUrl={"/website-dashboard"} />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}

      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/website-dashboard">Website Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Enquiry Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Enquiry Management
            </h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Enquiry Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>
              <div></div>
            </div>
          </Card.Body>
        </Card>

        <div className="mt-5">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Un Read Enquiries"
          >
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  eventKey="Un Read Enquiries"
                  onClick={() => setKey("unRead")}
                >
                  Un Read Enquiries
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Read Enquiries"
                  onClick={() => setKey("read")}
                >
                  Read Enquiries
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Un Read Enquiries">
                <div className="row">
                  {enquiryData.length > 0 ? (
                    enquiryData.map((enquiry, index) => (
                      <div
                        className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3 mt-2"
                        key={index}
                      >
                        <Card className="card-event">
                          <Card.Body>
                            <h6 className="mb-1">
                              Name : {enquiry.enquiry_first_name}{" "}
                              {enquiry.enquiry_last_name}{" "}
                            </h6>
                            <p className="mb-1">
                              Phone : {enquiry.enquiry_phone_number}
                            </p>
                            <p>Email : {enquiry.enquiry_email}</p>
                          </Card.Body>
                          <Card.Footer>
                            <div className="d-flex gap-2">
                              {(userRole === 7 || userRole === 6) && (
                                <>
                                  <Button
                                    variant="info"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#featuredPopup"
                                    onClick={() => {
                                      setMoveEnquiryById(enquiry.enquiry_id);
                                    }}
                                    className="text-light"
                                  >
                                    <i className="ri-task-fill"></i> Mark as
                                    Read
                                  </Button>
                                  {enquiry.enquiry_response === "" && (
                                    <Button
                                      variant="success"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        setResponseEnquiry(enquiry);
                                      }}
                                      className="text-light"
                                    >
                                      <i className="ri-send-plane-fill"></i>{" "}
                                      Give Response
                                    </Button>
                                  )}
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setDeleteEnquiryId(enquiry.enquiry_id);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delPopup"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <Container>
                      <Card className="card-event">
                        <Card.Body>
                          <div className="text-center">
                            <h5> No Un-Read Enquiries Data Currently</h5>
                          </div>
                        </Card.Body>
                      </Card>
                    </Container>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Read Enquiries">
                <div className="row">
                  {readEnquiryData.length > 0 ? (
                    readEnquiryData.map((enquiry, index) => (
                      <div
                        className="col-xl-6  col-lg-12 col-sm-12 mb-3 mt-2"
                        key={index}
                      >
                        <Card className="card-event">
                          <Card.Body>
                            <h6 className="mb-1">
                              Name : {enquiry.enquiry_first_name}{" "}
                              {enquiry.enquiry_last_name}{" "}
                            </h6>
                            <p className="mb-1">
                              Phone : {enquiry.enquiry_phone_number}
                            </p>
                            <p>Email : {enquiry.enquiry_email}</p>
                          </Card.Body>
                          <Card.Footer>
                            <div className="d-flex gap-2 justify-content-center">
                              {(userRole === 7 || userRole === 6) && (
                                <>
                                  <Button
                                    variant="info"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#markAsUnreadPopup"
                                    onClick={() => {
                                      setMoveEnquiryById(enquiry.enquiry_id);
                                    }}
                                    className="text-light"
                                  >
                                    <i className="ri-task-fill"></i> Mark as
                                    Un-Read
                                  </Button>
                                  {enquiry.enquiry_response === "" && (
                                    <Button
                                      variant="success"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        setResponseEnquiry(enquiry);
                                      }}
                                      className="text-light"
                                    >
                                      <i className="ri-send-plane-fill"></i>{" "}
                                      Give Response
                                    </Button>
                                  )}
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setDeleteEnquiryId(enquiry.enquiry_id);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delPopup"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <Container>
                      <Card className="card-event">
                        <Card.Body>
                          <div className="text-center">
                            <h5> No Read Enquiries Data Currently</h5>
                          </div>
                        </Card.Body>
                      </Card>
                    </Container>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/* Make Read*/}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Move it to Read?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateEnquiryStateByID(1)}
              >
                Move to Read
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Read  */}

      {/* Make Un-Read*/}
      <div
        className="modal fade"
        id="markAsUnreadPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="markAsUnreadPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="markAsUnreadPopupLabel">
                Are You want to Move it to Read?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateEnquiryStateByID(0)}
              >
                Move to Un-Read
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make un-Read  */}

      {/* response Popup*/}
      <div
        className="modal fade"
        id="exampleModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Enquiry Response
              </h5>
              <button
                type="button"
                onClick={resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <h6 className="col-form-label">
                  Recipient :
                  <span className="main-subtitle ms-2">
                    {responseEnquiry.enquiry_first_name}{" "}
                    {responseEnquiry.enquiry_last_name}
                  </span>
                </h6>
                <h6 className="col-form-label">
                  Message :
                  <span className="main-subtitle ms-3">
                    {responseEnquiry.enquiry_message}
                  </span>
                </h6>
              </div>
              <form noValidate>
                <div className="mb-3">
                  <label className="col-form-label">Response:</label>
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Response here..."
                    rows={3}
                    {...register("enquiry_response")}
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={resetValues}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
              >
                Send Response
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeEnquiryByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}
    </React.Fragment>
  );
}
