import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Sidebar from "../layouts/Sidebar";
import { UploadImagetoS3 } from "../api-services/s3-service.js";
import { convertToWebP } from "../api-services/FileConvertionService";
import {
  getProductById,
  addProduct,
  updateProductImage,
  updateProduct,
} from "../api-services/ProductApi";
import { getAllBrands } from "../api-services/BrandApi";
import { getAllSubcategories } from "../api-services/SubCategoryApi";

window.Buffer = window.Buffer || require("buffer").Buffer;
// let renderCount = 0;

export default function ProductForm() {
  const { id } = useParams();
  const isAddMode = !id;
  const navigation = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [productImage, setProductImage] = useState("");
  const [productImageTwo, setProductImageTwo] = useState("");
  const [productImageThree, setProductImageThree] = useState("");
  const [productImageFour, setProductImageFour] = useState("");
  const [productImageFive, setProductImageFive] = useState("");

  const [selectedPackingType, setSelectedPackingType] = useState(0);
  const [selectedScaleType, setSelectedScaleType] = useState(0); // for weight
  const [selectedproducttype, setSelectedproducttype] = useState(0);

  // sending store id value from store detail page  //
  const location = useLocation();
  // sending store id value from store detail page  //

  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    if (!isAddMode) {
      if (location?.state?.name) {
        setProductName(location.state.name);
        setProductId(location.state.id);
      }
    }
    getAllBrandsData();
    getAllCategoriesData();
  }, []);

  // Brand drop down
  const [brandOption, setBrandOption] = useState("");
  const [subCategoryOption, setSubCategoryOption] = useState("");
  const setSelectedOption = (event) => {
    const option = allBrands.find(
      (option) => option.brand_id === event.target.value
    );
    setBrandOption(option.brand_id);
  };

  // category drop down
  const setSelectedCategoryOption = (event) => {
    const option = allCategories.find(
      (option) => option.sub_category_id === event.target.value
    );
    setSubCategoryOption(option.sub_category_id);
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  ///// Skin Switch /////

  const getAllBrandsData = async () => {
    await getAllBrands()
      .then((res) => {
        if (res.data.status === 1) {
          setAllBrands(res.data.brands);
        } else {
          // console.log("error", res.data.message);
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error("Failed To Fetch Brands Data", {
          position: "top-right",
        });
      });
  };

  const getAllCategoriesData = async () => {
    await getAllSubcategories()
      .then((res) => {
        if (res.data.status === 1) {
          setAllCategories(res.data.sub_categories);
        } else {
          // console.log("error", res.data.message);
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error("Failed To Fetch Sub Categories Data", {
          position: "top-right",
        });
      });
  };

  const form = useForm<formValues>({
    defaultValues: {
      product_title: "",
      product_description: "",
      product_brand: "",
      product_sub_category: "",
      product_images: [], // array of strings
      product_items_per_package: 0,
      product_add_ons: "",
      product_actual_price: 0,
      product_type: 1,
      product_packing_type: 0,
      product_weight: 0,
      product_scale: 0,
      // product_store: [],
    },
  });

  // Form Configuration
  const { register, handleSubmit, formState, setValue, reset } = form;

  // Interface
  type formValues = {
    product_title: string;
    product_description: string;
    product_brand: string;
    product_sub_category: string;
    product_images: string[]; // string Object with array of strings
    product_items_per_package: number;
    product_add_ons: string;
    product_actual_price: number;
    product_type: number;
    product_packing_type: number;
    product_weight: number;
    product_scale: number;
    // product_store: string[];
  };

  // Form error handling
  const { errors } = formState;

  // ================================== update related =========================================== //
  useEffect(() => {
    if (!isAddMode) {
      // get and set form fields
      getProductDataById(id);
    }
  }, []);

  // final images array for Api
  const [updatefinalImagesS3Urls, setupdatefinalImagesS3Urls] = useState([]);

  // ============== get Data By Id ==================== //
  const getProductDataById = async (id) => {
    await getProductById(id)
      .then((res) => {
        if (res.data.status == 1) {
          let data = res.data;
          let product = data.product;
          // console.log("product",product);
          setValue("product_title", product.product_title);
          setValue("product_description", product.product_description);
          setValue("product_brand", product.product_brand); //
          setBrandOption(product.product_brand);
          setValue("product_sub_category", product.product_sub_category); //
          setSubCategoryOption(product.product_sub_category);
          setValue(
            "product_items_per_package",
            product.product_items_per_package
          );
          setValue("product_add_ons", product.product_add_ons);
          setValue("product_actual_price", product.product_actual_price);
          setValue("product_type", product.product_type); // dropdown
          setValue("product_weight", product.product_weight);
          setValue("product_packing_type", product.product_packing_type); // dropdown
          setSelectedproducttype(product.product_type);
          setSelectedPackingType(product.product_packing_type);
          setSelectedScaleType(product.product_scale);
          // ============== Images ==================== //
          setValue("product_images", product.product_images);
          setProductImage(product.product_images[0]);
          setProductImageTwo(product.product_images[1]);
          setProductImageThree(product.product_images[2]);
          setProductImageFour(product.product_images[3]);
          setProductImageFive(product.product_images[4]);
          setupdatefinalImagesS3Urls(product.product_images);
          // console.log("updatefinalImagesS3Urls", updatefinalImagesS3Urls);
          // ============== Images ===================== //
        } else {
          // console.log("error mesaage", res.data.message);
          toast.error(res?.data?.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };
  // let oldSelectedStoresArray = [];

  // ============================ update related ============================== //

  // Img Upload to s3
  const [file, setFile] = useState<File>();
  const [fileTwo, setFileTwo] = useState<File>();
  const [fileThree, setFileThree] = useState<File>();
  const [fileFour, setFileFour] = useState<File>();
  const [fileFive, setFileFive] = useState<File>();

  // reset form
  const resetValues = () => {
    reset();
    setSpinner(false);
  };

  // updated s3 images array for update APi
  var finalImagesS3Urls: any = [];

  // on submit function
  const onSubmit = async (data: any) => {
    if (isAddMode) {
      setSpinner(true);
      // console.log("data", data);
      let productScale = Number(data["product_scale"]);
      data["product_scale"] = productScale;
      let productType = Number(data["product_type"]);
      data["product_type"] = productType;
      let productPackingType = Number(data["product_packing_type"]);
      data["product_packing_type"] = productPackingType;
      await addProduct(data)
        .then(async (res) => {
          if (res.data.status === 1) {
            let response_data = res.data;
            let response_data_productId = response_data.product_id;
            //========== if files selected =========//
            if (file || fileTwo || fileThree || fileFour || fileFive) {
              let folderPath = "Common/Products/" + response_data_productId;
              if (file) {
                let filename =
                  response_data_productId + "_product_image_one.webp";
                let response = await UploadImagetoS3(
                  file,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[0] = response["Location"];
              }
              if (fileTwo) {
                let filename =
                  response_data_productId + "_product_image_two.webp";
                let response = await UploadImagetoS3(
                  fileTwo,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[1] = response["Location"];
              }
              if (fileThree) {
                let filename =
                  response_data_productId + "_product_image_three.webp";
                let response = await UploadImagetoS3(
                  fileThree,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[2] = response["Location"];
              }
              if (fileFour) {
                let filename =
                  response_data_productId + "_product_image_four.webp";
                let response = await UploadImagetoS3(
                  fileFour,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[3] = response["Location"];
              }
              if (fileFive) {
                let filename =
                  response_data_productId + "_product_image_five.webp";
                let response = await UploadImagetoS3(
                  fileFive,
                  folderPath,
                  filename
                );
                finalImagesS3Urls[4] = response["Location"];
              }
              // ============= Update with Image ================ //
              await updateProductImage(
                response_data_productId,
                finalImagesS3Urls
              )
                .then((updateRes) => {
                  if (updateRes.data.status == 1) {
                    resetValues();
                    toast.success(updateRes.data.message, {
                      position: "top-right",
                    });
                    navigation(-1);
                  } else {
                    resetValues();
                    toast.error(updateRes.data.message, {
                      position: "top-right",
                    });
                  }
                })
                .catch((err) => {
                  // console.log("error", err);
                  toast.error(err.message, {
                    position: "top-right",
                  });
                });
              // ============= Update with Image ================ //
            } else {
              resetValues();
              toast.success(res.data.message, {
                position: "top-right",
              });
              navigation(-1);
            }
          } else {
            reset();
            toast.error(res.data.message, {
              position: "top-right",
            });
            // console.log("error meg", res.data.message);
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
      // =========== UPDATE ============ //
    } else {
      setSpinner(true);
      // data["product_store"] = selectedStoreIds;
      data.product_id = id;
      let productScale = Number(data["product_scale"]);
      data["product_scale"] = productScale;
      let productType = Number(data["product_type"]);
      data["product_type"] = productType;
      let productPackingType = Number(data["product_packing_type"]);
      data["product_packing_type"] = productPackingType;
      // console.log("Updated array data", updatefinalImagesS3Urls);
      // ================== images related ===================== //
      let folderPath = "Common/Products/" + id;
      if (file) {
        let filename = id + "_product_image_one.webp";
        let response = await UploadImagetoS3(file, folderPath, filename);
        updatefinalImagesS3Urls[0] = response["Location"];
      }
      if (fileTwo) {
        let filename = id + "_product_image_two.webp";
        let response = await UploadImagetoS3(fileTwo, folderPath, filename);
        updatefinalImagesS3Urls[1] = response["Location"];
      }
      if (fileThree) {
        let filename = id + "_product_image_three.webp";
        let response = await UploadImagetoS3(fileThree, folderPath, filename);
        updatefinalImagesS3Urls[2] = response["Location"];
      }
      if (fileFour) {
        let filename = id + "_product_image_four.webp";
        let response = await UploadImagetoS3(fileFour, folderPath, filename);
        updatefinalImagesS3Urls[3] = response["Location"];
      }
      if (fileFive) {
        let filename = id + "_product_image_five.webp";
        let response = await UploadImagetoS3(fileFive, folderPath, filename);
        updatefinalImagesS3Urls[4] = response["Location"];
      }
      // console.log("Updated array data two", updatefinalImagesS3Urls);
      data["product_images"] = updatefinalImagesS3Urls;
      // console.log("Updated data", data);
      await updateProduct(data)
        .then((updateres) => {
          if (updateres.data.status === 1) {
            resetValues();
            setProductImage("");
            setProductImageTwo("");
            setProductImageThree("");
            setProductImageFour("");
            setProductImageFive("");
            setupdatefinalImagesS3Urls([]);
            toast.success(updateres.data.message, {
              position: "top-right",
            });
            navigation(-1);
          } else {
            resetValues();
            setProductImage("");
            setProductImageTwo("");
            setProductImageThree("");
            setProductImageFour("");
            setProductImageFive("");
            setupdatefinalImagesS3Urls([]);
            // console.log("error", updateres.data.message);
            toast.error(updateres.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // console.log("error", err);
          toast.error(err.message, {
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      {/* <Header onSkin={setSkin} /> */}
      <Sidebar />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/product-dashboard">Product Dashboard</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/product-dashboard/product">Product Management</Link>
              </li>
              {!isAddMode && (
                <li
                  className="breadcrumb-item"
                  aria-current="page"
                  onClick={() => {
                    navigation(`/productdetailpage/${productId}`);
                  }}
                >
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    {productName}
                  </span>
                </li>
              )}
              <li className="breadcrumb-item active" aria-current="page">
                {isAddMode ? "Add New Product" : "Edit Product"}
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              {isAddMode ? "Add New Product" : "Edit Product"}
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              style={{
                backgroundColor: "#dae0e6bf",
                color: "black",
                borderColor: "#dae0e6bf",
              }}
              className="d-flex align-items-center gap-2"
              // size="md"
              onClick={() => navigation(-1)}
            >
              <i className="ri-arrow-left-fill fs-18 lh-1"></i>
              <span className="d-none d-sm-inline"> Back </span>
            </Button>
          </div>
        </div>

        {/*=========================== Form ======================================*/}
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row>
                <div className="mb-3">
                  <label
                    htmlFor="inputName"
                    className="col-form-label text-dark "
                  >
                    Product Name
                  </label>
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Product Name"
                    {...register("product_title")}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Textarea" className="col-form-label">
                    Product Description
                  </label>
                  <textarea
                    className="form-control"
                    id="Textarea"
                    placeholder="Enter Description here..."
                    rows={3}
                    {...register("product_description")}
                  ></textarea>
                </div>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label className="col-form-label text-dark">
                      Choose Brand
                    </label>
                    <div>
                      <select
                        {...register("product_brand")}
                        className="form-control"
                        onChange={setSelectedOption}
                        value={brandOption}
                      >
                        {allBrands.map((option) => (
                          <option key={option.brand_id} value={option.brand_id}>
                            {option.brand_title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label className="col-form-label text-dark">
                      Choose Sub-Category
                    </label>
                    <div>
                      <select
                        {...register("product_sub_category")}
                        className="form-control"
                        onChange={setSelectedCategoryOption}
                        value={subCategoryOption}
                      >
                        {allCategories.map((option) => (
                          <option
                            key={option.sub_category_id}
                            value={option.sub_category_id}
                          >
                            {option.sub_category_title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="productPackage"
                      className="col-form-label text-dark"
                    >
                      Product Items Per Package
                    </label>
                    <input
                      type="number"
                      id="productPackage"
                      className="form-control"
                      placeholder="Product Items Per Package"
                      {...register("product_items_per_package", {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="productActualPrice"
                      className="col-form-label text-dark"
                    >
                      Product Actual Price
                    </label>
                    <input
                      type="number"
                      id="productActualPrice"
                      className="form-control"
                      placeholder="Product Actual Price"
                      {...register("product_actual_price", {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  {/* number */}
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="producttype"
                      className="col-form-label text-dark"
                    >
                      Product Type
                    </label>
                    <div>
                      <select
                        {...register("product_type")}
                        className="form-control"
                        id="producttype"
                        value={selectedproducttype}
                        onChange={(e) =>
                          setSelectedproducttype(Number(e.target.value))
                        }
                      >
                        <option value="">Select...</option>
                        <option value="1">Fresh</option>
                        <option value="0">Non-fresh</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  {/* number */}
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="productPackingType"
                      className="col-form-label text-dark"
                    >
                      Product Packing Type
                    </label>
                    <div>
                      <select
                        {...register("product_packing_type")}
                        className="form-control"
                        id="productPackingType"
                        value={selectedPackingType}
                        onChange={(e) =>
                          setSelectedPackingType(Number(e.target.value))
                        }
                      >
                        <option value="">Select...</option>
                        <option value={0}>Paper</option>
                        <option value={1}>Plastic</option>
                        <option value={2}>Glass</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="productweight"
                      className="col-form-label text-dark"
                    >
                      Product Weight
                    </label>
                    <input
                      type="number"
                      id="productweight"
                      className="form-control"
                      placeholder="Product weight"
                      {...register("product_weight", {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  {/* number */}
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="productScale"
                      className="col-form-label text-dark"
                    >
                      Product Scale
                    </label>
                    <div>
                      <select
                        {...register("product_scale")}
                        className="form-control"
                        id="productScale"
                        value={selectedScaleType}
                        onChange={(e) =>
                          setSelectedScaleType(Number(e.target.value))
                        }
                      >
                        <option value="">Select...</option>
                        <option value={0}>grams</option>
                        <option value={1}>Kg</option>
                        <option value={2}>lb</option>
                        <option value={3}>liter</option>
                        <option value={4}>ml</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="inputName"
                      className="col-form-label text-dark "
                    >
                      Product AddOns
                    </label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      placeholder="Product Addons"
                      {...register("product_add_ons")}
                    />
                  </div>
                </Col>
                <div className="row mb-3">
                  <div className="col mt-3">
                    <label>Product Images</label>
                    <p className="mb-1 text-warning">
                      Preffered Image Size 580px x 550px{" "}
                    </p>
                  </div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-lg-6">
                        <label htmlFor="Productimage" className="mt-3 mb-1">
                          Product Image One
                        </label>
                        <input
                          id="Productimage"
                          type="file"
                          className="form-control"
                          onChange={async (e) => {
                            let webpFile: any = await convertToWebP(
                              e.target.files[0]
                            );
                            setFile(webpFile);
                          }}
                        />
                        {!isAddMode && productImage && (
                          <div className="mt-3 mb-3">
                            <label className="mb-2">Current Image</label>
                            <div className="news-item">
                              <div className="news-img fullwidth-img">
                                <img
                                  src={productImage}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="ProductimageTwo" className="mt-3 mb-1">
                          Product Image Two
                        </label>
                        <input
                          id="ProductimageTwo"
                          type="file"
                          className="form-control"
                          onChange={async (e) => {
                            let webpFile: any = await convertToWebP(
                              e.target.files[0]
                            );
                            setFileTwo(webpFile);
                          }}
                        />
                        {!isAddMode && productImageTwo && (
                          <div className="mt-3 mb-3">
                            <label className="mb-2">Current Image</label>
                            <div className="news-item">
                              <div className="news-img fullwidth-img">
                                <img
                                  src={productImageTwo}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="ProductimageThree"
                          className="mt-3 mb-1"
                        >
                          Product Image Three
                        </label>
                        <input
                          id="ProductimageThree"
                          type="file"
                          className="form-control"
                          onChange={async (e) => {
                            let webpFile: any = await convertToWebP(
                              e.target.files[0]
                            );
                            setFileThree(webpFile);
                          }}
                        />
                        {!isAddMode && productImageThree && (
                          <div className="mt-3 mb-3">
                            <label className="mb-2">Current Image</label>
                            <div className="news-item">
                              <div className="news-img fullwidth-img">
                                <img
                                  src={productImageThree}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="ProductimageFour" className="mt-3 mb-1">
                          Product Image four
                        </label>
                        <input
                          id="ProductimageFour"
                          type="file"
                          className="form-control"
                          onChange={async (e) => {
                            let webpFile: any = await convertToWebP(
                              e.target.files[0]
                            );
                            setFileFour(webpFile);
                          }}
                        />
                        {!isAddMode && productImageFour && (
                          <div className="mt-3 mb-3">
                            <label className="mb-2">Current Image</label>
                            <div className="news-item">
                              <div className="news-img fullwidth-img">
                                <img
                                  src={productImageFour}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="ProductimageFive" className="mt-3 mb-1">
                          Product Image five
                        </label>
                        <input
                          id="ProductimageFive"
                          type="file"
                          className="form-control"
                          onChange={async (e) => {
                            let webpFile: any = await convertToWebP(
                              e.target.files[0]
                            );
                            setFileFive(webpFile);
                          }}
                        />
                        {!isAddMode && productImageFive && (
                          <div className="mt-3 mb-3">
                            <label className="mb-2">Current Image</label>
                            <div className="news-item">
                              <div className="news-img fullwidth-img">
                                <img
                                  src={productImageFive}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              {spinner && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}

              <div className="text-end mt-5">
                <Button
                  type="submit"
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                  variant="primary"
                >
                  submit
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
