import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Col, Table, Badge } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { deleteImageFromS3 } from "../api-services/s3-service";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import {
  deleteBrandById,
  updateBrandState,
  getAllBrands,
} from "../api-services/BrandApi";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import SkeletonLoading from "../pages/SkeletonLoading";
import Pagination from "../docs/Pagination";
import AuthContext from "../auth/AuthContext";
let PageSize = 5;

export default function BrandManagement() {
  const { userRole } = useContext(AuthContext);
  // console.log("user role",userRole);

  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  // Brands Array Variable
  const [allBrands, setAllBrands] = useState([]);
  const [allBrandsTwo, setAllBrandsTwo] = useState([]);

  // search related
  const [q, setQ] = useState("");

  // move to normal/featured related
  const [moveBrandById, setMoveBrandId] = useState("");

  // delete popup //
  // const [deleteBrandId, setDeleteBrandId] = useState("");
  const [deleteBrand, setDeleteBrand] = useState({});

  const removeBrandByID = async () => {
    await deleteBrandById(deleteBrand.brand_id)
      .then(async (res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message, {
            position: "top-right",
          });
          for (let i = 0; i < deleteBrand.brand_images.length; i++) {
            let imgUrl = deleteBrand.brand_images[i];
            let imgname = imgUrl.substr(imgUrl.search(".com") + 5);
            await deleteImageFromS3(imgname);
            console.log("image ======= ", i, " ======== deleted");
          }
          getAllBrandsData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  // Get Api Call
  useEffect(() => {
    getAllBrandsData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getAllBrandsData = async () => {
    setLoading(true);
    await getAllBrands()
      .then((res) => {
        // displaying first 5 values //
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        let slicedData = res.data.brands.slice(firstPageIndex, lastPageIndex);
        setAllBrands(slicedData);
        setShowPagination(true); // showing pagination
        // displaying first 5 values //
        setAllBrandsTwo(res.data.brands);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
        // if (err.response) {
        //     // client received an error response (5xx, 4xx)
        //     console.log(err.response);
        // } else if (err.request) {
        //     // client never received a response, or request never left
        //     console.log(err.request);
        // } else {
        //     // anything else
        //     console.log(err);
        // }
      });
    // console.log("All Brands", allBrands);
  };

  // showing sliced data for every page change click event //
  useEffect(() => {
    setSlicedData(currentPage);
  }, [currentPage]);

  const setSlicedData = (currentPage) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let slicedData = allBrandsTwo.slice(firstPageIndex, lastPageIndex);
    setAllBrands(slicedData);
  };
  // Get Api Call

  // For Navigation
  const navigate = useNavigate();

  const searchData = (val) => {
    // console.log("search val",val);

    setShowPagination(false);
    setQ(val);
    if (val === "") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      let slicedData = allBrandsTwo.slice(firstPageIndex, lastPageIndex);
      setAllBrands(slicedData); // assigning sliced data
      setShowPagination(true); // showing pagination
      return;
    }
    const query = val.toLowerCase();
    const allData = allBrandsTwo.filter(
      (item) => item.brand_title.toLowerCase().indexOf(query) > -1
    );
    setAllBrands(allData);
  };

  const handleKeyDown = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (event.key === "Backspace") {
      setAllBrands(allBrandsTwo);
    }
  };

  const updateBrandStateByID = async (state) => {
    await updateBrandState(moveBrandById, state)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success("Brand State Updated.", {
            position: "top-right",
          });
          getAllBrandsData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        // console.log("error", err);
        toast.error(err.message, {
          position: "top-right",
        });
      });
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        {/* Bread Crumb */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/product-dashboard">Product Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Brand Management
              </li>
            </ol>
            <h4 className="main-title mb-0 mt-3">
              Welcome to Brand Management
            </h4>
          </div>
          <div></div>
        </div>

        {/* Search Bar & Add Brand Buttom */}
        <Card style={{ backgroundColor: "#dae0e6bf" }}>
          <Card.Body>
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="form-search me-auto">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => searchData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="ri-search-line"
                  style={{ cursor: "pointer" }}
                  onClick={() => searchData(q)}
                ></i>
              </div>

              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {(userRole === 7 || userRole === 6) && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    size="md"
                    onClick={() => {
                      navigate("/brandform");
                    }}
                  >
                    <i className="ri-add-circle-fill fs-18 lh-1"></i>Add
                    <span className="d-none d-sm-inline">Brand</span>
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Brands List Started */}
        <div className="mt-5 shadow">
          {loading && <SkeletonLoading />}
          {!loading && isError && <ServiceUnavailable />}
          {!loading && !isError && allBrands.length === 0 && (
            <div className="text-center">
              <h5 style={{ padding: 30 }}> No Brands Data Currently</h5>
            </div>
          )}
          {!loading && !isError && allBrands.length > 0 && (
            <Col xs="12 mt-5 mb-3">
              <Card className="card-one">
                <Card.Body>
                  <Table className="table-agent  table-hover mb-0" responsive>
                    <thead style={{ backgroundColor: "#dae0e6bf" }}>
                      <tr className="text-center">
                        <th>Image</th>
                        <th>Title</th>
                        <th>State</th>
                        <th>Views</th>
                        {/* <th>Created On</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allBrands &&
                        allBrands.map((item) => {
                          return (
                            <tr key={item.brand_id} className="text-center">
                              <td>
                                {item.brand_images ? (
                                  <div className="news-item">
                                    <div className="news-img">
                                      <img
                                        // src={JSON.parse(item.brand_images)[0]}
                                        src={item.brand_images[0]}
                                        alt={item.brand_title}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="interest-item">
                                    <div className="interest-icon bg-light ">
                                      {/* <i className="ri-github-fill"></i> */}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "category-title text-success"
                                      : "category-title"
                                  }
                                >
                                  {item.brand_title}
                                </span>
                              </td>
                              <td>
                                <span className="ff-numerals">
                                  {item.brand_state === 0 ? (
                                    <Badge bg="secondary p-2" pill>
                                      Normal
                                    </Badge>
                                  ) : (
                                    <Badge bg="success p-2" pill>
                                      Featurd
                                    </Badge>
                                  )}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    skin == "dark"
                                      ? "ff-numerals ms-2 text-success"
                                      : "ff-numerals ms-2"
                                  }
                                >
                                  {item.brand_views || 0}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2 ms-2 justify-content-center">
                                  {(userRole === 7 || userRole === 6) && (
                                    <>
                                      <Button
                                        variant="primary"
                                        onClick={() => {
                                          navigate(
                                            `/brandform/${item.brand_id}`,
                                            {
                                              state: {
                                                name: item?.brand_title,
                                                id: item?.brand_id,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Edit Brand
                                      </Button>

                                      {item.brand_state === 0 ? (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#featuredPopup"
                                          onClick={() => {
                                            setMoveBrandId(item.brand_id);
                                          }}
                                        >
                                          Make Featured
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#NormalPopup"
                                          onClick={() => {
                                            setMoveBrandId(item.brand_id);
                                          }}
                                        >
                                          Make Normal
                                        </Button>
                                      )}

                                      <button
                                        className="btn btn-info text-light"
                                        onClick={() => {
                                          setDeleteBrand(item);
                                        }}
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delPopup"
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}

                                  <Link
                                    to={`/commondetailpage/${item.brand_id}`}
                                  >
                                    <Button variant="success">
                                      View Details
                                    </Button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
        {/* Pagination */}
        {showPagination && allBrandsTwo?.length > 5 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={allBrandsTwo.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
        {/* Pagination */}
        <Footer />
      </div>

      {/* delete Modal */}
      <div
        className="modal fade"
        id="delPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="delPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="delPopupLabel">
                Are You Sure want to Delete?
              </h5>
              <button
                type="button"
                id="closeDeleteDemoPopup"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={removeBrandByID}
              >
                <i className="bx bxs-trash"></i>Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete Modal */}

      {/* Make Featured  */}
      <div
        className="modal fade"
        id="featuredPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="FeaturedPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="FeaturedPopupLabel">
                Are You want to Move it to Featured?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateBrandStateByID(1)}
              >
                Make Featured
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Featured  */}

      {/* Make Normal  */}
      <div
        className="modal fade"
        id="NormalPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="NormalPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="NormalPopupLabel">
                Are You want to Move it to Normal?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateBrandStateByID(0)}
              >
                Make Normal
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Make Normal */}
    </React.Fragment>
  );
}
