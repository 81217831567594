import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";

export default function SkeletonLoading() {
  return (
    <div className="p-2">
      <Placeholder as="p" animation="wave">
        <Placeholder xs={12} size="lg" />
      </Placeholder>
      <Row>
        <Col xs="3">
          <Placeholder.Button
            xs={6}
            aria-hidden="true"
            animation="glow"
            style={{ height: 100 }}
          />
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={2} />
          </Placeholder>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="3">
          <Placeholder.Button
            xs={6}
            aria-hidden="true"
            animation="glow"
            style={{ height: 100 }}
          />
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={2} />
          </Placeholder>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="3">
          <Placeholder.Button
            xs={6}
            aria-hidden="true"
            animation="glow"
            style={{ height: 100 }}
          />
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={5} /> <Placeholder xs={5} />
          </Placeholder>
        </Col>
        <Col xs="3">
          <Placeholder as="p" animation="glow" className="mt-5">
            <Placeholder xs={2} /> <Placeholder xs={2} /> <Placeholder xs={2} />
          </Placeholder>
        </Col>
      </Row>
    </div>
  );
}
